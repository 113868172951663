import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, Radio, Row, Col, message, Card } from "antd";
import axios from "axios";
import config from "../../../config";

const { Option } = Select;

const Accommodation = ({ onStepComplete, studentID }) => {
  const [form] = Form.useForm();
  const [isDayScholar, setIsDayScholar] = useState(null); // Track selected value
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!studentID) return;
    fetchAccommodationData();

    axios.get(`${config.curriculaserver}/curricula/admin/accommodation/${studentID}`)
      .then(response => {
        if (response.data.success) {
          form.setFieldsValue(response.data.data);
          setIsDayScholar(response.data.data.dayScholar || null);
        }
      })
      .catch(() => {
        console.warn("⚠️ No existing accommodation details found.");
      });

  }, [studentID, form]);

  const fetchAccommodationData = async () => {
    try {
      const { data } = await axios.get(
        `${config.curriculaserver}/curricula/admin/nextkeen/accommodation/get/${studentID}`
      );

      if (data && data.accommodationDetails) {
        form.setFieldsValue(data.accommodationDetails); // ✅ Pre-fill form with accommodation details
      }
    } catch (error) {
      console.error("Error fetching accommodation data:", error);
      message.error("No existing accommodation details found.");
    }
  };

  const handleSaveAndContinue = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();

      if (!studentID) {
        message.error("❌ Student ID is missing. Please enter personal info first!");
        return;
      }

      // Fetch existing status inside accommodationDetails
      const { data } = await axios.get(
        `${config.curriculaserver}/curricula/admin/nextkeen/accommodation/get/${studentID}`
      );

      const existingStatus = data?.accommodationDetails?.status;

      if (existingStatus === 2) {
        message.error("Your form has already been submitted.");
        return;
      }

      // Ensure status inside accommodationDetails remains 1 or is set if missing
      const updatedData = {
        ...values,
        studentID,
        status: existingStatus === 1 ? 1 : 1,
      };

      setLoading(true);
      const response = await axios.post(
        `${config.curriculaserver}/curricula/admin/nextkeen/accommodation/add`,
        updatedData
      );

      if (response.data.success) {
        message.success("Accommodation details saved successfully!");
        onStepComplete(); // ✅ Move to the next step
      } else {
        message.error(response.data.message || "Failed to save data.");
      }
    } catch (error) {
      message.error("⚠️ Please fill all required fields!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="site-layout-content" style={{ padding: "20px" }}>
      <Card>
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col xs={24} sm={8} md={8}>
              <Form.Item label="Student ID">
                <Input value={studentID} disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={8} md={8}>
              <Form.Item
                label="Are you a day scholar?"
                name="dayScholar"
                rules={[{ required: true, message: "Please specify if you're a day scholar!" }]}
              >
                <Radio.Group onChange={(e) => setIsDayScholar(e.target.value)}>
                  <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          {isDayScholar === "no" && (
            <>
              <Row gutter={16}>
                <Col xs={24} sm={8} md={8}>
                  <Form.Item label="Accommodation Type" name="accommodationType" rules={[{ required: true, message: "Please specify your accommodation type!" }]}>
                    <Select>
                      <Option value="dormitory">Dormitory</Option>
                      <Option value="rentalFlats">Rental Flats</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={8} md={8}>
                  <Form.Item label="Meals" name="meals" rules={[{ required: true, message: "Please specify your meal plan!" }]}>
                    <Select>
                      <Option value="fullBoard">Full Board</Option>
                      <Option value="halfBoard">Half Board</Option>
                      <Option value="noMeals">No meals at all</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={8} md={8}>
                  <Form.Item label="Special Diet?" name="specialDiet" rules={[{ required: true, message: "Please specify if you have a special diet!" }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={24} sm={8} md={8}>
                  <Form.Item label="Any known allergies?" name="allergies" rules={[{ required: true, message: "Please specify any known allergies!" }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          <Form.Item>
            <Button type="primary" onClick={handleSaveAndContinue} loading={loading}>
              Continue
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Accommodation;
