import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Radio,
  Upload,
  Row,
  Col,
  message,
  Card,
  Modal
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import config from "../../../config";

const { Option } = Select;

const PersonalInfo = ({ onStepComplete }) => {
  const [form] = Form.useForm();
  const [studentID, setStudentID] = useState("");
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isCitizen, setIsCitizen] = useState(true);
  const [passportPhoto, setPassportPhoto] = useState([]);
  const [idUpload, setIdUpload] = useState([]);
  const [passportUpload, setPassportUpload] = useState([]);
  const [visaUpload, setVisaUpload] = useState([]);
  const [photoPreview, setPhotoPreview] = useState(null);
  const [isNonCitizen, setIsNonCitizen] = useState(false);
  const [maritalStatus, setMaritalStatus] = useState("single");
  const [fileList, setFileList] = useState([]);
  const [isContinueDisabled, setIsContinueDisabled] = useState(false);
  

  useEffect(() => {
    if (studentID) {
      fetchStudentData();
    }
  }, [studentID]);
  
  const fetchStudentData = async () => {
    try {
      const { data } = await axios.get(
        `${config.curriculaserver}/curricula/admin/StudentDataEntry/personal-info/get/${studentID}`
      );

      if (data?.personalInfo) {
        form.setFieldsValue(data.personalInfo);
      }
    } catch (error) {
      console.error("Error fetching student data:", error);
    }
  };

  const checkStudentID = async () => {
    if (!studentID.trim()) {
      message.warning("⚠️ Please enter your Student ID.");
      return;
    }
  
    setLoading(true);
    try {
      const response = await axios.get(
        `${config.curriculaserver}/curricula/admin/StudentDataEntry/personal-info/get/${studentID}`
      );
  
      if (response.data.success && response.data.personalInfo) {
        const personalInfo = response.data.personalInfo;
  
        // ✅ Extract status
        const status = personalInfo?.status ?? 1;
  
        console.log("✅ Student record found. Status:", status);
  
        if (status === 2) {
          message.warning("Your application has already been submitted. Please enter a new Student ID.");
          setStudentID(""); 
          setShowForm(false);
          setIsContinueDisabled(true);
          return;
        }
  
        setShowForm(true);
        setIsContinueDisabled(false);
        setIsSubmitted(false);
        form.setFieldsValue({ studentID, ...personalInfo });
  
      } else {
        console.log("🚨 Student ID not found. Allowing new entry.");
        message.info("Student ID not found. You can proceed with data entry.");
        setShowForm(true);
        setIsContinueDisabled(false);
        form.setFieldsValue({ studentID });
      }
    } catch (error) {
      if (error.response?.status === 404) {
        console.log("🚨 Student record not found. Allowing form entry.");
        message.info("Student ID not found. You can proceed with data entry.");
        setShowForm(true);
        setIsContinueDisabled(false);
        form.setFieldsValue({ studentID });
      } else {
        message.error("Error checking student ID. Please try again.");
        setShowForm(false);
        setIsContinueDisabled(false);
      }
    } finally {
      setLoading(false);
    }
  };
  
  
  

   const handleCitizenChange = (value) => {
    setIsNonCitizen(value === "non-citizen");
  };

  const handleMaritalStatusChange = (value) => {
    setMaritalStatus(value);
    // If "Married" or "Divorced" is selected, ensure "children" is required
    if (value === "married" || value === "divorced") {
      form.setFields([
        {
          name: "children",
          errors: ["Please enter the number of children."],
        },
      ]);
    } else {
      form.setFields([{ name: "children", errors: [] }]);
    }
  };

  const beforeUploadPhoto = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isLt500Kb = file.size / 1024 < 500;

    if (!isJpgOrPng) {
      message.error("Only JPG/PNG files are allowed!");
      return false;
    }
    if (!isLt500Kb) {
      message.error("Photo must be smaller than 500KB!");
      return false;
    }

    // Update preview and file list
    setPhotoPreview(URL.createObjectURL(file));
    setFileList([file]);

    // Set form field value for validation
    form.setFieldsValue({ passportPhoto: file });

    return false; // Prevent auto-upload
  };

  const beforeUpload = (file, allowedTypes, maxSizeMB) => {
    const isValidType = allowedTypes.includes(file.type);
    const isLtMaxSize = file.size / 1024 / 1024 < maxSizeMB;

    if (!isValidType) {
      message.error(`Only ${allowedTypes.join(", ")} files are allowed!`);
      return false;
    }
    if (!isLtMaxSize) {
      message.error(`File must be smaller than ${maxSizeMB}MB!`);
      return false;
    }
    return true;
  };

  const handleFileChange = (setter) => ({ fileList }) => {
    setter(fileList.slice(-1)); // Keep only the latest file
  };

  // Handle clearing the uploaded photo
  const handleClearPhoto = () => {
    setPhotoPreview(null);
    setFileList([]);
    form.setFieldsValue({ passportPhoto: null }); // Clear validation state
  };

  // Handle PDF Validation (for ID/Passport/Visa Uploads)
  const beforeUploadPDF = (file) => {
    const isPDF = file.type === "application/pdf";
    const isLt1M = file.size / 1024 / 1024 < 1;

    if (!isPDF) {
      message.error("Only PDF files are allowed!");
      return false;
    }
    if (!isLt1M) {
      message.error("File must be smaller than 1MB!");
      return false;
    }
    return isPDF && isLt1M;
  };


  const handleSaveAndContinue = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();

      if (!studentID) {
        message.error("❌ Student ID is required!");
        return;
      }

      // Fetch existing student data to check status inside personalInfo
      const { data } = await axios.get(
        `${config.curriculaserver}/curricula/admin/StudentDataEntry/personal-info/get/${studentID}`
      );

      const existingStatus = data?.personalInfo?.status;

      if (existingStatus === 2) {
        message.error("❌ Your form has already been submitted.");
        return;
      }

      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      // Append file uploads
      if (passportPhoto.length > 0) formData.append("passportPhoto", passportPhoto[0].originFileObj);
      if (idUpload.length > 0) formData.append("idUpload", idUpload[0].originFileObj);
      if (passportUpload.length > 0) formData.append("passportUpload", passportUpload[0].originFileObj);
      if (visaUpload.length > 0) formData.append("visaUpload", visaUpload[0].originFileObj);

      // Ensure status inside personalInfo remains 1 or is set if missing
      formData.append("status", existingStatus === 1 ? 1 : 1);

      const response = await axios.post(
        `${config.curriculaserver}/curricula/admin/StudentDataEntry/personal-info/add`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      if (response.data.success) {
        message.success("✅ Your progress has been saved!");
        onStepComplete(studentID);
      } else {
        message.error(response.data.message || "❌ Failed to save data.");
      }
    } catch (error) {
      console.error("API Error:", error.response?.data || error.message);
      message.error("⚠️ Please fill all required fields!");
    }
  };
  
  


  const handlePassportPhotoChange = ({ fileList }) => {
    setPassportPhoto(fileList.slice(-1)); // Keep only one file
  };

  //  Handle Other Document Uploads
  const handleIdUploadChange = ({ fileList }) => {
    setIdUpload(fileList.slice(-1));
  };

  const handlePassportUploadChange = ({ fileList }) => {
    setPassportUpload(fileList.slice(-1));
  };

  const handleVisaUploadChange = ({ fileList }) => {
    setVisaUpload(fileList.slice(-1));
  };

  // Load saved data from localStorage on component mount
  useEffect(() => {
    const savedData = localStorage.getItem("personalInfoData");
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      form.setFieldsValue(parsedData);

      if (parsedData.citizenship) handleCitizenChange(parsedData.citizenship);
      if (parsedData.maritalStatus)
        handleMaritalStatusChange(parsedData.maritalStatus);
    }
  }, [form]);

  const goBack = () => {
    setShowForm(false);
    setIsSubmitted(false);
    setStudentID(""); // Clear input field when going back
  };
  

  return (
    <Card title="Personal Information">
     {!showForm ? (
        <div style={{ textAlign: "center" }}>
          <Input
            placeholder="Enter your Student ID"
            value={studentID}
            onChange={(e) => setStudentID(e.target.value)}
            style={{ marginBottom: "10px", width: "30%" }}
          />
          <br />
          <Button type="primary" onClick={checkStudentID} loading={loading}>
            Go
          </Button>
        </div>
      ) : isSubmitted ? (
        <p style={{ color: "red", fontWeight: "bold", textAlign: "center" }}>
          Your application has already been submitted.
        </p>
      ) : (
        

        <Form
          form={form}
          layout="vertical"
          initialValues={{
            citizenship: "citizen",
            maritalStatus: "single",
          }}
        >
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                label="Student ID"
                name="studentID"
                rules={[
                  { required: true, message: "Please enter Student ID!" },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>

            {/* Passport Size Photo Upload */}
            <Col xs={24} sm={12} md={8}>
              <Form.Item label="Passport Size Photo" name="passportPhoto" rules={[{ required: true }]}>
                <Upload
                  beforeUpload={(file) => beforeUploadPhoto(file, ["image/jpeg", "image/png"], 0.5)}
                  // listType="picture"
                  maxCount={1}
                  fileList={passportPhoto}
                  onChange={handleFileChange(setPassportPhoto)}
                >
                  <Button icon={<UploadOutlined />}>Upload Passport Photo</Button>
                </Upload>
              </Form.Item>
              

              {photoPreview && (
                <>
                  {/* <img src={photoPreview} alt="Preview" style={{ width: 100, height: 100, marginTop: 5, borderRadius: 5, objectFit: "cover" }} /> */}
                  <Button onClick={handleClearPhoto} type="link" danger>
                    Remove
                  </Button>
                </>
              )}
            </Col>
            <Col xs={24} sm={12} md={8}>
              {" "}
              {photoPreview && (
                <img
                  src={photoPreview}
                  alt="Passport Preview"
                  style={{
                    width: 100,
                    height: 100,
                    marginTop: 5,
                    borderRadius: 5,
                    objectFit: "cover",
                    marginBottom: 30,
                  }}
                />
              )}
            </Col>
          </Row>

          {/* First Row - First Name, Middle Name, Surname */}
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please input your first name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            {/* <Col xs={24} sm={12} md={8}>
              <Form.Item label="Middle Name" name="middleName">
                <Input />
              </Form.Item>
            </Col> */}
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                label="Last Name"
                name="surname"
                rules={[
                  {
                    required: true,
                    message: "Please input your surname!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          {/* Second Row - Gender, Citizenship, Marital Status */}
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                label="Gender"
                name="gender"
                rules={[
                  {
                    required: true,
                    message: "Please select your gender!",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value="male">Male</Radio>
                  <Radio value="female">Female</Radio>
                  <Radio value="other">Other</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8}>
              <Form.Item
                label="Citizenship"
                name="citizenship"
                rules={[
                  {
                    required: true,
                    message: "Please select your citizenship!",
                  },
                ]}
              >
                <Select onChange={handleCitizenChange}>
                  <Option value="citizen">Citizen</Option>
                  <Option value="non-citizen">Non-Citizen</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8}>
              <Form.Item
                label="Marital Status"
                name="maritalStatus"
                rules={[
                  {
                    required: true,
                    message: "Please select your marital status!",
                  },
                ]}
              >
                <Select onChange={handleMaritalStatusChange}>
                  <Option value="single">Single</Option>
                  <Option value="married">Married</Option>
                  <Option value="divorced">Divorced</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {/* Third Row - Number of Children, Email, Phone */}
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please input a valid email!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12} md={8}>
              <Form.Item
                label="Phone Number"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                label="Physical Address"
                name="address"
                rules={[
                  {
                    required: true,
                    message: "Please input your physical address!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          {/* Citizen Specific Fields */}
          {isCitizen && (
            <>
              <Row gutter={16}>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label="National ID Proof No"
                    name="idNumber"
                    rules={[
                      {
                        required: true,
                        message: "Please input your ID number!",
                      },
                    ]}
                    
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label="Upload National ID"
                    name="idUpload"
                    rules={[{ required: true,  message: "Please input your ID number!", }]}
                    valuePropName="fileList" // ✅ Ensures form validation works for Upload
                    getValueFromEvent={(e) => e && e.fileList} 
                  >
                    <Upload
                      beforeUpload={() => false}
                      maxCount={1}
                      fileList={idUpload}
                      onChange={handleIdUploadChange}
                    >
                      <Button icon={<UploadOutlined />}>Upload ID</Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          {/* Non-Citizen Specific Fields */}
          {isNonCitizen && (
            <>
              <Row gutter={16}>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label="Country"
                    name="nationality"
                    rules={[
                      {
                        required: true,
                        message: "Please input your nationality!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label="Passport No"
                    name="passportNo"
                    rules={[
                      {
                        required: true,
                        message: "Please input your passport number!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label="Upload Passport"
                    name="passportUpload"
                    rules={[{ required: true }]}
                  >
                    <Upload
                      beforeUpload={() => false}
                      maxCount={1}
                      fileList={passportUpload}
                      onChange={handlePassportUploadChange}
                    >
                      <Button icon={<UploadOutlined />}>Upload Passport</Button>
                    </Upload>
                  </Form.Item>
                  <p style={{ color: "blue" }}>
                    <b>Note:</b> Only PDF files (Max: 1MB).
                  </p>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label="Student Visa Information"
                    name="studentVisaInfo"
                    rules={[
                      {
                        required: true,
                        message: "Please input your visa information!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8}>
                  <Form.Item
                    label="Upload Visa"
                    name="visaUpload"
                    rules={[{ required: true }]}
                  >
                    <Upload
                      beforeUpload={() => false}
                      maxCount={1}
                      fileList={visaUpload}
                      onChange={handleVisaUploadChange}
                    >
                      <Button icon={<UploadOutlined />}>Upload Visa</Button>
                    </Upload>
                  </Form.Item>
                  <p style={{ color: "blue" }}>
                    <b>Note:</b> Only PDF files (Max: 1MB).
                  </p>
                </Col>
              </Row>
            </>
          )}

          <Row>
            <Col span={24}>
              <p style={{ color: "blue", marginTop: "10px" }}>
                <b>Note:</b> Only <b>JPEG (Max: 500KB)</b> and{" "}
                <b>PDF (Max: 1MB)</b> files are allowed.
              </p>
            </Col>
          </Row>

          <br/>
          <br/>

          <Row>
          <Col xs={24} sm={8} md={8}>
          {/* Save and Continue Button */}
          <Form.Item>
          <Button
                  type="primary"
                  onClick={handleSaveAndContinue}
                  disabled={isContinueDisabled} // ✅ Disable if status is 2
                  style={{ width: "auto", marginLeft: 0 }}
                >
                  Continue
                </Button>
          </Form.Item>
          </Col>
          <Col xs={24} sm={8} md={8}>
          <Form.Item>
          <Button type="default" onClick={goBack} style={{ marginBottom: "10px" }}>
            Back
          </Button>
          </Form.Item>
          </Col>
          </Row>
        </Form>
      )}
    </Card>
  );
};

export default PersonalInfo;
