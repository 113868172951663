import React, { useState, useEffect } from "react";
import { Form, Button, Card, Tabs, Steps, message } from "antd";
import PersonalInfo from "./PersonalInfo";
import NextKeenDetails from "./NextKeenDetails";
import Entrolment from "./Entrolment";
import Accomodation from "./Accomodation";
import Tuition from "./Tuition";
import Fees from "./Fees";
import Denomination from "./Denomination";
import ReviewAndSubmit from "./ReviewSubmit";
import HomePage from "./Homepage";


const { Step } = Steps;
const { TabPane } = Tabs;

const PersonalDetails = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [studentID, setStudentID] = useState("");

  useEffect(() => {
    const savedCompletedSteps = localStorage.getItem("completedSteps");
    if (savedCompletedSteps) {
      setCompletedSteps(JSON.parse(savedCompletedSteps));
    }
  }, []);

  const handleTabChange = (key) => {
    const tabIndex = parseInt(key) - 1;
    if (completedSteps[tabIndex] || tabIndex === currentStep) {
      setCurrentStep(tabIndex);
    } else {
      message.warning("Please complete the previous step before proceeding.");
    }
  };

  const handleStepComplete = (stepIndex, studentIDValue = null) => {
    const updatedCompletedSteps = [...completedSteps];
    updatedCompletedSteps[stepIndex] = true;
    setCompletedSteps(updatedCompletedSteps);

    // ✅ Store studentID from PersonalInfo.js
    if (studentIDValue) {
      setStudentID(studentIDValue);
    }

    setCurrentStep(stepIndex + 1);
  };
  const onReset = () => {
    setStudentID(""); // Reset Student ID
    setCurrentStep(0); // Navigate back to step 1
   // setShowForm(false); // Hide the form
  };

  return (
    <div className="site-layout-content" style={{ padding: "20px" }}>
      <Card>
        <Steps current={currentStep} style={{ marginBottom: 20 }}>
      
        <Step
            title="Personal Info"
            status={
              completedSteps[0]
                ? "finish"
                : currentStep === 0
                ? "process"
                : "wait"
            }
          />
          <Step
            title="Personal Info"
            status={
              completedSteps[1]
                ? "finish"
                : currentStep === 1
                ? "process"
                : "wait"
            }
          />
          <Step
            title="Next of Keen Details"
            status={
              completedSteps[2]
                ? "finish"
                : currentStep === 2
                ? "process"
                : "wait"
            }
          />
          <Step
            title="Enrolment Details"
            status={
              completedSteps[3]
                ? "finish"
                : currentStep === 3
                ? "process"
                : "wait"
            }
          />
          <Step
            title="Accommodation"
            status={
              completedSteps[4]
                ? "finish"
                : currentStep === 4
                ? "process"
                : "wait"
            }
          />
          <Step
            title="Tuition"
            status={
              completedSteps[5]
                ? "finish"
                : currentStep === 5
                ? "process"
                : "wait"
            }
          />
          <Step
            title="Fees"
            status={
              completedSteps[6]
                ? "finish"
                : currentStep === 6
                ? "process"
                : "wait"
            }
          />
          <Step
            title="Denomination"
            status={
              completedSteps[7]
                ? "finish"
                : currentStep === 7
                ? "process"
                : "wait"
            }
          />
           <Step
            title="Review"
            status={
              completedSteps[8]
                ? "finish"
                : currentStep === 8
                ? "process"
                : "wait"
            }
          />
        </Steps>

        <Tabs
          activeKey={(currentStep + 1).toString()}
          onChange={handleTabChange}
        >
            
          <TabPane
            tab="Personal Information"
            key="1"
            // disabled={!completedSteps[0] && currentStep !== 0}
          >
            <PersonalInfo onStepComplete={(id) => handleStepComplete(0, id)} />
          </TabPane>

          {studentID && (
            <TabPane
              tab="Next of Kin Details"
              key="2"
              // disabled={!completedSteps[1] && currentStep !== 1}
            >
              <NextKeenDetails
                studentID={studentID}
                onStepComplete={() => handleStepComplete(1)}
              />
            </TabPane>
          )}

          {studentID && (
            <TabPane
              tab="Enrolment Details"
              key="3"
              // disabled={!completedSteps[2] && currentStep !== 2}
            >
              <Entrolment
                studentID={studentID}
                onStepComplete={() => handleStepComplete(2)}
              />
            </TabPane>
          )}
          {studentID && (
            <TabPane
              tab="Accommodation"
              key="4"
              // disabled={!completedSteps[3] && currentStep !== 3}
            >
              <Accomodation
                studentID={studentID}
                onStepComplete={() => handleStepComplete(3)}
              />
            </TabPane>
          )}
          {studentID && (
            <TabPane
              tab="Tuition"
              key="5"
              // disabled={!completedSteps[4] && currentStep !== 4}
            >
              <Tuition
                studentID={studentID}
                onStepComplete={() => handleStepComplete(4)}
              />
            </TabPane>
          )}
          {studentID && (
            <TabPane
              tab="Fees"
              key="6"
              // disabled={!completedSteps[5] && currentStep !== 5}
            >
              <Fees
                studentID={studentID}
                onStepComplete={() => handleStepComplete(5)}
              />
            </TabPane>
          )}
          {studentID && (
            <TabPane
              tab="Denomination"
              key="7"
              // disabled={!completedSteps[6] && currentStep !== 6}
            >
              <Denomination
                studentID={studentID}
                onStepComplete={() => handleStepComplete(6)}
              />
            </TabPane>
          )}
          {studentID && (
            <TabPane
              tab="Review and submit"
              key="8"
              // disabled={!completedSteps[6] && currentStep !== 6}
            >
              <ReviewAndSubmit
                studentID={studentID}
                // onStepComplete={() => handleStepComplete(7)}
                // onReset={onReset}
                setCurrentStep={setCurrentStep}
              />
            </TabPane>
          )}
        </Tabs>
      </Card>
    </div>
  );
};

export default PersonalDetails;
