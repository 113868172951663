import React, { useEffect, useState, useRef } from "react";
import { Table, message, Card, Row, Col, Tooltip, Input, Button, Space, Switch } from "antd";
import { DownloadOutlined, SearchOutlined, ReloadOutlined } from "@ant-design/icons";
import axios from "axios";
import config from "../../../config";
import Highlighter from "react-highlight-words";
import * as XLSX from 'xlsx';

const AccommodationDetailsTable = () => {
  const [loading, setLoading] = useState(false);
  const [students, setStudents] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  useEffect(() => {
    fetchStudentData();
  }, []);

  const fetchStudentData = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(`${config.curriculaserver}/curricula/admin/denomination/students`);
      if (data.success) {
        setStudents(data.data.map((student, index) => ({ ...student, serialNo: index + 1 }))); // Add Serial No.
      } else {
        message.error("Failed to load student accommodation data.");
      }
    } catch (error) {
      console.error("Error fetching accommodation data:", error);
      message.error("Error fetching student accommodation data.");
    }
    setLoading(false);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] || ""}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });



  const columns = [
    { title: "S.No", dataIndex: "serialNo", key: "serialNo", width: 50,render: (text) => <strong>{text}</strong> },
    { title: "Student ID", dataIndex: "studentID", key: "studentID",fixed: "left", ...getColumnSearchProps("studentID") },
    { title: "Day Scholar", dataIndex: ["accommodationDetails", "dayScholar"], key: "dayScholar" },
    { title: "Accommodation Type", dataIndex: ["accommodationDetails", "accommodationType"], key: "accommodationType" },
    { title: "Meals", dataIndex: ["accommodationDetails", "meals"], key: "meals" },
    { title: "Special Diet", dataIndex: ["accommodationDetails", "specialDiet"], key: "specialDiet" },
    { title: "Allergies", dataIndex: ["accommodationDetails", "allergies"], key: "allergies" },
  ];

  const exportToExcel = () => {
    const formattedData = students.map(({ serialNo, studentID, accommodationDetails }) => ({
      "S.No": serialNo,
      "Student ID": studentID,
      "Day Scholar": accommodationDetails?.dayScholar,
      "Accommodation Type": accommodationDetails?.accommodationType,
      "Meals": accommodationDetails?.meals,
      "Special Diet": accommodationDetails?.specialDiet,
      "Allergies": accommodationDetails?.allergies,
    }));
    
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Accommodation Details');
    XLSX.writeFile(wb, 'Accommodation_Details.xlsx');
  };

  return (
    <>
    <Row gutter={[16, 16]} justify="end">
      <Col xs={24}>
        <Button type="dashed" icon={<DownloadOutlined />} size="small" onClick={exportToExcel}>
          Download Excel
        </Button>
        <Button type="primary" icon={<ReloadOutlined />} size="small" onClick={fetchStudentData}>
          Refresh
        </Button>
      </Col>
    </Row>
    <Card title="5. Accommodation Details">
      <Table columns={columns} dataSource={students} loading={loading} rowKey="studentID" scroll={{ x: 1500 }} />
    </Card>
  </>
  );
};

export default AccommodationDetailsTable;
