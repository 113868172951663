import React, { useEffect, useState, useRef } from "react";
import { Table, message, Card, Row, Col, Tooltip, Input, Button, Space, Switch } from "antd";
import { DownloadOutlined, SearchOutlined, ReloadOutlined } from "@ant-design/icons";
import axios from "axios";
import config from "../../../config";
import Highlighter from "react-highlight-words";
import * as XLSX from 'xlsx';

const EnrollmentDetailsTable = () => {
  const [loading, setLoading] = useState(false);
  const [students, setStudents] = useState([]);

  useEffect(() => {
    fetchStudentData();
  }, []);

  const fetchStudentData = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(`${config.curriculaserver}/curricula/admin/denomination/students`);
      if (data.success) {
        setStudents(data.data.map((student, index) => ({ ...student, serialNo: index + 1 }))); // Add Serial No.
      } else {
        message.error("Failed to load student enrollment data.");
      }
    } catch (error) {
      console.error("Error fetching enrollment data:", error);
      message.error("Error fetching student enrollment data.");
    }
    setLoading(false);
  };

  // ✅ Render Download Button Instead of View
  const renderDownloadIcon = (filePath, label) => {
    if (!filePath) return "N/A"; // If file doesn't exist

    return (
      <Tooltip title={`Download ${label}`}>
        <a href={`${config.curriculaserver}/${filePath}`} download target="_blank" rel="noopener noreferrer">
          <DownloadOutlined style={{ fontSize: "18px", color: "#1890ff" }} />
        </a>
      </Tooltip>
    );
  };

  const columns = [
    { title: "S.No", dataIndex: "serialNo", key: "serialNo" ,width: 50,render: (text) => <strong>{text}</strong> },
    { title: "Student ID", dataIndex: "studentID", key: "studentID" },
    { title: "High School Certificate No", dataIndex: ["enrollmentDetails", "highSchoolCertificateNumber"], key: "highSchoolCertificateNumber" },
    { title: "Graduation Date", dataIndex: ["enrollmentDetails", "graduationDate"], key: "graduationDate" },
    { title: "Department", dataIndex: ["enrollmentDetails", "department"], key: "department" },
    { title: "Platform", dataIndex: ["enrollmentDetails", "platform"], key: "platform" },
    { title: "Enrollment Date", dataIndex: ["enrollmentDetails", "enrollmentDate"], key: "enrollmentDate" },

    // ✅ Downloadable Proof Documents
    { 
      title: "High School Certificate", 
      dataIndex: ["enrollmentDetails", "highSchoolCertificates"], 
      key: "highSchoolCertificates", 
      render: (filePath) => renderDownloadIcon(filePath, "High School Certificate") 
    },
  ];

  const exportToExcel = () => {
    const formattedData = students.map(({ serialNo, studentID, enrollmentDetails }) => ({
      "S.No": serialNo,
      "Student ID": studentID,
      "Enrollment Status": enrollmentDetails?.status,
      "Program Name": enrollmentDetails?.programName,
      "Year of Study": enrollmentDetails?.yearOfStudy,
      "Semester": enrollmentDetails?.semester,
      "Enrollment Date": enrollmentDetails?.enrollmentDate,
    }));
    
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Enrollment Details');
    XLSX.writeFile(wb, 'Enrollment_Details.xlsx');
  };

  return (
    <>
    <Row gutter={[16, 16]} justify="end">
      <Col xs={24}>
        <Button type="dashed" icon={<DownloadOutlined />} size="small" onClick={exportToExcel}>
          Download Excel
        </Button>
        <Button type="primary" icon={<ReloadOutlined />} size="small" onClick={fetchStudentData}>
          Refresh
        </Button>
      </Col>
    </Row>
    <Card title="4. Enrollment Details">
      <Table columns={columns} dataSource={students} loading={loading} rowKey="studentID" scroll={{ x: 1500 }} />
    </Card>
  </>
  );
};

export default EnrollmentDetailsTable;
