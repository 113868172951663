import React, { useEffect, useState, useRef } from 'react';
import { Table, Card, Input, Button, Space, Modal, Form, Row, Col, message } from 'antd';
import { SearchOutlined, EditOutlined, DeleteOutlined, DownloadOutlined, ReloadOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import config from '../../../config'; // Ensure the config file is correctly set up
import * as XLSX from 'xlsx';

const TransactionDetails = () => {
  const [editingTransaction, setEditingTransaction] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [dataReturned, setDataReturned] = useState([]);  // Data for Returned Books
  const [dataBorrowed, setDataBorrowed] = useState([]);  // Data for Borrowed Books
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
    // Separate pagination states for each table
    const [returnedCurrentPage, setReturnedCurrentPage] = useState(1);
    const [returnedPageSize, setReturnedPageSize] = useState(10);
    const [borrowedCurrentPage, setBorrowedCurrentPage] = useState(1);
    const [borrowedPageSize, setBorrowedPageSize] = useState(10);

  // Search functionality
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] || ''}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'S.No',
      key: 'serialNo',
      render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: 'Member ID',
      dataIndex: 'memberId',
      key: 'memberId',
      ...getColumnSearchProps('memberId'),
    },
    {
      title: 'Member Name',
      dataIndex: 'memberName',
      key: 'memberName',
      ...getColumnSearchProps('memberName'),
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
      ...getColumnSearchProps('department'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      ...getColumnSearchProps('phone'),
    },
    {
      title: 'Book ISBN',
      dataIndex: 'bookISBN',
      key: 'bookISBN',
      ...getColumnSearchProps('bookISBN'),
    },
    {
      title: 'Book ID',
      dataIndex: 'bookId',
      key: 'bookId',
      render: (text) => <span>{text}</span>, // Book ID display
    },
    {
      title: 'Book Title',
      dataIndex: 'bookTitle',
      key: 'bookTitle',
      ...getColumnSearchProps('bookTitle'),
    },
    {
      title: 'Issue Date',
      dataIndex: 'issueDate',
      key: 'issueDate',
      ...getColumnSearchProps('issueDate'),
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      ...getColumnSearchProps('dueDate'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ...getColumnSearchProps('status'),
    },
    {
      title: 'Fine Amount',
      dataIndex: 'fineAmount',
      key: 'fineAmount',
      ...getColumnSearchProps('fineAmount'),
    },
    {
      title: 'Fine Paid',
      dataIndex: 'fine',
      key: 'fine',
      ...getColumnSearchProps('fine'),
    },
    
  ];

  const columns1 = [
    {
      title: 'S.No',
      key: 'serialNo',
      render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: 'Member ID',
      dataIndex: 'memberId',
      key: 'memberId',
      ...getColumnSearchProps('memberId'),
    },
    {
      title: 'Member Name',
      dataIndex: 'memberName',
      key: 'memberName',
      ...getColumnSearchProps('memberName'),
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
      ...getColumnSearchProps('department'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      ...getColumnSearchProps('phone'),
    },
    {
      title: 'Book ISBN',
      dataIndex: 'bookISBN',
      key: 'bookISBN',
      ...getColumnSearchProps('bookISBN'),
    },
    {
      title: 'Book ID',
      dataIndex: 'bookId',
      key: 'bookId',
      render: (text) => <span>{text}</span>, // Book ID display
    },
    {
      title: 'Book Title',
      dataIndex: 'bookTitle',
      key: 'bookTitle',
      ...getColumnSearchProps('bookTitle'),
    },
    {
      title: 'Issue Date',
      dataIndex: 'issueDate',
      key: 'issueDate',
      ...getColumnSearchProps('issueDate'),
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      ...getColumnSearchProps('dueDate'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ...getColumnSearchProps('status'),
    },
    {
      title: 'Fine Amount',
      dataIndex: 'fineAmount',
      key: 'fineAmount',
      ...getColumnSearchProps('fineAmount'),
    },
    
    {
      title: 'Renewal Count',
      dataIndex: 'renewalCount',
      key: 'renewalCount',
      ...getColumnSearchProps('renewalCount'),
    },
    
  ];

  // Fetch transaction details (Returned books) and history (Borrowed books) from backend API
  useEffect(() => {
    fetchTransactionDetails();
    fetchBorrowedTransactionDetails();
    
    // Set an interval to refresh data every 30 seconds
    const interval = setInterval(() => {
      fetchTransactionDetails();
      fetchBorrowedTransactionDetails();
    }, 30000); // 30 seconds

    // Cleanup the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);  // Empty dependency array to only run on mount and unmount

  const fetchTransactionDetails = async () => {
    try {
      const response = await axios.get(`${config.curriculaserver}/curricula/admin/library/transaction/details`);
      setDataReturned(response.data);
    } catch (error) {
      console.error('Error fetching transaction details:', error);
      message.error('Not Found');
    }
  };

  const fetchBorrowedTransactionDetails = async () => {
    try {
      const response = await axios.get(`${config.curriculaserver}/curricula/admin/library/transaction/history1`);
      setDataBorrowed(response.data);
    } catch (error) {
      console.error('Error fetching borrowed transaction details:', error);
      message.error('Not Found');
    }
  };

  const handleEdit = (record) => {
    setEditingTransaction(record);
    setIsModalVisible(true);
  };

  const handleEditSubmit = async (values) => {
    if (editingTransaction && editingTransaction._id) {
      try {
        await axios.put(`${config.curriculaserver}/curricula/admin/library/transaction/${editingTransaction._id}`, values);
        fetchTransactionDetails(); // Refetch transaction details after editing
        setIsModalVisible(false); // Close the modal
        message.success('Transaction updated successfully');
      } catch (error) {
        console.error('Error updating transaction:', error);
        message.error('Error updating transaction');
      }
    }
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this transaction?');
    if (confirmDelete) {
      try {
        await axios.delete(`${config.curriculaserver}/curricula/admin/library/transaction/${id}`);
        fetchTransactionDetails(); // Refetch transaction details after deletion
        message.success('Transaction deleted successfully');
      } catch (error) {
        console.error('Error deleting transaction:', error);
        message.error('Error deleting transaction');
      }
    }
  };

  const exportToExcel = (data) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Transactions');
    XLSX.writeFile(wb, 'Transactions.xlsx');
  };

  return (
    <div className="card-container padding-1" >
      <Row gutter={[16, 16]} justify="end">
        <Col xs={24}>
          <Button type="dashed" icon={<DownloadOutlined />} size="small" onClick={() => exportToExcel(dataReturned)}>
            Download Excel (Returned)
          </Button>
          <Button type="dashed" icon={<DownloadOutlined />} size="small" onClick={() => exportToExcel(dataBorrowed)}>
            Download Excel (Borrowed)
          </Button>
         
        </Col>
      </Row>

      {/* Returned Books Table */}
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Card
            title="Returned Books Details"
            size="small"
            style={{ marginTop: '20px', boxShadow: 'none', border: '1px solid #d9d9d9' }}
            extra={[
              <Button
                key="refresh"
                type="primary"
                icon={<ReloadOutlined />}
                onClick={fetchTransactionDetails}
              >
                Refresh
              </Button>,
            ]}
          >
            {/* <Table
              className="table-bordered"
              columns={columns}
              dataSource={dataReturned}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                  setPageSize(pageSize);
                },
              }}
              scroll={{ x: 1300 }} // Adjust horizontal scroll for large tables
            /> */}

<Table
              className="table-bordered"
              columns={columns} // Pass true for returned books table
              dataSource={dataReturned}
              rowKey={(record) => record._id || record.bookId || Math.random().toString()}
              pagination={{
                current: returnedCurrentPage,
                pageSize: returnedPageSize,
                onChange: (page, pageSize) => {
                  setReturnedCurrentPage(page);
                  setReturnedPageSize(pageSize);
                },
              }}
              scroll={{ x: 1300 }} // Adjust horizontal scroll for large tables
            />

          </Card>
        </Col>
      </Row>

      {/* Borrowed Books Table */}
      
      <Row gutter={[16, 16]}>
        
        <Col xs={24}>
          <Card
            title="Borrowed Books Details"
            size="small"
            style={{ marginTop: '20px', boxShadow: 'none', border: '1px solid #d9d9d9' }}

            
            extra={[
              <Button
                key="refresh"
                type="primary"
                icon={<ReloadOutlined />}
                onClick={fetchBorrowedTransactionDetails}
              >
                Refresh
              </Button>,
            ]}
            
          >
            <Table
              className="table-bordered"
              columns={columns1}
              dataSource={dataBorrowed}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                  setPageSize(pageSize);
                },
              }}
              scroll={{ x: 1300 }} // Adjust horizontal scroll for large tables
            />
          </Card>
        </Col>
      </Row>

      <Modal title="Edit Transaction" visible={isModalVisible} onCancel={() => setIsModalVisible(false)} footer={null}>
        <Form initialValues={editingTransaction} onFinish={handleEditSubmit}>
          <Form.Item name="memberId" label="Member ID">
            <Input />
          </Form.Item>
          <Form.Item name="memberName" label="Member Name">
            <Input />
          </Form.Item>
          <Form.Item name="bookISBN" label="Book ISBN">
            <Input />
          </Form.Item>
          <Form.Item name="bookTitle" label="Book Title">
            <Input />
          </Form.Item>
          <Form.Item name="status" label="Status">
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">Submit</Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default TransactionDetails;
