import React from 'react';
import { Tabs } from 'antd';
import PersonalDetails from './Personaldetails';
import ReviewFinal from './oldReviewFinalApplication';
import Report1 from './Report1';
import PersonalInfoTable from './PersonalInfoTable';
import NextKeenTable from './NextKennTable';
import EnrollmentDetailsTable from './EnrollmentDetailsTable';
import AccommodationDetailsTable from './AccommodationDetailsTable';
import TuitionDetailsTable from './TuitionDetailsTable';
import FeesDetailsTable from './FeesDetailsTable';
import DenominationDetailsTable from './DenominationDetailsTable';
import Dashboard from'./Dashboard';



const { TabPane } = Tabs;

const StudentDataEntry = () => {
    return (
        <div style={{ padding: '10px' }}>
            <Tabs defaultActiveKey="1">
            <TabPane tab="Home" key="1">
                     <Dashboard /> 
                </TabPane>
                <TabPane tab="Student Information" key="2">
                    <PersonalDetails />
                </TabPane>
                <TabPane tab="Submission Reports" key="3">
                    
                    <br/>                    <br/>
                    <Report1 />
                    
                    <br/>                    <br/>
                    <PersonalInfoTable />
                    
                    <br/>                    <br/>
                    <NextKeenTable />
                   
                    <br/>                    <br/>

                    <EnrollmentDetailsTable />
                    
                    <br/>                    <br/>
                    <AccommodationDetailsTable />
                    
                    <br/>                    <br/>
                    <TuitionDetailsTable />
                    <br/>                    <br/>
                    
                    <FeesDetailsTable />
                    <br/>                    <br/>
                   
                    <DenominationDetailsTable />
                   

                </TabPane>
                
             
               

                
                
                
            </Tabs>
        </div>
    );
};

export default StudentDataEntry;
