import React, { Component } from "react";
import { Layout } from "antd";
import {
  Row,
  Col,
  Card,
  Statistic,
  Input,
  Breadcrumb,
  Tooltip,
  Badge,
  message,
  PageHeader,
  Button,
  Menu,
  Dropdown,
  Modal,
} from "antd";
import axios from "axios";
import config from "../../config";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  CaretDownOutlined,
  PoweroffOutlined,
  SettingOutlined,
  UserOutlined,
  MenuOutlined,
  HomeOutlined,
  WechatOutlined,
} from "@ant-design/icons";
import CourseInformation from "./CourseInformation/NewHome";
import ManageUsers from "./ManageUsers/Home";
import Weightage from "./Weightage";
import Report from "./Report";
import ImportExport from "./ImportExport";
import CreateCourses from "./CourseInformation/CreateCourses";
import store from "../../redux/store";
import { logoutUser } from "../../redux/actions/commonActions";
import AdmissionsEntry from "./AdmissionsEntry";
import Verification from "./Verification";
import ProfileReport from "./ProfileReport";
import HomePO from "./ProgramOutcomes/Home";
import NewFeatures from "./NewFeatures/Home";
import LearnerProgress from "./NewFeatures/LearnerProgress";
import CourseStatusAll from "./CourseInformation/CourseStatusAll";
import FullReport from "./NewFeatures/FullReport";
import LibraryManagement from "./LibraryManagement/LibraryManagement";
import StudentDataEntry from "./StudentDataEntry/StudentDataEntry";
const { Content } = Layout;

class Home extends Component {
  state = {
    open: false,
    visible: false,
    visibleprofile: false,
    PASSWORD: "",
    CONFIRMPASSWORD: "",
    FIRST_NAME: "",
    LAST_NAME: "",
    MAIL_ID: "",
    MOBILE: "",
    DEPARTMENT: "",
    SLOT: "",
    key: 0,
    Courses: 0,
    Student: 0,
    Faculty: 0,
  };

  async componentDidMount() {
    let data = {
      USER_ID: this.props.common.user.USER_ID,
      key: config.key,
    };

    await axios
      .post(config.curriculaserver + "/curricula/getprofile", data)
      .then((res) => {
        if (res.data.Status === 1) {
          this.setState({
            USER_ID: res.data.user.USER_ID,
            FIRST_NAME: res.data.user.FIRST_NAME,
            LAST_NAME: res.data.user.LAST_NAME,
            MAIL_ID: res.data.user.MAIL_ID,
            MOBILE: res.data.user.MOBILE,
            DEPARTMENT: res.data.user.DEPARTMENT,
            SLOT: res.data.user.SLOT,
          });
        }
      });

    await axios
      .post(config.curriculaserver + "/curricula/admin/home/getdata", data)
      .then((res) => {
        if (res.data.Status === 1) {
          this.setState({
            Courses: res.data.course,
            Student: res.data.student,
            Faculty: res.data.faculty,
          });
        }
      });
  }

  getToken = () => {
    let data = {
      INFO: this.props.common.user,
      KEY: config.key,
    };
    axios
      .post(config.curriculaserver + "/curricula/gettoken", data)
      .then((res) => {
        if (res.data.result) {
          if (res.data.result.localeCompare(localStorage.jwtToken) !== 0) {
            return 1;
          } else return 0;
        }
      });
  };

  handleMenuClick = (e) => {
    if (this.getToken()) {
      alert("You have logged in other system !");
      store.dispatch(logoutUser());
    } else if (parseInt(e.key) === 5) {
      let temp = prompt("Enter password", "");
      if (temp === "johnblesswinC") this.setState({ key: 5 });
      else this.setState({ key: 0 });
    } else this.setState({ key: parseInt(e.key) });
  };

  menu = (
    <Menu onClick={this.handleMenuClick}>
      <Menu.Item key="2">Users Management</Menu.Item>
      <Menu.Item key="9">Program Outcomes</Menu.Item>
      <Menu.Item key="10">Course Enrollment</Menu.Item>
      <Menu.Item key="1">Content Management</Menu.Item>
      <Menu.Item key="4">Course Reports</Menu.Item>
      <Menu.Item key="11">Learner Performance</Menu.Item>
      <Menu.Item key="12">Course Content Status</Menu.Item>
      <Menu.Item key="13">Server Report</Menu.Item>
      <Menu.Item key="15">Library Management</Menu.Item>
      <Menu.Item key="14">Student Data Entry</Menu.Item>
      {/*
                <Menu.Item key="5">Full Import / Export</Menu.Item>
                <Menu.Item key="6">Admissions Entry</Menu.Item>
                <Menu.Item key="7">Verification</Menu.Item>
                <Menu.Item key="8">Profile Reports</Menu.Item>
                */}
    </Menu>
  );

  StatisticTemplate(title, value, precision, suffix) {
    return (
      <Col xs={{ span: 24 }} md={{ span: 6 }}>
        <Card size="small">
          <img
            src={require("../../images/" + suffix + ".svg").default}
            alt=""
            className="verifiable-skill-card-logo"
          />
          <Statistic
            title={title}
            value={value}
            precision={precision}
            valueStyle={{ color: "#3f8600" }}
          />
        </Card>
      </Col>
    );
  }

  handleCancel = () => {
    this.setState({ visible: false, visibleprofile: false });
  };

  showModal = () => {
    this.setState({ visibleprofile: true });
  };

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handlePasswordChange = (e) => {
    const password = e.target.value;

    // Regular expression to match any symbols or special characters in the password
    const regex = /[^a-zA-Z0-9_]/; // This will match any character that is not a letter, number, or underscore.

    // If the password contains special characters or symbols, show an error and reset the value.
    if (regex.test(password)) {
      message.error(
        "Special characters or symbols are not allowed in the password."
      );
      this.setState({ PASSWORD: "", loginBtnLoading: false });
    } else {
      // Otherwise, update the state with the new password value
      this.setState({ PASSWORD: password });
    }
  };

  handleOk = () => {
    if (
      !this.state.FIRST_NAME ||
      !this.state.LAST_NAME ||
      !this.state.MAIL_ID ||
      !this.state.MOBILE ||
      !this.state.PASSWORD
    ) {
      message.error("Invalid Entry !");
      return false;
    }

    if (this.state.PASSWORD !== this.state.CONFIRMPASSWORD) {
      message.error("Password Not Matched!");
      return false;
    }

    if (isNaN(this.state.MOBILE)) {
      message.error("Invalid Mobile Number!");
      return false;
    }

    let data = {
      USER_ID: this.props.common.user.USER_ID,
      FIRST_NAME: this.state.FIRST_NAME.toUpperCase(),
      LAST_NAME: this.state.LAST_NAME.toUpperCase(),
      MAIL_ID: this.state.MAIL_ID,
      MOBILE: this.state.MOBILE,
      PASSWORD: this.state.PASSWORD,
      key: config.key,
    };

    axios
      .post(config.curriculaserver + "/curricula/profile", data)
      .then(async (res) => {
        if (res.data.Status === 1) message.success("Profile Updated !");
        else message.error("Failed Entry !");
        this.setState({ visibleprofile: false });
      });
  };

  render() {
    let { Courses, Student, Faculty } = this.state;
    let toolname = config.toolname + " " + config.totalYear;

    return (
      <div className="userPageHeader">
        <PageHeader
          title={<span>ITMS - {toolname}</span>}
          extra={[
            <Tooltip key="5" placement="left" title={"Chat"}>
              <Button
                ghost
                icon={<WechatOutlined />}
                href={"https://chat.google.com"}
                target="_blank"
              ></Button>
            </Tooltip>,
            <Tooltip key="4" placement="bottom" title={"Configuration"}>
              <Button
                ghost
                icon={<SettingOutlined />}
                onClick={() => {
                  this.setState({ key: 3 });
                }}
              ></Button>
            </Tooltip>,
            <Tooltip key="3" placement="bottom" title={"Profile"}>
              <Button
                ghost
                icon={<UserOutlined />}
                onClick={this.showModal}
              ></Button>
            </Tooltip>,
            <Dropdown key="2" overlay={this.menu}>
              <Button type="primary">
                More Actions <CaretDownOutlined />
              </Button>
            </Dropdown>,
            <Button
              key="1"
              type="danger"
              icon={<PoweroffOutlined />}
              onClick={() => {
                this.props.history.push("/");
              }}
            >
              Logout
            </Button>,
          ]}
        />

        <Layout>
          <Content style={{ padding: "0 30px" }}>
            <div
              style={{
                padding: "0 24px",
                minHeight: "100%",
                marginTop: "35px",
              }}
              className="course-card"
            >
              <Row gutter={[16, 16]}>
                {this.StatisticTemplate("Admin", "PAUL DLAMINI", "", "account")}
                {this.StatisticTemplate("Courses", Courses, "", "course")}
                {this.StatisticTemplate("Students", Student, "", "user")}
                {this.StatisticTemplate("Faculty", Faculty, "", "faculty2")}
              </Row>

              <Card
                type="inner"
                className="student-breadcrump mt-2 mb-2"
                style={{ width: "100%" }}
                bodyStyle={{ padding: "1.5%" }}
                title={
                  <>
                    <Breadcrumb separator="/">
                      <Breadcrumb.Item
                        style={{ cursor: "pointer", color: "white" }}
                        onClick={() => {
                          this.setState({ key: 0 });
                        }}
                      >
                        <Button>
                          <HomeOutlined className="mr-1" />
                          Home{" "}
                        </Button>
                      </Breadcrumb.Item>
                      {this.state.key === 1 ? (
                        <Breadcrumb.Item style={{ color: "white" }}>
                          <Button>Content Management</Button>
                        </Breadcrumb.Item>
                      ) : this.state.key === 2 ? (
                        <Breadcrumb.Item style={{ color: "white" }}>
                          <Button>Users Management</Button>
                        </Breadcrumb.Item>
                      ) : this.state.key === 3 ? (
                        <Breadcrumb.Item style={{ color: "white" }}>
                          <Button>Configuration</Button>
                        </Breadcrumb.Item>
                      ) : this.state.key === 4 ? (
                        <Breadcrumb.Item style={{ color: "white" }}>
                          <Button>Course Reports</Button>
                        </Breadcrumb.Item>
                      ) : this.state.key === 5 ? (
                        <Breadcrumb.Item style={{ color: "white" }}>
                          <Button>Import / Export</Button>
                        </Breadcrumb.Item>
                      ) : this.state.key === 6 ? (
                        <Breadcrumb.Item style={{ color: "white" }}>
                          <Button>Admissions Entry</Button>
                        </Breadcrumb.Item>
                      ) : this.state.key === 7 ? (
                        <Breadcrumb.Item style={{ color: "white" }}>
                          <Button>Verification</Button>
                        </Breadcrumb.Item>
                      ) : this.state.key === 8 ? (
                        <Breadcrumb.Item style={{ color: "white" }}>
                          <Button>Profile Report</Button>
                        </Breadcrumb.Item>
                      ) : this.state.key === 9 ? (
                        <Breadcrumb.Item style={{ color: "white" }}>
                          <Button>Program Outcomes</Button>
                        </Breadcrumb.Item>
                      ) : this.state.key === 10 ? (
                        <Breadcrumb.Item style={{ color: "white" }}>
                          <Button>Course Enrollment</Button>
                        </Breadcrumb.Item>
                      ) : this.state.key === 11 ? (
                        <Breadcrumb.Item style={{ color: "white" }}>
                          <Button>Learner Performance</Button>
                        </Breadcrumb.Item>
                      ) : this.state.key === 12 ? (
                        <Breadcrumb.Item style={{ color: "white" }}>
                          <Button>Course Content Status</Button>
                        </Breadcrumb.Item>
                      ) : this.state.key === 13 ? (
                        <Breadcrumb.Item style={{ color: "white" }}>
                          <Button>Server Report</Button>
                        </Breadcrumb.Item>
                      ) : this.state.key === 14 ? (
                        <Breadcrumb.Item style={{ color: "white" }}>
                          <Button>Student Data Entry</Button>
                        </Breadcrumb.Item>
                      ) : this.state.key === 15 ? (
                        <Breadcrumb.Item style={{ color: "white" }}>
                          <Button>Library Management</Button>
                        </Breadcrumb.Item>
                      ) : (
                        ""
                      )}
                    </Breadcrumb>
                  </>
                }
              >
                {this.state.key === 1 ? (
                  <CourseInformation />
                ) : this.state.key === 2 ? (
                  <ManageUsers />
                ) : this.state.key === 3 ? (
                  <Weightage />
                ) : this.state.key === 4 ? (
                  <Report />
                ) : this.state.key === 5 ? (
                  <ImportExport />
                ) : this.state.key === 6 ? (
                  <AdmissionsEntry />
                ) : this.state.key === 7 ? (
                  <Verification />
                ) : this.state.key === 8 ? (
                  <ProfileReport />
                ) : this.state.key === 9 ? (
                  <HomePO />
                ) : this.state.key === 10 ? (
                  <NewFeatures />
                ) : this.state.key === 11 ? (
                  <LearnerProgress />
                ) : this.state.key === 12 ? (
                  <CourseStatusAll />
                ) : this.state.key === 13 ? (
                  <FullReport />
                ) : this.state.key === 14 ? (
                  <StudentDataEntry />
                ) : this.state.key === 15 ? (
                  <LibraryManagement />
                ) : (
                  <CreateCourses />
                )}
              </Card>
              <br />
            </div>
          </Content>
        </Layout>

        <Modal
          title="My Profile"
          visible={this.state.visibleprofile}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Input
                disabled
                value={this.state.USER_ID}
                addonBefore="Username"
              />
            </Col>
            <Col xs={24}>
              <Input
                value={this.state.PASSWORD}
                type="password"
                addonBefore="New Password"
                id="PASSWORD"
                onChange={this.handlePasswordChange} // Change the handler here
              />
            </Col>
            <Col xs={24}>
              <Input
                value={this.state.CONFIRMPASSWORD}
                type="password"
                addonBefore="Confirm Password"
                id="CONFIRMPASSWORD"
                onChange={this.handleChange}
              />
            </Col>
            <Col xs={24}>
              <Input
                disabled
                value={this.state.FIRST_NAME}
                addonBefore="First Name"
                id="FIRST_NAME"
                onChange={this.handleChange}
              />
            </Col>
            <Col xs={24}>
              <Input
                disabled
                value={this.state.LAST_NAME}
                addonBefore="Last Name"
                id="LAST_NAME"
                onChange={this.handleChange}
              />
            </Col>
            <Col xs={24} className="hide">
              <Input
                disabled
                value={this.state.DEPARTMENT}
                addonBefore="Department"
              />
            </Col>
            <Col xs={24} className="hide">
              <Input disabled value={this.state.SLOT} addonBefore="Slot" />
            </Col>
            <Col xs={24}>
              <Input
                value={this.state.MAIL_ID}
                addonBefore="Mail ID"
                id="MAIL_ID"
                onChange={this.handleChange}
              />
            </Col>
            <Col xs={24}>
              <Input
                value={this.state.MOBILE}
                addonBefore="Mobile"
                id="MOBILE"
                onChange={this.handleChange}
              />
            </Col>
          </Row>

          {/* <Row gutter={[16, 16]}>
                        <Col xs={24} >
                            <Input disabled value={this.state.USER_ID} addonBefore="Username" />
                        </Col>
                        <Col xs={24} >
                            <Input value={this.state.PASSWORD} type="password" addonBefore="New Password" id="PASSWORD" onChange={this.handleChange} />
                        </Col>
                        <Col xs={24} >
                            <Input value={this.state.CONFIRMPASSWORD} type="password" addonBefore="Confirm Password" id="CONFIRMPASSWORD" onChange={this.handleChange} />
                        </Col>
                        <Col xs={24} >
                            <Input disabled value={this.state.FIRST_NAME} addonBefore="First Name" id="FIRST_NAME" onChange={this.handleChange} />
                        </Col>
                        <Col xs={24} >
                            <Input disabled value={this.state.LAST_NAME} addonBefore="Last Name" id="LAST_NAME" onChange={this.handleChange} />
                        </Col>
                        <Col xs={24} className="hide">
                            <Input disabled value={this.state.DEPARTMENT} addonBefore="Department" />
                        </Col>
                        <Col xs={24} className="hide">
                            <Input disabled value={this.state.SLOT} addonBefore="Slot" />
                        </Col>
                        <Col xs={24} >
                            <Input value={this.state.MAIL_ID} addonBefore="Mail ID" id="MAIL_ID" onChange={this.handleChange} />
                        </Col>
                        <Col xs={24} >
                            <Input value={this.state.MOBILE} addonBefore="Mobile" id="MOBILE" onChange={this.handleChange} />
                        </Col>
                    </Row> */}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.common,
});
export default withRouter(connect(mapStateToProps, {})(Home));
