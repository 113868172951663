import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Button,
  Space,
  Row,
  Col,
  Input,
  InputNumber,
  Form,
  Select,
  message,
  Table,
  Modal,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons"; // Importing icons
import axios from "axios";
import config from "../../../config";
import {
  SearchOutlined,
  DownloadOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import * as XLSX from "xlsx";

const AddBook = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentBook, setCurrentBook] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [editingBook, setEditingBook] = useState(null);
  const searchInput = useRef(null);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState(""); // To store search query
  const [searchedColumn, setSearchedColumn] = useState("");
  const [bookData, setBookData] = useState({
    title: "",
    authors: "",
    isbn: "",
    publisher: "",
    publicationYear: "",
    edition: 1,
    genre: "",
    language: "",
    numberOfPages: null,
    shelfLocation: "",
    copyCount: 1,
    description: "",
    price: "",
    availableCopies: 1,
  });
  const [form] = Form.useForm();

  const generateYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= 1; year--) {
      years.push(year);
    }
    return years;
  };

  const fetchBooks = async () => {
    try {
      const response = await axios.get(
        `${config.curriculaserver}/curricula/admin/library/books`
        
      );
      const fetchedBooks = response.data;
      if (Array.isArray(fetchedBooks)) {
        setBooks(fetchedBooks);
      } else {
        console.error("Fetched data is not an array:", fetchedBooks);
      }
    } catch (error) {
      console.error("Error fetching books:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBooks();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBookData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange = (name, value) => {
    setBookData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputChange1 = (name, value) => {
    setBookData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10, // Default number of records per page
  });

  const handleTableChange = (pagination) => {
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  // Pagination for the table (based on current page and page size)
  const currentData = books.slice(
    (pagination.current - 1) * pagination.pageSize,
    pagination.current * pagination.pageSize
  );

  const handleClearFields = () => {
    form.resetFields();
    setBookData({
      title: "",
      authors: "",
      isbn: "",
      publisher: "",
      publicationYear: "",
      edition: 1,
      genre: "",
      language: "",
      numberOfPages: null,
      shelfLocation: "",
      copyCount: 1,
      description: "",
      price: "",
      availableCopies: 1,
    });
  };

  const handleAddBook = async () => {
    setIsSubmitting(true);
  
    // Extract book data
    const { copyCount, price, availableCopies, ...restBookData } = bookData;
  
    // Ensure title and ISBN are present
    if (!restBookData.title || !restBookData.isbn) {
      message.error("Title and ISBN are required fields.");
      setIsSubmitting(false);
      return;
    }
  
    const bookDataToSend = {
      ...restBookData,
      price,
      availableCopies,
      copyCount,
    };
  
    try {
      const response = await axios.post(
        `${config.curriculaserver}/curricula/admin/library/books/add`,
        bookDataToSend
      );
      if (response.status === 201) {
        message.success(`${copyCount} Book(s) added successfully!`);
  
        
  
        // Reset the form fields visually with Ant Design's resetFields method
        handleClearFields(); // This clears the form fields in the UI
  
        fetchBooks(); // Refresh the books list
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        message.error("A book with this ISBN already exists in the library.");
      } else {
        message.error("Failed to add book.");
      }
    } finally {
      setIsSubmitting(false);
    }
  };
  

  const handleDeleteBook = async (isbn) => {
    Modal.confirm({
      title: "Are you sure you want to delete this book?",
      onOk: async () => {
        try {
          const response = await axios.delete(
            `${config.curriculaserver}/curricula/admin/library/books/${isbn}`
          );
          if (response.status === 200) {
            message.success("Book deleted successfully.");
            fetchBooks(); // Refresh the books list
          }
        } catch (error) {
          message.error("Failed to delete book.");
        }
      },
    });
  };

  const handleEditBook = (book) => {
    setCurrentBook(book);
    form.setFieldsValue(book); // Populate the form with the book data for editing
    setIsModalVisible(true); // Show the modal for editing
  };

  const handleUpdateBook = async (values) => {
    setIsSubmitting(true);

    const { copyCount, price, availableCopies, ...restBookData } = values;

    const bookDataToSend = {
      ...restBookData,
      price,
      availableCopies,
      copyCount,
    };

    try {
      const response = await axios.put(
        `${config.curriculaserver}/curricula/admin/library/books/update/${currentBook.isbn}`,
        bookDataToSend
      );

      if (response.status === 200) {
        message.success("Book updated successfully!");
        setIsModalVisible(false); // Close the modal after updating
        form.resetFields(); // Reset form fields
        fetchBooks(); // Refresh the books list
      }
    } catch (error) {
      message.error("Failed to update book.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] || ""}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "S.No", // The title for the serial number column
      key: "serialNo",
      render: (text, record, index) => {
        // Ensure pagination values exist before using them
        const current = pagination.current || 1; // Default to page 1
        const pageSize = pagination.pageSize || 10; // Default page size
  
        return (current - 1) * pageSize + index + 1;
      },
    },
    {
      title: "ISBN",
      dataIndex: "isbn",
      key: "isbn",
      ...getColumnSearchProps("isbn"),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      ...getColumnSearchProps("title"),
    },
    {
      title: "Authors",
      dataIndex: "authors",
      key: "authors",
      ...getColumnSearchProps("authors"),
    },
    {
      title: "Publisher",
      dataIndex: "publisher",
      key: "publisher",
      ...getColumnSearchProps("publisher"),
    },
    {
      title: "Publication Year",
      dataIndex: "publicationYear",
      key: "publicationYear",
      ...getColumnSearchProps("publicationYear"),
    },
    {
      title: "Edition",
      dataIndex: "edition",
      key: "edition",
      ...getColumnSearchProps("edition"),
    },
    {
      title: "Genre",
      dataIndex: "genre",
      key: "genre",
      ...getColumnSearchProps("genre"),
    },
    {
      title: "Language",
      dataIndex: "language",
      key: "language",
      ...getColumnSearchProps("language"),
    },
    {
      title: "Number of Pages",
      dataIndex: "numberOfPages",
      key: "numberOfPages",
      ...getColumnSearchProps("numberOfPages"),
    },
    {
      title: "Shelf Location",
      dataIndex: "shelfLocation",
      key: "shelfLocation",
      ...getColumnSearchProps("shelfLocation"),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      ...getColumnSearchProps("description"),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      ...getColumnSearchProps("price"),
    },
    {
      title: "Copy Count",
      dataIndex: "copyCount",
      key: "copyCount",
      ...getColumnSearchProps("copyCount"),
    },
    {
      title: "Available Copies",
      dataIndex: "availableCopies",
      key: "availableCopies",
      ...getColumnSearchProps("availableCopies"),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <div>
          <Button
            onClick={() => handleEditBook(record)}
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
            size="small"
            style={{ marginRight: 8 }}
          />
          <Button
            onClick={() => handleDeleteBook(record.isbn)}
            type="danger"
            shape="circle"
            icon={<DeleteOutlined />}
            size="small"
          />
        </div>
      ),
    },
  ];

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    setSearchText(selectedKeys[0] || "");
    setSearchedColumn(dataIndex);
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  // const fetchMembers = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${config.curriculaserver}/curricula/admin/library/members`
  //     );
  //     setData(response.data);
  //   } catch (error) {
  //     console.error("Error fetching members:", error);
  //     message.error("Error fetching members");
  //   }
  // };
  
  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Members");
    XLSX.writeFile(wb, "Books.xlsx");
  };

   return (
    <>
      <Card
        title="1. Add New Book"
        size="small"
        style={{
          marginBottom: "20px",
          boxShadow: "none",
          border: "1px solid #d9d9d9",
          width: "100%",
        }}
      >
        <Form layout="vertical" onFinish={handleAddBook} form={form}>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <Form.Item
                name="isbn"
                label="ISBN"
                rules={[{ required: true, message: "Please input the ISBN!" }]}
              >
                <Input
                  name="isbn"
                  value={bookData.isbn}
                  onChange={handleInputChange}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <Form.Item
                name="title"
                label="Book Title"
                rules={[
                  { required: true, message: "Please input the book title!" },
                ]}
              >
                <Input
                  name="title"
                  value={bookData.title}
                  onChange={handleInputChange}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <Form.Item
                name="authors"
                label="Authors"
                rules={[
                  { required: true, message: "Please input the authors!" },
                ]}
              >
                <Input
                  name="authors"
                  value={bookData.authors}
                  onChange={handleInputChange}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <Form.Item
                name="publisher"
                label="Publisher"
                rules={[
                  { required: true, message: "Please input the publisher!" },
                ]}
              >
                <Input
                  name="publisher"
                  value={bookData.publisher}
                  onChange={handleInputChange}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <Form.Item
                name="genre"
                label="Genre"
                rules={[{ required: true, message: "Please input the genre!" }]}
              >
                <Input
                  name="genre"
                  value={bookData.genre}
                  onChange={handleInputChange}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <Form.Item
                name="language"
                label="Language"
                rules={[
                  { required: true, message: "Please input the language!" },
                ]}
              >
                <Input
                  name="language"
                  value={bookData.language}
                  onChange={handleInputChange}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <Form.Item
                name="edition"
                label="Edition"
                rules={[
                  { required: true, message: "Please input the edition!" },
                ]}
              >
                <InputNumber
                  name="edition"
                  value={bookData.edition}
                  onChange={(value) => handleInputChange1("edition", value)}
                  style={{ width: "100%" }}
                  min={1}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <Form.Item
                name="publicationYear"
                label="Publication Year"
                rules={[
                  {
                    required: true,
                    message: "Please input the publication year!",
                  },
                ]}
              >
                <Select
                  name="publicationYear"
                  value={bookData.publicationYear}
                  onChange={(value) =>
                    handleSelectChange("publicationYear", value)
                  }
                  style={{ width: "100%" }}
                >
                  {generateYears().map((year) => (
                    <Select.Option key={year} value={year}>
                      {year}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <Form.Item
                name="numberOfPages"
                label="Number of Pages"
                rules={[
                  {
                    required: true,
                    message: "Please input the number of pages!",
                  },
                ]}
              >
                <InputNumber
                  name="numberOfPages"
                  value={bookData.numberOfPages}
                  onChange={(value) =>
                    handleInputChange1("numberOfPages", value)
                  }
                  style={{ width: "100%" }}
                  min={1}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <Form.Item
                name="price"
                label="Price"
                rules={[{ required: true, message: "Please input the price!" }]}
              >
                <InputNumber
                  name="price"
                  value={bookData.price}
                  onChange={(value) => handleInputChange1("price", value)}
                  style={{ width: "100%" }}
                  min={1}
                  prefix="₹"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <Form.Item
                name="availableCopies"
                label="Available Copies"
                rules={[
                  {
                    required: true,
                    message: "Please input the number of available copies!",
                  },
                ]}
              >
                <InputNumber
                  name="availableCopies"
                  value={bookData.availableCopies}
                  onChange={(value) =>
                    handleInputChange1("availableCopies", value)
                  }
                  style={{ width: "100%" }}
                  min={1}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <Form.Item
                name="copyCount"
                label="Copy Count"
                rules={[
                  {
                    required: true,
                    message: "Please input the number of copies!",
                  },
                ]}
              >
                <InputNumber
                  name="copyCount"
                  value={bookData.copyCount}
                  onChange={(value) => handleInputChange1("copyCount", value)}
                  style={{ width: "100%" }}
                  min={1}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <Form.Item
                name="shelfLocation"
                label="Shelf Location"
                rules={[
                  {
                    required: true,
                    message: "Please input the shelf location!",
                  },
                ]}
              >
                <Input
                  name="shelfLocation"
                  value={bookData.shelfLocation}
                  onChange={handleInputChange}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <Form.Item
                name="description"
                label="Description"
                rules={[{ required: false }]}
              >
                <Input.TextArea
                  name="description"
                  value={bookData.description}
                  onChange={handleInputChange}
                  rows={4}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <Button type="primary" htmlType="submit" loading={isSubmitting} style={{ marginTop: 16 }}>
                Add Book
              </Button>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <Button type="default" onClick={handleClearFields} style={{ marginTop: 16 }}>
                Clear Fields
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>




      <br />
      <br />
      <br />
      <Col xs={24}>
        <Button
          type="dashed"
          icon={<DownloadOutlined />}
          size="small"
          onClick={exportToExcel}
        >
          Download Excel
        </Button>
        <Button
          type="primary"
          icon={<ReloadOutlined />}
          size="small"
          onClick={fetchBooks}
        >
          Refresh
        </Button>
      </Col>
      <Card
        title="2. View All Books"
        size="small"
        style={{ marginBottom: "20px" }}
      >
        <Table
          loading={loading}
          columns={columns}
          dataSource={currentData} // Use paginated data
          rowKey="isbn"
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: books.length, // Total number of records
            onChange: (page, pageSize) =>
              handleTableChange({ current: page, pageSize }), // Handle page change
          }}
          scroll={{ x: 1300 }} 
         
        />
      </Card>
      <Modal
        title="Edit Book"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => form.submit()}
        confirmLoading={isSubmitting}
        okText="Update Book"
      >
        <Form form={form} layout="vertical" onFinish={handleUpdateBook}>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <Form.Item
                name="isbn"
                label="ISBN"
                rules={[{ required: true, message: "Please input the ISBN!" }]}
              >
                <Input style={{ width: "100%" }} disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <Form.Item
                name="title"
                label="Title"
                rules={[{ required: true, message: "Please input the title!" }]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <Form.Item
                name="authors"
                label="Authors"
                rules={[
                  { required: true, message: "Please input the authors!" },
                ]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <Form.Item
                name="publisher"
                label="Publisher"
                rules={[
                  { required: true, message: "Please input the publisher!" },
                ]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <Form.Item
                name="publicationYear"
                label="Publication Year"
                rules={[
                  {
                    required: true,
                    message: "Please input the publication year!",
                  },
                ]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <Form.Item
                name="edition"
                label="Edition"
                rules={[
                  { required: true, message: "Please input the edition!" },
                ]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <Form.Item
                name="genre"
                label="Genre"
                rules={[{ required: true, message: "Please input the genre!" }]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <Form.Item
                name="language"
                label="Language"
                rules={[
                  { required: true, message: "Please input the language!" },
                ]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <Form.Item
                name="numberOfPages"
                label="Number of Pages"
                rules={[
                  {
                    required: true,
                    message: "Please input the number of pages!",
                  },
                ]}
              >
                <Input type="number" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <Form.Item
                name="shelfLocation"
                label="Shelf Location"
                rules={[
                  {
                    required: true,
                    message: "Please input the shelf location!",
                  },
                ]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  { required: true, message: "Please input the description!" },
                ]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <Form.Item
                name="price"
                label="Price"
                rules={[{ required: true, message: "Please input the price!" }]}
              >
                <Input type="number" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <Form.Item
                name="availableCopies"
                label="Available Copies"
                rules={[
                  {
                    required: true,
                    message: "Please input the available copies!",
                  },
                ]}
              >
                <Input type="number" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <Form.Item
                name="copyCount"
                label="Copy Count"
                rules={[
                  { required: true, message: "Please input the copy count!" },
                ]}
              >
                <Input type="number" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default AddBook;
