import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Statistic, Divider, Button } from 'antd';
import { BookOutlined, UserOutlined, DollarOutlined, DatabaseOutlined,ReloadOutlined  } from '@ant-design/icons';
import { message } from 'antd';
import { Column } from '@ant-design/charts'; // Importing Column chart from @ant-design/charts
import axios from 'axios';
import config from '../../../config'; // Assuming your config is set correctly

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);

    // Function to fetch dashboard data
    const fetchData = () => {
      axios
        .get(`${config.curriculaserver}/curricula/admin/library/dashboard`)
        .then((response) => {
          setDashboardData(response.data);
        })
        .catch((error) => {
          message.error('Failed to load dashboard data');
          console.error(error);
        });
    };
  
    // Initially fetch the data when the component mounts
    useEffect(() => {
      fetchData();
    }, []);

  useEffect(() => {
    if (dashboardData) {
      // Process the data for each chart
      const chartData = [
        { name: 'Total Books', value: dashboardData.totalBooks || 0 },
        { name: 'Available Books', value: dashboardData.availableBooks || 0 },
        { name: 'Borrowed Books', value: dashboardData.borrowedBooks || 0 },
        { name: 'Total Fine Amount', value: dashboardData.totalFineAmount || 0 },
        { name: 'Total Fine Collected', value: dashboardData.totalFineCollected || 0 },
        { name: 'Total Journals', value: dashboardData.totalJournals || 0 },
        { name: 'Total Titles', value: dashboardData.totalVolCount || 0 },
      ];

      // Update the chart data as needed
      setDashboardData({ ...dashboardData, chartData });
    }
  }, []);

  // Chart Configuration Template with Color Customization
  const chartConfig = (data, chartType) => ({
    forceFit: true,
    padding: 40,
    title: {
      visible: true,
      text: 'Library Data Overview',
      style: {
        fontSize: 16,
        fontFamily: 'Futura Std Medium Condensed',
        fill: '#252525',
      },
    },
    data: data,
    xField: 'name',
    yField: 'value',
    label: {
      visible: true,
      position: 'top',
      style: {
        fill: '#0D3660',
        fontFamily: 'Futura Std Medium Condensed',
        opacity: 1,
      },
    },
    columnStyle: {
      shadowBlur: 10,
      shadowOffsetX: 5,
      shadowOffsetY: 5,
      cursor: 'pointer',
    },
    yAxis: {
      min: 0,
      style: {
        fontFamily: 'Futura Std Medium Condensed',
      },
    },
    xAxis: {
      style: {
        fontFamily: 'Futura Std Medium Condensed',
      },
    },
    columnBackground: {
      style: {
        fill: 'rgba(0,0,0,0.1)',
      },
    },
    // Explicitly set colors for each chart type
    // color: chartType === 'booksComparison' 
    //   ? ['#4CAF50', '#FF9800']  // Green and Orange for Total vs Available Books
    //   : chartType === 'fineComparison' 
    //   ? ['#2196F3', '#F44336']  // Blue and Red for Fine Amount vs Fine Collected
    //   : chartType === 'journalsComparison' 
    //   ? ['#9C27B0', '#673AB7']  // Purple for Total Books vs Journals
    //   : ['#FFEB3B', '#00BCD4'],  // Default colors
  });

  const { totalBooks, availableBooks,totalVolCount, borrowedBooks, totalFineAmount, totalFineCollected, totalMembers, totalJournals } = dashboardData || {};

  return (
    <div style={{ padding: '20px' }}>
      <Card
        title="Dashboard"
        size="small"
        style={{
          marginTop: '20px',
          boxShadow: 'none',
          border: '1px solid #d9d9d9',
          borderRadius: '16px',
        }}
        extra={[
          <Button
            key="refresh"
            type="primary"
            icon={<ReloadOutlined />}
            onClick={fetchData}
          >
            Refresh
          </Button>,
        ]}
      >
        <Divider />
         {/* Refresh Button
         <Button type="primary" onClick={fetchData} style={{ marginBottom: '20px' }}>
          Refresh Data
        </Button> */}

          

        {/* Dashboard Summary */}
        <Row gutter={16} justify="space-between">
        <Col xs={24} sm={12} md={8} lg={3}>
            <Card
              hoverable
              title="Total Titles"
              size="small"
              style={{
                marginTop: '20px',
                boxShadow: 'none',
                border: '1px solid #d9d9d9',
                borderRadius: '16px',
              }}
            >
              <Statistic
                value={totalVolCount || 0}
                precision={0}
                valueStyle={{ color: '#3f8600' }}
                prefix={<BookOutlined />}
              />
            </Card>
          </Col>
          {/* Total Books */}
          <Col xs={24} sm={12} md={8} lg={3}>
            <Card
              hoverable
              title="Total Books"
              size="small"
              style={{
                marginTop: '20px',
                boxShadow: 'none',
                border: '1px solid #d9d9d9',
                borderRadius: '16px',
              }}
            >
              <Statistic
                value={totalBooks || 0}
                precision={0}
                valueStyle={{ color: '#3f8600' }}
                prefix={<BookOutlined />}
              />
            </Card>
          </Col>

          {/* Available Books */}
          <Col xs={24} sm={12} md={8} lg={3}>
            <Card
              hoverable
              title="Available Books"
              size="small"
              style={{
                marginTop: '20px',
                boxShadow: 'none',
                border: '1px solid #d9d9d9',
                borderRadius: '16px',
              }}
            >
              <Statistic
                value={availableBooks || 0}
                precision={0}
                valueStyle={{ color: '#52c41a' }}
                prefix={<DatabaseOutlined />}
              />
            </Card>
          </Col>

          {/* Total Fine Amount */}
          <Col xs={24} sm={12} md={8} lg={3}>
            <Card
              hoverable
              title="Total Fine Amount"
              size="small"
              style={{
                marginTop: '20px',
                boxShadow: 'none',
                border: '1px solid #d9d9d9',
                borderRadius: '16px',
              }}
            >
              <Statistic
                value={totalFineAmount || 0}
                precision={2}
                valueStyle={{ color: '#1890ff' }}
                prefix={<DollarOutlined />}
              />
            </Card>
          </Col>

           {/* Total Fine Collected */}
           <Col xs={24} sm={12} md={8} lg={3}>
            <Card
              hoverable
              title="Total Fine Collected"
              size="small"
              style={{
                marginTop: "20px",
                boxShadow: "none",
                border: "1px solid #d9d9d9",
                borderRadius: "16px", // Rounded corners for each card
              }}
            >
              <Statistic
                value={isNaN(totalFineCollected) ? 0 : totalFineCollected} // Check if the value is NaN
                precision={2}
                valueStyle={{ color: "#1890ff" }}
                prefix={<DollarOutlined />}
              />
            </Card>
          </Col>

          {/* Borrowed Books */}
          <Col xs={24} sm={12} md={8} lg={3}>
            <Card
              hoverable
              title="Borrowed Books"
              size="small"
              style={{
                marginTop: '20px',
                boxShadow: 'none',
                border: '1px solid #d9d9d9',
                borderRadius: '16px',
              }}
            >
              <Statistic
                value={borrowedBooks || 0}
                precision={0}
                valueStyle={{ color: '#cf1322' }}
              />
            </Card>
          </Col>

          {/* Total Members */}
          <Col xs={24} sm={12} md={8} lg={3}>
            <Card
              hoverable
              title="Total Members"
              size="small"
              style={{
                marginTop: '20px',
                boxShadow: 'none',
                border: '1px solid #d9d9d9',
                borderRadius: '16px',
              }}
            >
              <Statistic
                value={totalMembers || 0}
                precision={0}
                valueStyle={{ color: '#eb2f96' }}
                prefix={<UserOutlined />}
              />
            </Card>
          </Col>

          {/* Total Journals */}
          <Col xs={24} sm={12} md={8} lg={3}>
            <Card
              hoverable
              title="Total Journals"
              size="small"
              style={{
                marginTop: '20px',
                boxShadow: 'none',
                border: '1px solid #d9d9d9',
                borderRadius: '16px',
              }}
            >
              <Statistic
                value={totalJournals || 0}
                precision={0}
                valueStyle={{ color: '#faad14' }}
              />
            </Card>
          </Col>
        </Row>

        <Divider />

        {/* Chart Section */}
        <Row gutter={16}>
          {/* Total Books vs Available Books Chart */}
          <Col xs={24} sm={12} md={8} lg={6}>
            <Card title="Total Books vs Available Books" size="small" hoverable>
              <Column {...chartConfig([{ name: 'Total Books', value: totalBooks || 0 }, { name: 'Available Books', value: availableBooks || 0 }], 'booksComparison')} />
            </Card>
          </Col>

          {/* Total Books vs Borrowed Books Chart */}
          <Col xs={24} sm={12} md={8} lg={6}>
            <Card title="Total Books vs Borrowed Books" size="small" hoverable>
              <Column {...chartConfig([{ name: 'Total Books', value: totalBooks || 0 }, { name: 'Borrowed Books', value: borrowedBooks || 0 }], 'booksComparison')} />
            </Card>
          </Col>

          {/* Total Fine Amount vs Total Fine Collected Chart */}
          <Col xs={24} sm={12} md={8} lg={6}>
            <Card title="Total Fine Amount vs Total Fine Collected" size="small" hoverable>
              <Column {...chartConfig([{ name: 'Total Fine Amount', value: totalFineAmount || 0 }, { name: 'Total Fine Collected', value: totalFineCollected || 0 }], 'fineComparison')} />
            </Card>
          </Col>

          {/* Total Books vs Total Journals Chart */}
          <Col xs={24} sm={12} md={8} lg={6}>
            <Card title="Total Books vs Total Journals" size="small" hoverable>
              <Column {...chartConfig([{ name: 'Total Books', value: totalBooks || 0 }, { name: 'Total Journals', value: totalJournals || 0 }], 'journalsComparison')} />
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default Dashboard;
