import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  Spin,
  Table,
  Card,
  Input,
  Button,
  Space,
  Modal,
  Row,
  Col,
  message,
} from "antd";
import { SearchOutlined, ReloadOutlined } from "@ant-design/icons";
import axios from "axios";
import config from "../../../config";

const ReturnBookPage = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [transactionData, setTransactionData] = useState([]);
  const [isReturnModalVisible, setIsReturnModalVisible] = useState(false);
  const [isRenewalModalVisible, setIsRenewalModalVisible] = useState(false);
  const [selectedBook, setSelectedBook] = useState(null);
  const [fineAmount, setFineAmount] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isFinePaid, setIsFinePaid] = useState(false);
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] || ""}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <span style={{ backgroundColor: "#ffc069", padding: 0 }}>{text}</span>
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Member ID",
      dataIndex: "memberId",
      key: "memberId",
      ...getColumnSearchProps("memberId"),
    },
    {
      title: "Member Name",
      dataIndex: "memberName",
      key: "memberName",
      ...getColumnSearchProps("memberName"),
    },
    {
      title: "Book ISBN",
      dataIndex: "bookISBN",
      key: "bookISBN",
      ...getColumnSearchProps("bookISBN"),
    },
    {
      title: "Book ID",
      dataIndex: "bookId",
      key: "bookId",
      ...getColumnSearchProps("bookId"),
    },
    {
      title: "Book Title",
      dataIndex: "bookTitle",
      key: "bookTitle",
      ...getColumnSearchProps("bookTitle"),
    },
    {
      title: "Issue Date",
      dataIndex: "issueDate",
      key: "issueDate",
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: "Fine",
      dataIndex: "fineAmount",
      key: "fineAmount",
      render: (text) => (text > 0 ? `$${text}` : "No Fine"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => {
        return (
          <Space size="middle">
            <Button
              type="primary"
              disabled={record.status !== "Borrowed" || record.fineAmount > 0}
              onClick={() => handleReturnOrRenew(record, false)} // Return
            >
              Return
            </Button>
            <Button
              type="default"
              disabled={record.status !== "Borrowed" || record.fineAmount > 0} // Disable Renew if there's a fine
              onClick={() => handleReturnOrRenew(record, true)} // Renew
            >
              Renew
            </Button>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchTransactionHistory(); // Initial data load
      setLoading(false);
    };

    fetchData();
  }, []);

  const fetchTransactionHistory = async () => {
    try {
      const response = await axios.get(
        `${config.curriculaserver}/curricula/admin/library/transaction/history`
      );

      const formattedData = response.data
        .filter((transaction) => transaction.status === "Borrowed")
        .map((transaction) => {
          return {
            memberId: transaction.memberId,
            memberName: transaction.memberName,
            bookISBN: transaction.bookISBN,
            bookId: transaction.bookId,
            bookTitle: transaction.bookTitle,
            issueDate: transaction.issueDate,
            dueDate: transaction.dueDate,
            returnDate: transaction.returnDate,
            status: transaction.status,
            fineAmount: transaction.fineAmount || 0, // Ensure fineAmount defaults to 0
            renewalCount:transaction.renewalCount ||0,

          };
        });
      setTransactionData(formattedData);
    } catch (error) {
      console.error("Error fetching transaction history:", error);
      message.error("Error fetching transaction history");
    }
  };

  const handleReturnOrRenew = (book, isRenew) => {
    setSelectedBook(book);
    setFineAmount(calculateFine(book.issueDate, book.dueDate)); // Updated fine calculation
    setIsFinePaid(false);

    if (isRenew) {
      setIsRenewalModalVisible(true); // Open Renewal Modal
    } else {
      setIsReturnModalVisible(true); // Open Return Modal
    }
  };

  const calculateFine = (issueDate, dueDate) => {
    const today = new Date();
    const due = new Date(dueDate);

    // Calculate the number of days the book is overdue
    const lateDays = Math.ceil((today - due) / (1000 * 3600 * 24));

    // If it's late, apply a fine
    if (lateDays > 0) {
      return lateDays * 2; // $2 per day fine
    } else {
      return 0; // No fine if returned within due date
    }
  };

  // const handleRenew = async () => {
  //   if (fineAmount > 0) {
  //     message.error("You must return the book and pay any fine before renewing.");
  //     return;
  //   }

  //   if (selectedBook.status !== "Borrowed") {
  //     message.error("This book cannot be renewed as it is not in borrowed status.");
  //     return;
  //   }

  //   try {
  //     const response = await axios.put(
  //       `${config.curriculaserver}/curricula/admin/library/transaction/renew`,
  //       {
  //         bookId: selectedBook.bookId,
  //         newIssueDate: selectedBook.issueDate,  // Send new issue date as per the backend's expectation
  //         newDueDate: new Date(new Date(selectedBook.dueDate).setDate(new Date(selectedBook.dueDate).getDate() + 14)),  // Renew for 14 more days
  //       }
  //     );

  //     if (response.status === 200) {
  //       setTransactionData((prevData) =>
  //         prevData.map((book) =>
  //           book.bookId === selectedBook.bookId
  //             ? { ...book, dueDate: new Date(new Date().setDate(new Date().getDate() + 14)) }
  //             : book
  //         )
  //       );
  //       await fetchTransactionHistory();
  //       setIsRenewalModalVisible(false);
  //       message.success("Book renewed successfully!");
  //     } else {
  //       throw new Error("Failed to process the renewal.");
  //     }
  //   } catch (error) {
  //     console.error("Error renewing book:", error);
  //     message.error("Error processing renewal. Please try again later.");
  //   }
  // };

  const handleRenew = async () => {
    if (fineAmount > 0) {
      message.error(
        "You must return the book and pay any fine before renewing."
      );
      return;
    }

    if (selectedBook.status !== "Borrowed") {
      message.error(
        "This book cannot be renewed as it is not in borrowed status."
      );
      return;
    }

    if (selectedBook.renewalCount >= 3) {
      message.error("Maximum renewal limit reached. Cannot renew.");
      return;
    }

    try {
      const response = await axios.put(
        `${config.curriculaserver}/curricula/admin/library/transaction/renew`,
        {
          bookId: selectedBook.bookId,
          newIssueDate: selectedBook.issueDate,
          newDueDate: new Date(
            new Date(selectedBook.dueDate).setDate(
              new Date(selectedBook.dueDate).getDate() + 14
            )
          ), // Add 14 days to due date
        }
      );

      if (response.status === 200) {
        const updatedRenewalCount = response.data.renewalCount; // Get updated renewalCount

        // Update selectedBook with new due date and renewal count
        setSelectedBook((prevBook) => ({
          ...prevBook,
          dueDate: new Date(new Date().setDate(new Date().getDate() + 14)), // Update due date
          renewalCount: updatedRenewalCount ?? 0, // Ensure renewalCount is a valid number
        }));

        // Update transactionData to reflect the new renewalCount and dueDate
        setTransactionData((prevData) =>
          prevData.map((book) =>
            book.bookId === selectedBook.bookId
              ? {
                  ...book,
                  dueDate: new Date(
                    new Date().setDate(new Date().getDate() + 14)
                  ), // Update due date
                  renewalCount: updatedRenewalCount ?? 0, // Update renewal count
                }
              : book
          )
        );

        await fetchTransactionHistory(); // Update the transaction history if necessary
        setIsRenewalModalVisible(false);
        message.success("Book renewed successfully!");
      } else {
        throw new Error("Failed to process the renewal.");
      }
    } catch (error) {
      console.error("Error renewing book:", error);
      message.error("Your Renewal limit exceeded. Please return");
    }
  };

  const handleConfirmReturn = async () => {
    if (!selectedBook) return;

    if (fineAmount > 0 && !isFinePaid) {
      message.error("Please pay the fine before confirming return.");
      return;
    }

    const returnDate = new Date().toISOString();
    const payload = {
      bookId: selectedBook.bookId,
      bookISBN: selectedBook.bookISBN,
      returnDate,
      fineAmount,
    };

    if (selectedBook.status !== "Borrowed") {
      message.error(
        "This book cannot be returned as it is not in borrowed status."
      );
      return;
    }

    setIsProcessing(true);

    try {
      const response = await axios.put(
        `${config.curriculaserver}/curricula/admin/library/transaction/return`,
        payload
      );

      if (response.status === 200) {
        setTransactionData((prevData) =>
          prevData.map((book) =>
            book.bookId === selectedBook.bookId
              ? { ...book, status: "Returned", fineAmount }
              : book
          )
        );
        await fetchTransactionHistory();
        setIsReturnModalVisible(false);
        message.success("Book returned successfully!");
      } else {
        throw new Error("Failed to process the return.");
      }
    } catch (error) {
      console.error("Error confirming return:", error);
      message.error("Error processing return. Please try again later.");
    } finally {
      setIsProcessing(false);
    }
  };

  const handlePayFine = async () => {
    const payload = {
      bookId: selectedBook.bookId,
      fineAmount: fineAmount,
    };

    try {
      const response = await axios.put(
        `${config.curriculaserver}/curricula/admin/library/transaction/update-fine`,
        payload
      );

      if (response.status === 200) {
        message.success("Fine successfully updated in transaction collection.");
        setIsFinePaid(true); // Fine is now paid
      } else {
        message.error("Error updating fine in transaction collection.");
      }
    } catch (error) {
      console.error("Error updating fine in transaction collection:", error);
      message.error("Error updating fine. Please try again later.");
    }
  };

  const filteredData = useMemo(() => {
    return transactionData.filter(
      (record) =>
        record.bookTitle.toLowerCase().includes(searchText.toLowerCase()) ||
        record.memberId.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [searchText, transactionData]);

  return loading ? (
    <Spin size="large" />
  ) : (
    <div className="card-container padding-1">
      <Row gutter={[16, 16]} justify="end">
        <Col xs={24}>
          <Card
            title="Return or Renew Book"
            size="small"
            style={{
              marginTop: "20px",
            }}
            extra={[
              <Button
                key="refresh"
                type="primary"
                icon={<ReloadOutlined />}
                onClick={fetchTransactionHistory}
              >
                Refresh
              </Button>,
            ]}
          >
            <Table
              columns={columns}
              dataSource={filteredData}
              rowKey="bookId"
              pagination={{
                pageSize: 10,
              }}
            />
          </Card>
        </Col>
      </Row>

      {/* Modal for Renew */}
      {/* <Modal
        title="Renew Book"
        visible={isRenewalModalVisible}
        onOk={handleRenew}
        onCancel={() => setIsRenewalModalVisible(false)}
        confirmLoading={isProcessing}
        okButtonProps={{
          disabled: fineAmount > 0 && !isFinePaid,  // Disable OK button if fine is greater than 0 and unpaid
        }}
      >
        <p><strong>Book ID: </strong>{selectedBook?.bookId}</p>
        <p><strong>Member ID: </strong>{selectedBook?.memberId}</p>
        <p><strong>Book Title: </strong>{selectedBook?.bookTitle}</p>
        <p><strong>ISBN: </strong>{selectedBook?.bookISBN}</p>
        <p><strong>Fine: </strong>{fineAmount > 0 ? `$${fineAmount}` : "No fine"}</p>
        <p>Are you sure you want to renew this book?</p>
      </Modal> */}

      <Modal
        title="Renew Book"
        visible={isRenewalModalVisible}
        onOk={handleRenew}
        onCancel={() => setIsRenewalModalVisible(false)}
        confirmLoading={isProcessing}
        okButtonProps={{
          disabled: fineAmount > 0 || selectedBook?.renewalCount >= 3, // Disable if fine is unpaid or renewal limit is reached
        }}
      >
        <p>
          <strong>Book ID: </strong>
          {selectedBook?.bookId}
        </p>
        <p>
          <strong>Member ID: </strong>
          {selectedBook?.memberId}
        </p>
        <p>
          <strong>Book Title: </strong>
          {selectedBook?.bookTitle}
        </p>
        <p>
          <strong>ISBN: </strong>
          {selectedBook?.bookISBN}
        </p>
        <p>
          <strong>Fine: </strong>
          {fineAmount > 0 ? `$${fineAmount}` : "No fine"}
        </p>
        <p>
    <strong>Renewal Count:</strong> {selectedBook?.renewalCount ?? 0}/3
  </p>
        <p>Are you sure you want to renew this book?</p>
      </Modal>

      {/* Modal for Return */}
      <Modal
        title="Return Book Details"
        visible={isReturnModalVisible}
        onCancel={() => setIsReturnModalVisible(false)}
        footer={[
          <Button key="back" onClick={() => setIsReturnModalVisible(false)}>
            Cancel
          </Button>,
          fineAmount > 0 ? (
            <Button key="paid" type="primary" onClick={handlePayFine}>
              Pay Fine
            </Button>
          ) : null,
          <Button
            onClick={handleConfirmReturn}
            disabled={
              isProcessing || !selectedBook || (fineAmount > 0 && !isFinePaid)
            } // Disable Return if there's a fine and it's unpaid
          >
            Confirm Return
          </Button>,
        ]}
      >
        <p>
          <strong>Book ID: </strong>
          {selectedBook?.bookId}
        </p>
        <p>
          <strong>Member ID: </strong>
          {selectedBook?.memberId}
        </p>
        <p>
          <strong>Book Title: </strong>
          {selectedBook?.bookTitle}
        </p>
        <p>
          <strong>ISBN: </strong>
          {selectedBook?.bookISBN}
        </p>
        <p>
          <strong>Fine: </strong>
          {fineAmount > 0 ? `$${fineAmount}` : "No fine"}
        </p>
      </Modal>
    </div>
  );
};

export default ReturnBookPage;
