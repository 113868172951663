import React, { Component } from 'react';
import { Row, Col, Card, List, Descriptions, Avatar, Spin, message, Input, Checkbox, Button, Result } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import config from '../../config';
import ViewPO from '../admin/ProgramOutcomes/ViewPO';

class ProgramObjective extends Component {

    state = {
        loading: true,
        open: false,
        PLO: [], 
        PSO: [], 
        PEO: [],
        error: 0,
        CLO1: '',
        CLO2: '',
        CLO3: '',
        CLO4: '',
        CLO5: '',
        CLO6: '',
        OC1: [],
        OC2: [],
        OC3: [],
        OC4: [],
        OC5: [],
        OC6: [],
    };

    async componentDidMount() {
        await this.POget();
        this.setState({ loading: false, open: true });
    }

    POget = () => {
        let DEPT = this.props.common.user.DEPARTMENT;

        let data = {
            info: this.props.common.user,
            key: config.key,
            dept: DEPT
        };

        axios.post(config.curriculaserver + '/curricula/batchcoordinator/po/view', data)
            .then(async res => {
                if (res.data.Status === 1) {
                    await this.setState({
                        PLO: res.data.result.PLO,
                        PSO: res.data.result.PSO,
                        PEO: res.data.result.PEO,
                        loading: false
                    });
                    await message.success('Information Loaded!');
                } else {
                    await this.setState({
                        PLO: res.data.default.PLO,
                        PSO: res.data.default.PSO,
                        PEO: res.data.default.PEO,
                        loading: false
                    });
                }
            });
    };

    // Function to render checkboxes dynamically based on the outcome number
    FunctionCheckboxGroup = (coNumber) => {
        const correlationLevels = [
            { label: 'Slight (Low)', value: 1 },
            { label: 'Moderate (Medium)', value: 2 },
            { label: 'Substantial (High)', value: 3 },
            { label: 'No Correlation', value: 0 },
        ];

        return correlationLevels.map(level => (
            <Checkbox key={level.value} value={level.value}>
                {level.label}
            </Checkbox>
        ));
    };

    // Handle changes in the input fields for CLOs
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
        });
    };

    // Handle changes in the checkbox groups
    handleCheckboxChange = (co, values) => {
        this.setState({
            [`OC${co}`]: values,
        });
    };

    render() {
        let { open, PEO, PSO, PLO } = this.state;
        return (
            <div>
                {/* <Spin spinning={this.state.loading}>
                    {open ? (
                        <Descriptions
                            bordered
                            column={{ sm: 1, md: 1, lg: 1, xs: 1 }}
                            size="small"
                            className="padding-1 justify"
                        >
                            <Descriptions.Item label="Department" span={2}>
                                {this.props.common.user.DEPARTMENT}
                            </Descriptions.Item>
                            <Descriptions.Item label="Programme Educational Objectives (PEOs)" span={2}>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={PEO}
                                    renderItem={(item, val) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={<Avatar style={{ fontSize: "12px" }} size={40}>PEO {val + 1}</Avatar>}
                                                description={item}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label="Programme Outcomes (PO)" span={2}>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={PLO}
                                    renderItem={(item, val) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={<Avatar style={{ fontSize: "12px" }} size={40}>PO {val < 9 ? 0 : ''}{val + 1}</Avatar>}
                                                description={item}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label="Programme Specific Outcomes (PSO)" span={2}>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={PSO}
                                    renderItem={(item, val) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={<Avatar style={{ fontSize: "12px" }} size={40}>PSO {val + 1}</Avatar>}
                                                description={item}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </Descriptions.Item>
                        </Descriptions>
                    ) : ''}
                </Spin> */}

                <Spin spinning={this.state.loading}>
                    <Row gutter={[16, 16]} justify="start">
                        <Col xs={24}>
                            <Card title="View Outcomes (PO = PO + PSO)" bordered={true} className='justify'>
                                <ViewPO />
                            </Card>
                        </Col>
                    </Row>
                </Spin>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});

export default withRouter(connect(mapStateToProps, {})(ProgramObjective));
