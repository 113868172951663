import React from 'react';
import { Tabs } from 'antd';
import AddBook from './AddBook';
// import EditBook from './EditBook';
import AvailableBooks from './AvailableBooks';
import AddMember from './AddMember';
import AddTransaction from './AddTransaction';
import TransactionDetails from './transactiondetails';
import ReturnBookPage from './ReturnBook';
import Addjournal from './AddJournal'
 import Dashboard from './Dashboard';


const { TabPane } = Tabs;

const LibraryManagement = () => {
    return (
        <div style={{ padding: '10px' }}>
            <Tabs defaultActiveKey="1">
            <TabPane tab="Home" key="1">
                     <Dashboard /> 
                </TabPane>
                <TabPane tab="Add Book" key="2">
                    <AddBook />
                </TabPane>
                <TabPane tab="Add Journal" key="3">
                    <Addjournal />
                </TabPane>
                
             
                <TabPane tab="Add Member" key="4">
                    <AddMember />
                </TabPane>
                <TabPane tab="Book Issue Process" key="5">
                    <AddTransaction />
                </TabPane>
                <TabPane tab="Book Return" key="6">
                    <ReturnBookPage />
                </TabPane>
                <TabPane tab="Transaction Details" key="7">
                    <TransactionDetails />
                </TabPane>

                
                
                
            </Tabs>
        </div>
    );
};

export default LibraryManagement;
