import React, { Component } from 'react';
import './App.less';
import { Provider } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import AddBook from './pages/admin/LibraryManagement/AddBook'; // Import the AddBook component
import setAuthToken from "./redux/utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { setCurrentUser, logoutUser } from './redux/actions/commonActions';
import store from "./redux/store";
import PrivateRoute from './PrivateRoute';
import Login from './pages/common/LoginNew';
import AdminHome from './pages/admin/Home';
import CoordinatorHome from './pages/coordinator/Home';
import StudentHome from './pages/student/Home';
import FacultyHome from './pages/faculty/Home';
import batchCoordinatorHome from './pages/batchcoordinator/Home';
import hodHome from './pages/hod/Home';
import studentaffairsHome from './pages/studentaffairs/Home';
import disableDevtool from 'disable-devtool';

if (localStorage.jwtToken) {
  const token = localStorage.jwtToken;
  setAuthToken(token);
  const decoded = jwt_decode(token);
  if (decoded.ROLE !== "A") disableDevtool();
  store.dispatch(setCurrentUser(decoded));
  const currentTime = Date.now() / 1000; // to get milliseconds
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
    window.location.href = "../";
  }
}

// disableDevtool();

class App extends Component {

  render() {
    return (
      <Provider store={store}>
        <div>
          <Route exact path="/" component={Login} />
          <Switch>
            <PrivateRoute user="S" exact path="/student/home" component={StudentHome} />
            <PrivateRoute user="TF" exact path="/faculty/home" component={FacultyHome} />
            <PrivateRoute user="C" exact path="/coordinator/home" component={CoordinatorHome} />
            <PrivateRoute user="A" exact path="/admin/home" component={AdminHome} />
            
            {/* <PrivateRoute user="A" exact path="/admin/add-book" component={AddBook} /> // Add route for adding books */}
            
            <PrivateRoute user="B" exact path="/batchcoordinator/home" component={batchCoordinatorHome} />
            <PrivateRoute user="H" exact path="/hod/home" component={hodHome} />
            <PrivateRoute user="SA" exact path="/studentaffairs/home" component={studentaffairsHome} />
          </Switch>
        </div>
      </Provider>
    );
  }
}

export default withRouter(App);
