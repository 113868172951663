import React, { useState, useEffect } from "react";
import { Form, Input, Button, Radio, Upload, Row, Col, message, Card } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import config from "../../../config";

const Tuition = ({ onStepComplete, studentID }) => {
  const [form] = Form.useForm();
  const [isKindleIssued, setIsKindleIssued] = useState(null);
  const [isChromebookIssued, setIsChromebookIssued] = useState(null);
  const [isStationeryIssued, setIsStationeryIssued] = useState(null);
  const [fileList, setFileList] = useState({
    kindleProof: [],
    chromebookProof: [],
    stationeryProof: [],
  });
  const [loading, setLoading] = useState(false);

  //  Fetch existing tuition details from the server
    
    useEffect(() => {
      if (studentID) {
        form.setFieldsValue({ studentID });
        fetchTuitionData();
      }
    }, [studentID, form]);
  
    //  Handle file selection for proof uploads
    const handleFileChange = (fileType, { fileList }) => {
      setFileList(prev => ({ ...prev, [fileType]: fileList.slice(-1) })); // Keep only latest file
    };

    const fetchTuitionData = async () => {
      try {
        const { data } = await axios.get(
          `${config.curriculaserver}/curricula/admin/tuition/get/${studentID}`
        );
  
        if (data && data.tuitionDetails) {
          form.setFieldsValue({
            kindleIssued: data.tuitionDetails.kindleIssued || "",
            kindleDetails: data.tuitionDetails.kindleDetails || "",
            chromebookIssued: data.tuitionDetails.chromebookIssued || "",
            chromebookDetails: data.tuitionDetails.chromebookDetails || "",
            stationeryIssued: data.tuitionDetails.stationeryIssued || "",
            stationeryDetails: data.tuitionDetails.stationeryDetails || "",
          });
  
          setFileList({
            kindleProof: data.tuitionDetails.kindleProof
              ? [{ url: `${config.fileServer}/${data.tuitionDetails.kindleProof}`, name: "Kindle Proof" }]
              : [],
            chromebookProof: data.tuitionDetails.chromebookProof
              ? [{ url: `${config.fileServer}/${data.tuitionDetails.chromebookProof}`, name: "Chromebook Proof" }]
              : [],
            stationeryProof: data.tuitionDetails.stationeryProof
              ? [{ url: `${config.fileServer}/${data.tuitionDetails.stationeryProof}`, name: "Stationery Proof" }]
              : [],
          });
        }
      } catch (error) {
        console.error("Error fetching tuition data:", error);
        message.error("Enter the data");
      }
    };
    
  
    //  Save and Continue
    const handleSaveAndContinue = async () => {
      try {
        await form.validateFields();
        
        if (!studentID) {
          message.error("❌ Student ID is missing. Please enter personal info first!");
          return;
        }
        
        const allFields = form.getFieldsValue(true);
        const formData = new FormData();
        Object.keys(allFields).forEach((key) => {
          formData.append(key, allFields[key]);
        });
  
        ["kindleProof", "chromebookProof", "stationeryProof"].forEach((key) => {
          if (fileList[key].length > 0 && fileList[key][0].originFileObj) {
            formData.append(key, fileList[key][0].originFileObj);
          }
        });
  
        formData.append("studentID", studentID);
        
        setLoading(true);
        const response = await axios.post(
          `${config.curriculaserver}/curricula/admin/tuition/add`,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
  
        if (response.data.success) {
          message.success("Tuition details saved successfully!");
          onStepComplete();
        } else {
          message.error(response.data.message || "Failed to save data.");
        }
      } catch (error) {
        console.error("Validation Error:", error);
        message.error("⚠️ Please fill all required fields!");
      } finally {
        setLoading(false);
      }
    };
  
    
    

  return (
    <div className="site-layout-content" style={{ padding: "20px" }}>
      <Card>
        <Form form={form} layout="vertical" 
        initialValues={{
    kindleIssued: "",
    kindleDetails: "",
    chromebookIssued: "",
    chromebookDetails: "",
    stationeryIssued: "",
    stationeryDetails: "",
  }}>
        <Row gutter={16}>
        
            <Col xs={24} sm={8} md={8}>
              <Form.Item label="Student ID" name="studentID">
                <Input disabled />
              </Form.Item>
              {/*  Fix: Hidden Input to Ensure Student ID is Sent */}
              <Form.Item name="studentID" hidden>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item label="Issued with Kindle?" name="kindleIssued" rules={[{ required: true, message: "Please specify if issued with Kindle!" }]}>
                <Radio.Group onChange={(e) => setIsKindleIssued(e.target.value)}>
                  <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                </Radio.Group>
              </Form.Item>
              {isKindleIssued === "yes" && (
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Kindle details" name="kindleDetails" rules={[{ required: true, message: "Please provide Kindle details!" }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Upload Kindle Proof" name="kindleProof" rules={[{ required: true, message: "Please provide Kindle details!" }]}>
                      <Upload beforeUpload={() => false}  maxCount={1} fileList={fileList.kindleProof} onChange={(info) => handleFileChange("kindleProof", info)}>
                        <Button icon={<UploadOutlined />}>Upload Proof</Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item label="Issued with Chromebook?" name="chromebookIssued" rules={[{ required: true, message: "Please specify if issued with Chromebook!" }]}>
                <Radio.Group onChange={(e) => setIsChromebookIssued(e.target.value)}>
                  <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                </Radio.Group>
              </Form.Item>
              {isChromebookIssued === "yes" && (
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Chromebook details" name="chromebookDetails" rules={[{ required: true, message: "Please provide Chromebook details!" }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Upload Chromebook Proof" name="chromebookProof" rules={[{ required: true, message: "Please provide Kindle details!" }]}>
                      <Upload beforeUpload={() => false}  maxCount={1} fileList={fileList.chromebookProof} onChange={(info) => handleFileChange("chromebookProof", info)}>
                        <Button icon={<UploadOutlined />}>Upload Proof</Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item label="Issued with Other Stationery?" name="stationeryIssued" rules={[{ required: true, message: "Please specify if issued with stationery!" }]}>
                <Radio.Group onChange={(e) => setIsStationeryIssued(e.target.value)}>
                  <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                </Radio.Group>
              </Form.Item>
              {isStationeryIssued === "yes" && (
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Other Stationery details" name="stationeryDetails" rules={[{ required: true, message: "Please provide stationery details!" }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Upload Stationery Proof" name="stationeryProof" rules={[{ required: true, message: "Please provide Kindle details!" }]}>
                      <Upload beforeUpload={() => false}  maxCount={1} fileList={fileList.stationeryProof} onChange={(info) => handleFileChange("stationeryProof", info)}>
                        <Button icon={<UploadOutlined />}>Upload Proof</Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>

          <Form.Item>
            <Button type="primary" onClick={handleSaveAndContinue} loading={loading}>Continue</Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Tuition;
