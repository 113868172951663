import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Row,
  Col,
  message,
  Table,
  Modal,
  Card,
} from "antd";
import axios from "axios";
import moment from "moment";
import config from "../../../config";
import {
  DownloadOutlined,
  ReloadOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import * as XLSX from "xlsx";

const AddJournalForm = () => {
  const [form] = Form.useForm();
  const [journalType, setJournalType] = useState("");
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingJournal, setEditingJournal] = useState(null);

  const handleSubmit = async (values) => {
    const formattedValues = {
      ...values,
      startYearMonth: values.startYearMonth
        ? values.startYearMonth.format("YYYY-MM")
        : null,
      endYearMonth: values.endYearMonth
        ? values.endYearMonth.format("YYYY-MM")
        : null,
      digitalLink: journalType === "online" ? values.digitalLink : null,
      collectionLocation:
        journalType === "offline" ? values.collectionLocation : null,
    };

    try {
      const response = await axios.post(
        `${config.curriculaserver}/curricula/admin/library/journals/add`,
        formattedValues
      );
      if (response.status === 201) {
        message.success("Journal added successfully!");
        form.resetFields(); // Reset form after successful submission
        fetchJournals(); // Refresh the journal list
      }
    } catch (error) {
      message.error("Failed to add journal, please try again!");
    }
  };

  const fetchJournals = async () => {
    try {
      const response = await axios.get(
        `${config.curriculaserver}/curricula/admin/library/journals`
      );
      setData(response.data.journalDetails);
    } catch (error) {
      message.error("Failed to load journal details!");
    }
  };

  // const handleEditClick = (journal) => {
  //   setEditingJournal(journal);
  //   form.setFieldsValue({
  //     ...journal,
  //     startYearMonth: journal.startYearMonth
  //       ? moment(journal.startYearMonth)
  //       : null,
  //     endYearMonth: journal.endYearMonth
  //       ? moment(journal.endYearMonth)
  //       : null,
  //   });
  //   setIsModalVisible(true);
  // };

  const handleDeleteClick = async (issn) => {
    try {
      // Send DELETE request to the backend with the ISSN
      const response = await axios.delete(
        `${config.curriculaserver}/curricula/admin/library/journals/${issn}`
      );
  
      if (response.status === 200) {
        message.success("Journal deleted successfully!");
        fetchJournals(); // Refresh the journal list
      }
    } catch (error) {
      message.error("Failed to delete journal, please try again!");
    }
  };

  // const handleModalCancel = () => {
  //   setIsModalVisible(false);
  //   setEditingJournal(null);
  // };

  // const handleEditSubmit = async (values) => {
  //   // Format the input values as per the backend requirements
  //   const formattedValues = {
  //     ...values,
  //     startYearMonth: values.startYearMonth
  //       ? values.startYearMonth.format("YYYY-MM")
  //       : null,
  //     endYearMonth: values.endYearMonth
  //       ? values.endYearMonth.format("YYYY-MM")
  //       : null,
  //     // If journal type is 'online', set collectionLocation to 'N/A'
  //     collectionLocation:
  //       values.journalType === "offline" ? values.collectionLocation : "N/A",
  //     // If journal type is 'offline', set digitalLink to 'N/A'
  //     digitalLink: values.journalType === "online" ? values.digitalLink : "N/A",
  //   };
  
  //   try {
  //     const response = await axios.put(
  //       `${config.curriculaserver}/curricula/admin/library/journals/update/${values.issn}`,
  //       formattedValues
  //     );
  //     if (response.status === 200) {
  //       message.success("Journal updated successfully!");
  //       fetchJournals(); // Refresh the journal list
  //       handleModalCancel(); // Close the modal
  //     }
  //   } catch (error) {
  //     message.error("Failed to update journal, please try again!");
  //   }
  // };
  
  

  const columns = [
    { title: "S.No", dataIndex: "serial", render: (text, record, index) => index + 1 },
    { title: "Journal Type", dataIndex: "journalType", key: "journalType" },
    { title: "Journal Title", dataIndex: "journalTitle", key: "journalTitle" },
    { title: "ISSN", dataIndex: "issn", key: "issn" },
    { title: "Publisher", dataIndex: "publisher", key: "publisher" },
    { title: "Frequency", dataIndex: "frequency", key: "frequency" },
    {
      title: "Start Year & Month",
      dataIndex: "startYearMonth",
      key: "startYearMonth",
      render: (text) => moment(text).format("YYYY-MM"),
    },
    {
      title: "End Year & Month",
      dataIndex: "endYearMonth",
      key: "endYearMonth",
      render: (text) => (text ? moment(text).format("YYYY-MM") : "N/A"),
    },
    { title: "Volume Information", dataIndex: "volume", key: "volume" },
    { title: "Issue Number", dataIndex: "issueNumber", key: "issueNumber" },
    { title: "Description", dataIndex: "description", key: "description" },
    { title: "Keywords", dataIndex: "keywords", key: "keywords" },
    { title: "Status", dataIndex: "status", key: "status" },
    {
      title: "Collection Location",
      dataIndex: "collectionLocation",
      key: "collectionLocation",
      render: (text) => text || "N/A",
    },
    {
      title: "Digital Link",
      dataIndex: "digitalLink",
      key: "digitalLink",
      render: (text) => text || "N/A",
      width: "10%",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <span>
          
          <Button
            type="danger"
            icon={<DeleteOutlined />}
            size="small"
            onClick={() => handleDeleteClick(record.issn)}
          />
        </span>
      ),
    },
  ];

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Journals");
    XLSX.writeFile(wb, "journals.xlsx");
  };

  const handleJournalTypeChange = (value) => {
    setJournalType(value); // Update the journal type state when it changes
  };

  useEffect(() => {
    fetchJournals();
  }, []);

  return (
    <>
      {/* Add Journal Form */}
      <Card title="Add New Journal" size="small" style={{ marginTop: "20px", boxShadow: "none", border: "1px solid #d9d9d9" }}>
  <Form form={form} onFinish={handleSubmit} layout="vertical">
    <Row gutter={[16, 16]}>
      {/* Journal Type */}
      <Col xs={24} sm={12} md={8} lg={4}>
        <Form.Item name="journalType" label="Journal Type" rules={[{ required: true, message: "Please select the journal type" }]}>
          <Select onChange={handleJournalTypeChange}>
            <Select.Option value="online">Online</Select.Option>
            <Select.Option value="offline">Offline</Select.Option>
          </Select>
        </Form.Item>
      </Col>
      {/* Journal Title */}
      <Col xs={24} sm={12} md={8} lg={4}>
        <Form.Item name="journalTitle" label="Journal Title" rules={[{ required: true, message: "Please enter the journal title" }]}>
          <Input />
        </Form.Item>
      </Col>
      {/* ISSN */}
      <Col xs={24} sm={12} md={8} lg={4}>
        <Form.Item name="issn" label="ISSN" rules={[{ required: true, message: "Please enter the ISSN" }]}>
          <Input />
        </Form.Item>
      </Col>
      {/* Publisher */}
      <Col xs={24} sm={12} md={8} lg={4}>
        <Form.Item name="publisher" label="Publisher" rules={[{ required: true, message: "Please enter the publisher" }]}>
          <Input />
        </Form.Item>
      </Col>
      {/* Frequency */}
      <Col xs={24} sm={12} md={8} lg={4}>
        <Form.Item name="frequency" label="Frequency" rules={[{ required: true, message: "Please select the frequency" }]}>
          <Select>
            <Select.Option value="weekly">Weekly</Select.Option>
            <Select.Option value="monthly">Monthly</Select.Option>
            <Select.Option value="quarterly">Quarterly</Select.Option>
            <Select.Option value="annually">Annually</Select.Option>
          </Select>
        </Form.Item>
      </Col>
      {/* Start Year & Month */}
      <Col xs={24} sm={12} md={8} lg={4}>
        <Form.Item name="startYearMonth" label="Start Year & Month" rules={[{ required: true, message: "Please select the start year and month" }]}>
          <DatePicker picker="month" />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={[16, 16]}>
      {/* End Year & Month (if applicable) */}
      <Col xs={24} sm={12} md={8} lg={4}>
        <Form.Item name="endYearMonth" label="End Year & Month (if applicable)">
          <DatePicker picker="month" />
        </Form.Item>
      </Col>
      {/* Volume Information */}
      <Col xs={24} sm={12} md={8} lg={4}>
        <Form.Item name="volume" label="Volume Information" rules={[{ required: true, message: "Please enter the volume information" }]}>
          <Input />
        </Form.Item>
      </Col>
      {/* Issue Number */}
      <Col xs={24} sm={12} md={8} lg={4}>
        <Form.Item name="issueNumber" label="Issue Number" rules={[{ required: true, message: "Please enter the issue number" }]}>
          <Input />
        </Form.Item>
      </Col>
      {/* Keywords */}
      <Col xs={24} sm={12} md={8} lg={4}>
        <Form.Item name="keywords" label="Keywords" rules={[{ required: true, message: "Please enter the keywords" }]}>
          <Input />
        </Form.Item>
      </Col>
      {/* Status */}
      <Col xs={24} sm={12} md={8} lg={4}>
        <Form.Item name="status" label="Status" rules={[{ required: true, message: "Please select the journal status" }]}>
          <Select>
            <Select.Option value="available">Available</Select.Option>
            <Select.Option value="checkedOut">Checked Out</Select.Option>
            <Select.Option value="reserved">Reserved</Select.Option>
          </Select>
        </Form.Item>
      </Col>
      {/* Description */}
      <Col xs={24} sm={12} md={8} lg={4}>
        <Form.Item name="description" label="Description" rules={[{ required: true, message: "Please enter the description" }]}>
          <Input.TextArea />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={[16, 16]}>
      {/* Collection Location (Only visible if journal type is offline) */}
      {journalType === "offline" && (
        <Col xs={24} sm={12} md={8} lg={4}>
          <Form.Item name="collectionLocation" label="Collection Location" rules={[{ required: true, message: "Please specify the collection location" }]}>
            <Input />
          </Form.Item>
        </Col>
      )}
      {/* Link to Digital Version (Only visible if journal type is online) */}
      {journalType === "online" && (
        <Col xs={24} sm={12} md={8} lg={4}>
          <Form.Item name="digitalLink" label="Link to Digital Version" rules={[{ required: true, message: "Please enter the link to the digital version" }]}>
            <Input />
          </Form.Item>
        </Col>
      )}
    </Row>
    <Row gutter={[16, 16]}>
      {/* Submit Button */}
      <Col xs={24} sm={12} md={8} lg={4}>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            Add Journal
          </Button>
        </Form.Item>
      </Col>
    </Row>
  </Form>
      </Card>

      {/* Journal Table */}
      <Card>
        <Row gutter={[16, 16]} justify="end">
          <Col xs={24}>
            <Button type="dashed" icon={<DownloadOutlined />} size="small" onClick={exportToExcel}>
              Download Excel
            </Button>
            {/* <Button type="primary" icon={<ReloadOutlined />} size="small" onClick={fetchJournals}>
              Refresh
            </Button> */}
          </Col>
          <Col xs={24}>
            <Card title="Available Journals" size="small" style={{ marginTop: "20px", boxShadow: "none", border: "1px solid #d9d9d9" }}  extra={[
              <Button
                key="refresh"
                type="primary"
                icon={<ReloadOutlined />}
                onClick={fetchJournals}
              >
                Refresh
              </Button>,
            ]}>
              <Table
                columns={columns}
                dataSource={data.map((item) => ({ ...item, key: item._id }))}
                pagination={{
                  current: currentPage,
                  pageSize: pageSize,
                  onChange: (page, pageSize) => {
                    setCurrentPage(page);
                    setPageSize(pageSize);
                  },
                }}
                scroll={{ x: 1500 }}
              />
            </Card>
          </Col>
        </Row>
      </Card>

      
    </>
  );
};

export default AddJournalForm;

