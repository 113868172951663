import React, { Component } from 'react'
import { Row, Col, Tooltip, Checkbox, Descriptions, Typography, message, Spin, Empty } from 'antd';
import { connect } from "react-redux";
import axios from 'axios';
import config from '../../config';
import ProObj from '../../ProObj';
import { withRouter } from "react-router-dom";
const page_name = "course_learning_objective";
const { Text, Title } = Typography;

class CourseLearningObjective extends Component {
    state = {
        COURSE_CODE: this.props.COURSE_CODE, COURSE_NAME: this.props.COURSE_NAME,
        CLO1: '', CLO2: '', CLO3: '', CLO4: '', CLO5: '', CLO6: '',
        OC1: [], OC2: [], OC3: [], OC4: [], OC5: [], OC6: [],
        loading: true, PBOJ: ProObj.PROGRAM_OUTCOMES,
        PLO: [], PSO: [], PEO: []
    }

    componentDidMount() {
        this.getCourseLO(this.state.COURSE_CODE)
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({ loading: true });
        this.setState({ COURSE_CODE: props.COURSE_CODE, COURSE_NAME: props.COURSE_NAME })
        this.getCourseLO(props.COURSE_CODE);
    }

    getCourseLO = (COURSE_CODE) => {
        if (COURSE_CODE) {
            let sendData = {
                data: {
                    COURSE_CODE: COURSE_CODE,
                    title: page_name
                },
                key: config.key
            };

            axios.post(config.curriculaserver + '/curricula/admin/course/getcourseinfo', sendData)
                .then(async res => {
                    if (res.data.Status === 1) {
                        let record = res.data.selectedCourseData;
                        if (record) {
                            Object.entries(record).map(([key, value]) => this.setState({ [key]: value }));
                            if (record.CLO1) this.setState({ open: true })
                            else this.setState({ open: false })
                        } else {
                            this.setState({
                                CLO1: '', CLO2: '', CLO3: '', CLO4: '', CLO5: '', CLO6: '',
                                OC1: [], OC2: [], OC3: [], OC4: [], OC5: [], OC6: [],
                            })
                        }
                        let DEPT = res.data.dept;

                        let data = {
                            info: this.props.common.user,
                            key: config.key,
                            dept: DEPT
                        };

                        await axios.post(config.curriculaserver + '/curricula/batchcoordinator/po/view', data)
                            .then(async res => {
                                let tempPBOJ = {}, PLO = [], PSO = [];
                                if (res.data.Status === 1) {
                                    PLO = res.data.result.PLO; PSO = res.data.result.PSO;
                                }
                                else {
                                    PLO = res.data.default.PLO; PSO = res.data.default.PSO;
                                }

                                tempPBOJ.PLO1 = PLO[0];
                                tempPBOJ.PLO2 = PLO[1];
                                tempPBOJ.PLO3 = PLO[2];
                                tempPBOJ.PLO4 = PLO[3];
                                tempPBOJ.PLO5 = PLO[4];
                                tempPBOJ.PLO6 = PLO[5];
                                tempPBOJ.PLO7 = PLO[6];
                                tempPBOJ.PLO8 = PLO[7];
                                tempPBOJ.PLO9 = PLO[8];
                                tempPBOJ.PLO10 = PLO[9];
                                tempPBOJ.PLO11 = PLO[10];
                                tempPBOJ.PLO12 = PLO[11];
                                tempPBOJ.PLO13 = PSO[0];
                                tempPBOJ.PLO14 = PSO[1];
                                tempPBOJ.PLO15 = PSO[2];

                                await this.setState({ PBOJ: tempPBOJ });
                            })
                    }
                    this.setState({ loading: false });
                });
        }
        else
            message.error("Course Not Selected");
    }

    FunctionCheckboxGroup = (id) => {
        let PBOJECTIVES = this.state.PBOJ;
        let PLO, html = [], PLONAME;
        for (let i = 1; i <= 15; i++) {
            PLO = "PLO" + i;
            i < 10 ? PLONAME = "PO 0" + i : PLONAME = "PO " + i;
            html.push(<Tooltip key={PLO + id} title={PBOJECTIVES[PLO]}><Checkbox className="ml-2 mt-1" value={PLO}>{PLONAME}</Checkbox></Tooltip>);
        }
        return html;
    }

    render() {
        let { COURSE_NAME, COURSE_CODE } = this.state;
        let msg = "Upon completion of the course, the learners will be able to";
        return (
            <Spin spinning={this.state.loading}>

                {
                    this.state.open ?
                        (<>

                            <Descriptions
                                bordered
                                column={{ sm: 1, md: 2, lg: 2, xs: 1 }}
                                size="small"
                                className="mt-1 padding-2">
                                <Descriptions.Item label="Course Code">
                                    {COURSE_CODE}
                                </Descriptions.Item>
                                <Descriptions.Item label="Course Name">
                                    {COURSE_NAME}
                                </Descriptions.Item>
                            </Descriptions>

                            <Title className="ml-2 mb-1 mt-2" level={5}>Course Outcomes (CO) mapping with Program Outcomes [PO (15) = PO (12) + PSO (3)] :</Title>

                            <Descriptions
                                size="large"
                                column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                                className="padding-2 student-so"
                                style={{ marginTop: "-20px" }}
                                bordered
                            >
                                <Descriptions.Item label="CO1">
                                    <Row gutter={[16, 16]} justify="space-around" align="middle" className="mt-1">
                                        <Col xs={24}>
                                            <Text className="justify">
                                                {msg + " " + this.state.CLO1}
                                            </Text>
                                        </Col>
                                        <Col xs={24}>
                                            <Row gutter={[16, 16]}>
                                                <Checkbox.Group style={{ width: '100%' }} value={this.state.OC1} disabled>
                                                    {this.FunctionCheckboxGroup(1)}
                                                </Checkbox.Group>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Descriptions.Item>

                                <Descriptions.Item label="CO2">
                                    <Row gutter={[16, 16]} justify="space-around" align="middle" className="mt-1">
                                        <Col xs={24}>
                                            <Text className="justify">
                                                {msg + " " + this.state.CLO2}
                                            </Text>
                                        </Col>
                                        <Col xs={24}>
                                            <Row gutter={[16, 16]}>
                                                <Checkbox.Group style={{ width: '100%' }} value={this.state.OC2} disabled>
                                                    {this.FunctionCheckboxGroup(2)}
                                                </Checkbox.Group>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Descriptions.Item>

                                <Descriptions.Item label="CO3">
                                    <Row gutter={[16, 16]} justify="space-around" align="middle" className="mt-1">
                                        <Col xs={24}>
                                            <Text className="justify">
                                                {msg + " " + this.state.CLO3}
                                            </Text>
                                        </Col>
                                        <Col xs={24}>
                                            <Row gutter={[16, 16]}>
                                                <Checkbox.Group style={{ width: '100%' }} value={this.state.OC3} disabled>
                                                    {this.FunctionCheckboxGroup(3)}
                                                </Checkbox.Group>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Descriptions.Item>

                                <Descriptions.Item label="CO4">
                                    <Row gutter={[16, 16]} justify="space-around" align="middle" className="mt-1">
                                        <Col xs={24}>
                                            <Text className="justify">
                                                {msg + " " + this.state.CLO4}
                                            </Text>
                                        </Col>
                                        <Col xs={24}>
                                            <Row gutter={[16, 16]}>
                                                <Checkbox.Group style={{ width: '100%' }} value={this.state.OC4} disabled>
                                                    {this.FunctionCheckboxGroup(4)}
                                                </Checkbox.Group>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Descriptions.Item>

                                <Descriptions.Item label="CO5">
                                    <Row gutter={[16, 16]} justify="space-around" align="middle" className="mt-1">
                                        <Col xs={24}>
                                            <Text className="justify">
                                                {msg + " " + this.state.CLO5}
                                            </Text>
                                        </Col>
                                        <Col xs={24}>
                                            <Row gutter={[16, 16]}>
                                                <Checkbox.Group style={{ width: '100%' }} value={this.state.OC5} disabled>
                                                    {this.FunctionCheckboxGroup(5)}
                                                </Checkbox.Group>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Descriptions.Item>

                                <Descriptions.Item label="CO6">
                                    <Row gutter={[16, 16]} justify="space-around" align="middle" className="mt-1">
                                        <Col xs={24}>
                                            <Text className="justify">
                                                {msg + " " + this.state.CLO6}
                                            </Text>
                                        </Col>
                                        <Col xs={24}>
                                            <Row gutter={[16, 16]}>
                                                <Checkbox.Group style={{ width: '100%' }} value={this.state.OC6} disabled>
                                                    {this.FunctionCheckboxGroup(6)}
                                                </Checkbox.Group>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Descriptions.Item>

                            </Descriptions>

                        </>
                        ) :
                        <Row gutter={[16, 16]} className="mt-1" justify="center">
                            <Empty />
                        </Row>
                }
            </Spin>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(CourseLearningObjective));