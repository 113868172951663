import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, message, Card } from "antd";
import axios from "axios";
import config from "../../../config";

const NextKeenDetails = ({ onStepComplete, studentID }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [localStudentID, setLocalStudentID] = useState("");


useEffect(() => {
  if (studentID) {
    form.setFieldsValue({ studentID });  //  Auto-fill Student ID in the form
    fetchStudentData();
  }
}, [studentID, form]);

const fetchStudentData = async () => {
  try {
    const { data } = await axios.get(
      `${config.curriculaserver}/curricula/admin/nextkeen/next-keen/get/${studentID}`
    );
    if (data) {
      form.setFieldsValue(data); // Pre-fill form with existing data
    }
  } catch (error) {
    console.error("Error fetching student data:", error);
  }
};


const handleSaveAndContinue = async () => {
  try {
    await form.validateFields();
    const values = form.getFieldsValue();

    if (!values.studentID) {
      message.error("Student ID is required. Please enter personal info first!");
      return;
    }

    setLoading(true);

    const response = await axios.post(
      `${config.curriculaserver}/curricula/admin/nextkeen/next-keen/add`,
      values
    );

    if (response.data.success) {
      message.success(response.data.message);
      onStepComplete();
    } else {
      message.error(response.data.message || "Failed to save details.");
    }
  } catch (error) {
    console.error("API Error:", error.response?.data || error.message);
    message.error("⚠️ Please fill all required fields!");
  } finally {
    setLoading(false);
  }
};



  

  return (
    <div className="site-layout-content" style={{ padding: "20px" }}>
       <Card>
        <Form form={form} layout="vertical">
          {/* Display Student ID from `PersonalInfo.js` */}
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8}>
              <Form.Item label="Student ID" name="studentID">
                <Input value={studentID} disabled /> {/* Auto-filled Student ID */}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={8}>
              <Form.Item label="Full Names" name="fullNames" rules={[{ required: true, message: "Please input full names!" }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item label="Email" name="email" rules={[{ required: true, type: "email", message: "Please input a valid email!" }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item label="Phone Number" name="phone" rules={[{ required: true, message: "Please input the phone number!" }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={8}>
              <Form.Item label="Physical Address" name="physicalAddress" rules={[{ required: true, message: "Please input the physical address!" }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button type="primary" onClick={handleSaveAndContinue} loading={loading}>
              Continue
            </Button>
          </Form.Item>
        </Form>
      </Card>
     
      
    </div>
    
  );
};

export default NextKeenDetails;
