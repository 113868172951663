import React, { useEffect, useState, useRef } from "react";
import { Table, message, Card,Row,Col, Tooltip, Input, Button, Space, Switch } from "antd";
import { DownloadOutlined, SearchOutlined, ReloadOutlined } from "@ant-design/icons";
import axios from "axios";
import config from "../../../config";
import Highlighter from "react-highlight-words";
import * as XLSX from 'xlsx';

const FeesDetailsTable = () => {
  const [loading, setLoading] = useState(false);
  const [students, setStudents] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [fixedTop, setFixedTop] = useState(false);
  const searchInput = useRef(null);

  useEffect(() => {
    fetchStudentData();
  }, []);

  const fetchStudentData = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(`${config.curriculaserver}/curricula/admin/denomination/students`);
      if (data.success) {
        setStudents(data.data.map((student, index) => ({ ...student, serialNo: index + 1 })));
      } else {
        message.error("Failed to load student fees details.");
      }
    } catch (error) {
      console.error("Error fetching fees details:", error);
      message.error("Error fetching student fees details.");
    }
    setLoading(false);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] || ""}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const renderDownloadIcon = (filePath, label) => {
    if (!filePath) return "N/A";

    return (
      <Tooltip title={`Download ${label}`}>
        <a href={`${config.curriculaserver}/${filePath}`} download target="_blank" rel="noopener noreferrer">
          <DownloadOutlined style={{ fontSize: "18px", color: "#1890ff" }} />
        </a>
      </Tooltip>
    );
  };

  const columns = [
    { title: "S.No", dataIndex: "serialNo",width: 50, key: "serialNo", fixed: "left", render: (text) => <strong>{text}</strong> },
    { title: "Student ID", dataIndex: "studentID", key: "studentID", fixed: "left", ...getColumnSearchProps("studentID") },
    { title: "Payment Method", dataIndex: ["feesDetails", "paymentMethod"], key: "paymentMethod", ...getColumnSearchProps("feesDetails.paymentMethod") },
    { title: "Tuition Fees Paid", dataIndex: ["feesDetails", "tuitionFeesPaid"], key: "tuitionFeesPaid", ...getColumnSearchProps("feesDetails.tuitionFeesPaid") },
    { title: "Accommodation Fees Paid", dataIndex: ["feesDetails", "accommodationFeesPaid"], key: "accommodationFeesPaid", ...getColumnSearchProps("feesDetails.accommodationFeesPaid") },
    { title: "Meals Fees Paid", dataIndex: ["feesDetails", "mealsFeesPaid"], key: "mealsFeesPaid", ...getColumnSearchProps("feesDetails.mealsFeesPaid") },
    { title: "Graduation Fees Paid", dataIndex: ["feesDetails", "graduationFeesPaid"], key: "graduationFeesPaid", ...getColumnSearchProps("feesDetails.graduationFeesPaid") },
    { title: "Registration Fees Paid", dataIndex: ["feesDetails", "registrationFeesPaid"], key: "registrationFeesPaid", ...getColumnSearchProps("feesDetails.registrationFeesPaid") },
    { title: "Sponsor Name", dataIndex: ["feesDetails", "sponsorName"], key: "sponsorName", ...getColumnSearchProps("feesDetails.sponsorName") },
    { title: "Sponsor Contact", dataIndex: ["feesDetails", "sponsorContact"], key: "sponsorContact" , ...getColumnSearchProps("feesDetails.sponsorContact")},
    { title: "Tuition Proof", dataIndex: ["feesDetails", "tuitionProof"], key: "tuitionProof", render: renderDownloadIcon },
    { title: "Accommodation Proof", dataIndex: ["feesDetails", "accommodationProof"], key: "accommodationProof", render: renderDownloadIcon },
    { title: "Meals Proof", dataIndex: ["feesDetails", "mealsProof"], key: "mealsProof", render: renderDownloadIcon },
    { title: "Graduation Proof", dataIndex: ["feesDetails", "graduationProof"], key: "graduationProof", render: renderDownloadIcon },
    { title: "Registration Proof", dataIndex: ["feesDetails", "registrationProof"], key: "registrationProof", render: renderDownloadIcon },
  ];
  const exportToExcel = () => {
    const formattedData = students.map(({ serialNo, studentID, feesDetails }) => ({
      "S.No": serialNo,
      "Student ID": studentID,
      "Payment Method": feesDetails?.paymentMethod,
      "Tuition Fees Paid": feesDetails?.tuitionFeesPaid,
      "Accommodation Fees Paid": feesDetails?.accommodationFeesPaid,
      "Meals Fees Paid": feesDetails?.mealsFeesPaid,
      "Graduation Fees Paid": feesDetails?.graduationFeesPaid,
      "Registration Fees Paid": feesDetails?.registrationFeesPaid,
      "Sponsor Name": feesDetails?.sponsorName,
      "Sponsor Contact": feesDetails?.sponsorContact
    }));
    
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Fees Details');
    XLSX.writeFile(wb, 'Fees_Details.xlsx');
  };

  return (<>
    <Row gutter={[16, 16]} justify="end">
        <Col xs={24}>
          <Button type="dashed" icon={<DownloadOutlined />} size="small" onClick={exportToExcel}>
            Download Excel
          </Button>
          <Button type="primary" icon={<ReloadOutlined />} size="small" onClick={fetchStudentData}>
            Refresh
          </Button>
        </Col>
        </Row>
    <Card title="7. Fees Details">
      <Table columns={columns} dataSource={students} loading={loading} rowKey="studentID" scroll={{ x: 1500 }} />
    </Card>
    </>
  );
};

export default FeesDetailsTable;
