import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Row,
  Col,
  message,
  Card,
  Modal,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import config from "../../../config";

const Denomination = ({ onStepComplete, studentID }) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [denominationData, setDenominationData] = useState(null);

  // ✅ Fetch saved denomination details
  useEffect(() => {
    if (studentID) {
      fetchDenominationData();
      form.setFieldsValue({ studentID });
    }
  }, [studentID]);

  const fetchDenominationData = async () => {
    try {
      const { data } = await axios.get(`${config.curriculaserver}/curricula/admin/denomination/get/${studentID}`);

      if (data && data.denominationDetails) {
        form.setFieldsValue({
          churchName: data.denominationDetails.churchName || "",
          denomination: data.denominationDetails.denomination || "",
          churchLocation: data.denominationDetails.churchLocation || "",
          churchInvolvement: data.denominationDetails.churchInvolvement || "",
        });

        // Handle existing file uploads
        setFileList(
          data.denominationDetails.personalTestimony
            ? [{ url: `${config.fileServer}/${data.denominationDetails.personalTestimony}`, name: "Personal Testimony" }]
            : []
        );
      }
    } catch (error) {
      console.error("Error fetching denomination data:", error);
      message.error("Failed to load denomination details.");
    }
  };

  //  Handle File Selection
  const handleFileChange = ({ fileList }) => {
    setFileList(fileList.slice(-1)); // Keep only the latest file
  };

  //  Save & Continue (Updates DB without submission)
  const handleSaveAndContinue = async () => {
    try {
      await form.validateFields();

      if (!studentID) {
        message.error("❌ Student ID is missing. Please enter personal info first!");
        return;
      }

      // Fetch existing status inside denominationDetails
      const { data } = await axios.get(`${config.curriculaserver}/curricula/admin/denomination/get/${studentID}`);
      const existingStatus = data?.denominationDetails?.status;

      if (existingStatus === 2) {
        message.error("Your form has already been submitted.");
        return;
      }

      const values = form.getFieldsValue();
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      // ✅ Attach the File to FormData
      if (fileList.length > 0 && fileList[0].originFileObj) {
        formData.append("personalTestimony", fileList[0].originFileObj);
      }

      formData.append("studentID", studentID);
      formData.append("status", existingStatus === 1 ? 1 : 1); // Keep status as 1

      setLoading(true);
      const response = await axios.post(`${config.curriculaserver}/curricula/admin/denomination/add`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.data.success) {
        message.success("🎉 Denomination details saved successfully!");
        onStepComplete();
      } else {
        message.error(response.data.message || "❌ Failed to save data.");
      }
    } catch (error) {
      console.error("❌ Submission Error:", error);
      message.error("⚠ Please fill all required fields!");
    } finally {
      setLoading(false);
    }
  };
  
  

  //  Open Confirmation Modal Before Final Submission
  const handleConfirmSubmit = () => {
    setIsModalVisible(true);
  };

  //  Final Submission (Updates status to `1`)
  const handleSubmit = async () => {
    setIsModalVisible(false);
    try {
      const values = form.getFieldsValue();
      // console.log("📝 Form Values Before Submission:", values); // Debugging

      await form.validateFields();

      const response = await axios.post(
        `${config.curriculaserver}/curricula/admin/denomination/submit`,
        { studentID }
      );

      if (response.data.success) {
        message.success("🎉 Form submitted successfully!");
        onStepComplete();
      } else {
        message.error(response.data.message || "❌ Submission failed.");
      }
    } catch (error) {
      console.error("⚠ Submission Validation Error:", error);
      message.error("⚠ Submission failed. Please try again.");
    }
  };

  return (
    <div className="site-layout-content" style={{ padding: "20px" }}>
      <Card>
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item label="Student ID" name="studentID">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>

          {/*  Church Details */}
          <Row gutter={16}>
            <Col xs={24} sm={8}>
              <Form.Item
                label="Name of Church"
                name="churchName"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                label="Name of Denomination"
                name="denomination"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8}>
              <Form.Item
                label="Location of Church"
                name="churchLocation"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          {/*  Upload Testimony */}
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Upload Personal Testimony"
                name="personalTestimony"
                rules={[{ required: true }]}
              >
                <Upload
                  beforeUpload={() => false}
                 
                  maxCount={1}
                  fileList={fileList}
                  onChange={handleFileChange}
                >
                  <Button icon={<UploadOutlined />}>Upload Testimony</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          {/* Save & Continue and Final Submit Buttons */}
          <Form.Item>
            <Button
              type="primary"
              onClick={handleSaveAndContinue}
              loading={loading}
            >
              Continue
            </Button>
            {/* <Button
              type="primary"
              onClick={handleConfirmSubmit}
              loading={loading}
              style={{ marginLeft: "10px" }}
            >
              Final Submit
            </Button> */}
          </Form.Item>
        </Form>
      </Card>

      {/*  Confirmation Modal */}
      <Modal
        title="Confirm Submission"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleSubmit}
        okText="Yes, Submit"
        cancelText="Cancel"
      >
        <p>
          Are you sure you want to submit the form? Once submitted, changes
          cannot be made.
        </p>
      </Modal>
    </div>
  );
};

export default Denomination;
