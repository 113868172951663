import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  Input,
  Form,
  Row,
  Col,
  Modal,
  message,
  Spin,
  Checkbox,
} from "antd";
import { SearchOutlined, CheckCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import config from "../../../config";
import moment from "moment";

const BookIssue = () => {
  const [memberData, setMemberData] = useState(null);
  const [bookData, setBookData] = useState(null);
  const [isMemberValid, setIsMemberValid] = useState(false);
  const [isBookAvailable, setIsBookAvailable] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [memberIdInput, setMemberIdInput] = useState("");
  const [isbnInput, setIsbnInput] = useState("");
  const [bookIdInput, setBookIdInput] = useState("");
  const [isbn, setIsbn] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [issuedBooksCount, setIssuedBooksCount] = useState(0);

  // Member search handler
  const handleMemberSearch = async () => {
    if (!memberIdInput) {
      message.error("Please enter a Member ID.");
      return;
    }

    try {
      // Fetch the member data
      const response = await axios.get(
        `${config.curriculaserver}/curricula/admin/library/members/${memberIdInput}`
      );

      if (response.data) {
        // console.log("Member data:", response.data); // Debug member data

        setMemberData(response.data);
        setIsMemberValid(true);

        // Fetch the count of books borrowed by the member
        const transactionResponse = await axios.get(
          `${config.curriculaserver}/curricula/admin/library/transactions/count/${memberIdInput}`
        );

        // Check if the 'issuedBooks' property exists in the response
        // console.log("Transaction response:", transactionResponse.data); // Debug response

        const issuedBooks = transactionResponse.data.issuedBooks;

        if (issuedBooks !== undefined) {
          setIssuedBooksCount(issuedBooks); // Update the issued books count
        } else {
          console.error("Issued books count is undefined.");
        }

        // Check if the member is eligible for more books
        if (issuedBooks >= response.data.maxEligibleBooks) {
          message.error("This member has reached the maximum book limit (5).");
        }
      } else {
        message.error("Member not found.");
        setMemberData(null);
        setIsMemberValid(false);
      }
    } catch (error) {
      console.error("Enter Valid Member:", error);
      message.error("Enter Valid Member.");
      setIsMemberValid(false);
    }
  };

  // Book search handler
  const handleBookSearch = async () => {
    // Validate ISBN input (should be 13 digits)
    if (!isbnInput || isbnInput.length !== 13 || !/^\d{13}$/.test(isbnInput)) {
      message.error("Please enter a valid ISBN (13 digits).");
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.get(
        `${config.curriculaserver}/curricula/admin/library/books/isbn/${isbnInput}`
      );

      if (response.data) {
        setBookData(response.data);
        setIsBookAvailable(parseInt(response.data.copyCount) > 0);
        setBookIdInput(response.data.bookId); // Automatically set bookIdInput
        message.success("Book found!");
      } else {
        setBookData(null);
        setIsBookAvailable(false);
        setBookIdInput(""); // Clear the bookId input
        message.error("Book not found.");
      }
    } catch (error) {
      console.error("Enter Valid ISBN:", error);
      message.error("Enter Valid ISBN.");
      setIsBookAvailable(false);
      setBookIdInput("");
    } finally {
      setIsLoading(false); // Stop loading spinner
    }
  };

  const handleIssueBook = async () => {
    // Ensure memberData and bookData are provided
    if (!memberData || !bookData) {
      message.error("Please search and select both member and book.");
      return;
    }
  
    // Ensure that the user has confirmed the details
    if (!isChecked) {
      message.error("Please confirm the details before issuing the book.");
      return;
    }
  
    // Ensure that the user has entered a valid Book ID
    if (!bookIdInput) {
      message.error("Please enter a valid Book ID.");
      return;
    }
  
    setIsSubmitting(true);
  
    try {
      // Step 1: Check if the bookId already exists in active transactions
      const transactionCheckResponse = await axios.get(
        `${config.curriculaserver}/curricula/admin/library/transactions/check/${bookIdInput}`
      );
  
      // If the response indicates the book is already issued, prevent further action
      if (transactionCheckResponse.data && transactionCheckResponse.data.isBookIssued) {
        message.error("This book has already been issued. Cannot issue again.");
        setIsSubmitting(false);
        return;
      }
  
      // Step 2: Proceed with book issue if no duplicate found
      const issueDate = moment().toDate();  // Use the current date as a Date object
      const dueDate = moment().add(14, "days").toDate();  // 14 days from current date
  
      // Create the transaction data object to send to the backend
      const transactionData = {
        memberId: memberData.memberId || "",
        memberName: `${memberData.firstName} ${memberData.lastName}` || "",
        department: memberData.department || "Not Provided",
        email: memberData.email || "",
        phone: memberData.phoneNumber || "",
        bookISBN: isbnInput || "",
        bookId: bookIdInput || "",
        bookTitle: bookData.title || "",
        authors: bookData.authors || "",
        issueDate, // Directly use the Date object
        dueDate, // Directly use the Date object
        status: "Borrowed", // Default status as Borrowed
      };
  
      // Log the transaction data to see what is being sent to the backend
      console.log("Transaction Data to be sent:", transactionData);
  
      // Make the POST request to the backend to issue the book
      const response = await axios.post(
        `${config.curriculaserver}/curricula/admin/library/transactions`,
        transactionData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.data) {
        message.success("Book issued successfully!");
        setIsModalVisible(false); // Close modal after success
  
        // After issuing the book, refresh the member's borrowed book count
        await refreshIssuedBooksCount(); // Call the refresh function to update the count
      } else {
        message.error("Failed to issue the book. Please try again.");
      }
    } catch (error) {
      console.error("Error issuing book:", error);
      message.error("Error issuing book.");
    } finally {
      setIsSubmitting(false);
    }
  };
  
  // Function to refresh the issued books count
  const refreshIssuedBooksCount = async () => {
    try {
      // Re-fetch the count of books borrowed by the member
      const transactionResponse = await axios.get(
        `${config.curriculaserver}/curricula/admin/library/transactions/count/${memberIdInput}`
      );
  
      // Debug the response
      console.log('Refreshed Transaction response:', transactionResponse.data);
  
      const issuedBooks = transactionResponse.data.issuedBooks;
      setIssuedBooksCount(issuedBooks); // Update the issued books count in state
  
    } catch (error) {
      console.error('Error refreshing issued books count:', error);
    }
  };
  

  return (
    <div>
      <Card
        title="Book Issue Process"
        size="small"
        style={{ marginBottom: "20px", boxShadow: "none" }}
      >
        <Form layout="vertical">
          {/* Member Search */}
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Member ID" required>
                <Input
                  placeholder="Enter Member ID"
                  value={memberIdInput}
                  onChange={(e) => setMemberIdInput(e.target.value)}
                  onPressEnter={handleMemberSearch}
                />
                <Button
                  type="primary"
                  icon={<SearchOutlined />}
                  onClick={handleMemberSearch}
                  style={{ marginTop: "10px" }}
                >
                  Search Member
                </Button>
                {isMemberValid && (
                  <span style={{ color: "green", marginLeft: "10px" }}>
                    <CheckCircleOutlined />
                  </span>
                )}
              </Form.Item>
            </Col>

            {memberData && (
              <Col span={16}>
              <Card
                title="Member Information"
                bordered={false}
                style={{ width: "100%" }}
              >
                <p>
                  <strong>Name:</strong> {memberData.firstName} {memberData.lastName}
                </p>
                <p>
                  <strong>Member ID:</strong> {memberData.memberId} 
                </p>
                <p>
                  <strong>Email:</strong> {memberData.email}
                </p>
                <p>
                  <strong>Phone:</strong> {memberData.phoneNumber}
                </p>
                <p>
                  <strong>Books Borrowed:</strong> {issuedBooksCount}/
                  {memberData.maxEligibleBooks || 5}
                </p>
              </Card>
            </Col>
            
            )}
          </Row>

          {/* Book Search */}
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="ISBN" required>
                <Input
                  placeholder="Enter ISBN (13 digits)"
                  value={isbnInput}
                  onChange={(e) => setIsbnInput(e.target.value)}
                  onPressEnter={handleBookSearch}
                />
                <Button
                  type="primary"
                  icon={<SearchOutlined />}
                  onClick={handleBookSearch}
                  style={{ marginTop: "10px" }}
                  disabled={!isbnInput}
                >
                  Search Book by ISBN
                </Button>
                {isBookAvailable && (
                  <span style={{ color: "green", marginLeft: "10px" }}>
                    <CheckCircleOutlined />
                  </span>
                )}
              </Form.Item>
            </Col>

            {bookData && (
              <Col span={16}>
              <Card
                title="Book Information"
                bordered={false}
                style={{ width: "100%" }}
              >
                <p>
                  <strong>Title:</strong> {bookData.title}
                </p>
                <p>
                  <strong>Author:</strong> {bookData.authors}
                </p>
                <p>
                  <strong>Availability:</strong> {bookData.copyCount}
                </p>
                <p>
                  <strong>ISBN:</strong> {bookData.isbn}
                </p>
              </Card>
            </Col>
            
            )}

            {isLoading && (
              <Col span={16}>
                <div style={{ textAlign: "center" }}>
                  <Spin size="large" />
                  <p>Searching...</p>
                </div>
              </Col>
            )}
          </Row>

          {/* Book ID Input */}
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Book ID" required>
                <Input
                  placeholder="Enter Book ID"
                  value={bookIdInput}
                  onChange={(e) => setBookIdInput(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Issue Book Button */}
          <Row gutter={16}>
            <Col span={8}>
              <Button
                type="primary"
                onClick={() => setIsModalVisible(true)}
                disabled={
                  !isMemberValid ||
                  !isBookAvailable ||
                  !bookIdInput ||
                  issuedBooksCount >= (memberData?.maxEligibleBooks || 5)
                }
              >
                Issue Book
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>

      {/* Modal for confirming Book Issue */}
      <Modal
        title="Confirm Book Issue"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="back" onClick={() => setIsModalVisible(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isSubmitting}
            onClick={handleIssueBook}
          >
            Issue Book
          </Button>,
        ]}
      >
        <p>
          <strong>Member ID:</strong> {memberData?.memberId || "Not available"}
        </p>
        <p>
          <strong>Member Name:</strong>{" "}
          {`${memberData?.firstName || "N/A"} ${memberData?.lastName || "N/A"}`}
        </p>
        <p>
          <strong>Department:</strong> {memberData?.department || "N/A"}
        </p>
        <p>
          <strong>Email:</strong> {memberData?.email || "Not available"}
        </p>
        <p>
          <strong>Phone:</strong> {memberData?.phoneNumber || "Not available"}
        </p>

        <p>
          <strong>Book ISBN:</strong> {bookData?.isbn || "Not available"}
        </p>
        <p>
          <strong>Book ID:</strong> {bookIdInput || "Not available"}
        </p>
        <p>
          <strong>Book Title:</strong> {bookData?.title || "Not available"}
        </p>
        <p>
          <strong>Author(s):</strong> {bookData?.authors || "Not available"}
        </p>

        <p>
          <strong>Issue Date:</strong> {moment().format("YYYY-MM-DD")}
        </p>
        <p>
          <strong>Due Date:</strong>{" "}
          {moment().add(14, "days").format("YYYY-MM-DD")}
        </p>
        <Checkbox onChange={(e) => setIsChecked(e.target.checked)}>
          Confirm the details and issue the book to the member.
        </Checkbox>
      </Modal>
    </div>
  );
};

export default BookIssue;
