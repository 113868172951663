import React, { useEffect, useState, useRef } from "react";
import { Table, message, Card, Row, Col, Tooltip, Input, Button, Space, Switch } from "antd";
import { DownloadOutlined, SearchOutlined, ReloadOutlined } from "@ant-design/icons";
import axios from "axios";
import config from "../../../config";
import Highlighter from "react-highlight-words";
import * as XLSX from 'xlsx';

const NextOfKinDetailsTable = () => {
  const [loading, setLoading] = useState(false);
  const [students, setStudents] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  useEffect(() => {
    fetchStudentData();
  }, []);

  const fetchStudentData = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(`${config.curriculaserver}/curricula/admin/denomination/students`);
      if (data.success) {
        setStudents(data.data.map((student, index) => ({ ...student, serialNo: index + 1 })));
      } else {
        message.error("Failed to load student next of kin details.");
      }
    } catch (error) {
      console.error("Error fetching next of kin details:", error);
      message.error("Error fetching student next of kin details.");
    }
    setLoading(false);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] || ""}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    { title: "S.No", dataIndex: "serialNo", key: "serialNo",width: 50, fixed: "left", render: (text) => <strong>{text}</strong> },
    { title: "Student ID", dataIndex: "studentID", key: "studentID", fixed: "left", ...getColumnSearchProps("studentID") },
    { title: "Full Name", dataIndex: ["nextKeenDetails", "fullNames"], key: "fullNames" },
    { title: "Email", dataIndex: ["nextKeenDetails", "email"], key: "email" },
    { title: "Phone", dataIndex: ["nextKeenDetails", "phone"], key: "phone" },
    { title: "Physical Address", dataIndex: ["nextKeenDetails", "physicalAddress"], key: "physicalAddress" },
  ];

  const exportToExcel = () => {
    const formattedData = students.map(({ serialNo, studentID, nextKeenDetails }) => ({
      "S.No": serialNo,
      "Student ID": studentID,
      "Next of Kin Name": nextKeenDetails?.fullNames,
      "Next of Kin Email": nextKeenDetails?.email,
      "Next of Kin Phone": nextKeenDetails?.phone,
      "Physical Address": nextKeenDetails?.physicalAddress,
    }));
    
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Next of Kin Details');
    XLSX.writeFile(wb, 'Next_Of_Kin_Details.xlsx');
  };

  return (
    <>
    <Row gutter={[16, 16]} justify="end">
      <Col xs={24}>
        <Button type="dashed" icon={<DownloadOutlined />} size="small" onClick={exportToExcel}>
          Download Excel
        </Button>
        <Button type="primary" icon={<ReloadOutlined />} size="small" onClick={fetchStudentData}>
          Refresh
        </Button>
      </Col>
    </Row>
    <Card title="3. Next Keen Details">
      <Table columns={columns} dataSource={students} loading={loading} rowKey="studentID" scroll={{ x: 1500 }} />
    </Card>
  </>
  );
};

export default NextOfKinDetailsTable;
