import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, Upload, Row, Col, message, Card, DatePicker } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import config from "../../../config";
import moment from "moment"; 

const { Option } = Select;

const Enrollment = ({ onStepComplete, studentID }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);

  const handleFileChange = ({ fileList }) => {
    setFileList(fileList.slice(-1)); //  Keep only the latest file
  };

  useEffect(() => {
    if (studentID) {
      form.setFieldsValue({ studentID });  //  Auto-fill Student ID in the form
      fetchEnrollmentData();
    }
    
  }, [studentID, form]);


  const fetchEnrollmentData = async () => {
    try {
      const { data } = await axios.get(
        `${config.curriculaserver}/curricula/admin/nextkeen/enrollment/get/${studentID}`
      );

      if (data && data.enrollmentDetails) {
        //  Pre-fill form with existing enrollment details
        form.setFieldsValue({
          ...data.enrollmentDetails,
          graduationDate: data.enrollmentDetails.graduationDate
            ? moment(data.enrollmentDetails.graduationDate, "DD-MM-YYYY")
            : null,
          enrollmentDate: data.enrollmentDetails.enrollmentDate
            ? moment(data.enrollmentDetails.enrollmentDate, "DD-MM-YYYY")
            : null,
        });

        //  Handle file if already uploaded
        if (data.enrollmentDetails.highSchoolCertificates) {
          setFileList([
            {
              url: `${config.fileServer}/${data.enrollmentDetails.highSchoolCertificates}`,
              name: "High School Certificate",
            },
          ]);
        }
      }
    } catch (error) {
      console.error("Error fetching enrollment data:", error);
      message.error("Failed to load enrollment details.");
    }
  };

  const handleSaveAndContinue = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
  
      if (!values.studentID) {
        message.error("Student ID is missing. Please enter personal info first!");
        return;
      }
  
      // Fetch existing student data to check status inside enrollmentDetails
      const { data } = await axios.get(
        `${config.curriculaserver}/curricula/admin/nextkeen/enrollment/get/${values.studentID}`
      );
  
      const existingStatus = data?.enrollmentDetails?.status;
  
      if (existingStatus === 2) {
        message.error("Your form has already been submitted.");
        return;
      }
  
      // Format dates before sending to backend
      values.graduationDate = values.graduationDate ? moment(values.graduationDate).format("DD-MM-YYYY") : "";
      values.enrollmentDate = values.enrollmentDate ? moment(values.enrollmentDate).format("DD-MM-YYYY") : "";
  
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });
  
      // Append uploaded file
      if (fileList.length > 0 && fileList[0].originFileObj) {
        formData.append("highSchoolCertificates", fileList[0].originFileObj);
      }
  
      formData.append("studentID", values.studentID);
      
      // Ensure status inside enrollmentDetails remains 1 or is set if missing
      formData.append("status", existingStatus === 1 ? 1 : 1);
  
      setLoading(true);
      const response = await axios.post(
        `${config.curriculaserver}/curricula/admin/nextkeen/enrollment/add`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
  
      if (response.data.success) {
        message.success("Enrollment details saved successfully!");
        onStepComplete(); // Move to the next step
      } else {
        message.error(response.data.message || "Failed to save data.");
      }
    } catch (error) {
      message.error("⚠️ Please fill all required fields!");
    } finally {
      setLoading(false);
    }
  };
  



  return (
    <div className="site-layout-content" style={{ padding: "20px" }}>
      <Card>
        <Form form={form} layout="vertical">
          {/*  Display Student ID from `PersonalInfo.js` */}
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8}>
              <Form.Item label="Student ID" name="studentID">
                <Input value={studentID} disabled /> {/*  Auto-filled Student ID */}
              </Form.Item>
            </Col>
          </Row>

          <h3>Admission Requirements</h3>
          <br />
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8}>
              <Form.Item label="High School/College Certificates" name="highSchoolCertificates" rules={[{ required: true, message: "Please upload your certificates!" }]}>
              <Upload
                  beforeUpload={() => false} // Prevents auto-upload
                  
                  maxCount={1}
                  fileList={fileList}
                  onChange={handleFileChange}
                >
                  <Button icon={<UploadOutlined />}>Upload Certificate</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item label="High School Certificate Number" name="highSchoolCertificateNumber" rules={[{ required: true, message: "Please input your certificate number!" }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
            <Form.Item
                label="Graduation Date"
                name="graduationDate"
                rules={[{ required: true, message: "Please select your graduation date!" }]}
              >
                <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={24} sm={12} md={8}>
              <Form.Item label="Department Enrolled to" name="department" rules={[{ required: true, message: "Please input your department!" }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item label="Platform" name="platform" rules={[{ required: true, message: "Please select your platform!" }]}>
                <Select>
                  <Option value="online">Online</Option>
                  <Option value="onsite">Onsite</Option>
                  <Option value="non-accredited">Non-accredited</Option>
                  <Option value="short-courses">Short Courses</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
            <Form.Item
                label="Enrollment Date"
                name="enrollmentDate"
                rules={[{ required: true, message: "Please select your enrollment date!" }]}
              >
                <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button type="primary" onClick={handleSaveAndContinue} loading={loading}>
              Continue
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Enrollment;
