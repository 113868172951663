import React, { useState, useEffect } from "react";
import { Card, Row, Col, Button, Checkbox, message, Modal,Tooltip } from "antd";
import { EyeOutlined, ReloadOutlined } from "@ant-design/icons";
import axios from "axios";
import config from "../../../config";
import { useHistory, useParams } from 'react-router-dom';

const ReviewAndSubmit = ({ studentID, setCurrentStep, }) => {
  const [studentData, setStudentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [verifiedDocs, setVerifiedDocs] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [data, setData] = useState([]);
  const [showForm, setShowForm] = useState(true);
 
  

  useEffect(() => {
    if (studentID) {
      fetchStudentData();
    }
  }, [studentID]);

  const fetchStudentData = async () => {
    try {
      const response = await axios.get(
        `${config.curriculaserver}/curricula/admin/denomination/student/${studentID}`
      );
      if (response.data.success) {
        const student = response.data.data;

        // Merge personalInfo into root level for easier access
        const formattedData = {
          ...student,
          ...(student.personalInfo || {}) // Ensures personalInfo fields are accessible
        };

        console.log("Fetched Student Data:", formattedData); // Debugging
        setStudentData(formattedData);
      } else {
        message.error("No student data found.");
      }
    } catch (error) {
      message.error("Error fetching student data.");
    }
  };

  // const handleFinalSubmit = async () => {
  //   setLoading(true);
  //   try {
  //     // ✅ Send final submission request
  //     const response = await axios.post(
  //       `${config.curriculaserver}/curricula/admin/denomination/finalstudent/submit`,
  //       { studentID }
  //     );

  //     if (response.data.success) {
  //       setIsSubmitted(true); // ✅ Disable submit button

  //       // ✅ Send request to update "status: 2" in all sections
  //       await axios.put(`${config.curriculaserver}/admin/student/update-status`, {
  //         studentID,
  //       });

  //       // ✅ Clear local storage and reset everything
  //       localStorage.removeItem("personalInfoData");

  //       // ✅ Show success message and reload page
  //       Modal.success({
  //         title: "Submission Successful",
  //         content: "Your application has been successfully submitted!",
  //         onOk: () => {
  //           window.location.reload(); // ✅ Reloads the page to reset form state
  //         },
  //       });
  //     } else {
  //       message.error("❌ Submission failed. Please try again.");
  //     }
  //   } catch (error) {
  //     console.error("❌ Submission Error:", error);
  //     message.error("⚠️ Submission error. Please try again.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  
  // const handleFinalSubmit = async () => {
  //   setLoading(true);
  //   try {
  //     // ✅ Send final submission request
  //     const response = await axios.post(
  //       `${config.curriculaserver}/curricula/admin/denomination/finalstudent/submit`,
  //       { studentID }
  //     );
  
  //     console.log("Submission Response:", response.data); // Debugging
  
  //     if (response.data.success) {
  //       setIsSubmitted(true); // ✅ Disable submit button
  
  //       // ✅ Send request to update "status: 2" in all sections
  //       const updateStatusResponse = await axios.put(
  //         `${config.curriculaserver}/admin/denomination/update-status`,
  //         { studentID }
  //       );
  
  //       console.log("Update Status Response:", updateStatusResponse.data); // Debugging
  
  //       if (updateStatusResponse.data.success) {
  //         // ✅ Clear local storage and reset everything
  //         localStorage.removeItem("personalInfoData");
  
  //         // ✅ Show success message and reload page
  //         Modal.success({
  //           title: "Submission Successful",
  //           content: "Your application has been successfully submitted!",
  //           onOk: () => {
  //             setTimeout(() => {
  //               window.location.reload(); // ✅ Delayed reload for smooth transition
  //             }, 1000);
  //           },
  //         });
  //         setTimeout(() => {
  //           setLoading(false);
  //           setIsDisabled(true); // Disable the button after 3 seconds
  //         }, 3000);

  //       } else {
  //         message.error("⚠️ Failed to update student status. Please try again.");
  //       }
  //     } else {
  //       message.error("❌ Submission failed. Please try again.");
  //     }
  //   } catch (error) {
  //     console.error("❌ Submission Error:", error);
  
  //     // More detailed error message handling
  //     message.error(error.response?.data?.message || "⚠️ Submission error. Please try again.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const handleFinalSubmit = async () => { 
    setLoading(true);
    try {
        console.log("Submitting for Student ID:", studentID); // Debugging
        
        // ✅ Send final submission request
        const response = await axios.post(
            `${config.curriculaserver}/curricula/admin/denomination/finalstudent/submit`,
            { studentID }
        );

        console.log("Submission Response:", response.data); // Debugging

        if (response.data.success) {
            // ✅ Show success message
            Modal.success({
                title: "Submission Successful",
                content: "Your application has been successfully submitted!",
            });

            // ✅ Disable the submit button after 3 seconds
            setTimeout(() => {
                setIsDisabled(true);
            }, 3000);
        } else {
            message.error(response.data.message || "❌ Submission failed. Please try again.");
        }
    } catch (error) {
        console.error("❌ Submission Error:", error);
        message.error(error.response?.data?.message || "⚠️ Submission error. Please try again.");
    } finally {
        setLoading(false);
    }
};

const goBack = () => {
  setCurrentStep(0); // Navigate back to Personal Information tab
  // setStudentID("");
};


  const handleVerifyChange = (key, checked) => {
    setVerifiedDocs((prev) => ({ ...prev, [key]: checked }));
  };
  // Function to handle file download
  const renderFileLink = (filePath, label) => {
    if (!filePath) {
      return (
        <Row>
          <Col span={24}>
            <p>
              <strong>{label}:</strong> Not Uploaded
            </p>
          </Col>
        </Row>
      );
    }

    return (
      <Row>
        <Col span={24}>
          <a
            href={`${config.curriculaserver}/${filePath}`}
            download
            target="_blank"
            rel="noopener noreferrer"
          >
            <EyeOutlined /> Download {label}
          </a>
        </Col>
      </Row>
    );
  };

  const renderFileLinkWithCheckbox = (filePath, label, key) => {
    return (
      <Row align="middle" style={{ marginBottom: "10px" }}>
        <Col span={16}>
          {filePath ? (
            <a
              href={`${config.curriculaserver}/${filePath}`}
              download
              target="_blank"
              rel="noopener noreferrer"
            >
              <EyeOutlined /> Download {label}
            </a>
          ) : (
            <p>
              <strong>{label}:</strong> Not Uploaded
            </p>
          )}
        </Col>
        {/* <Col span={8}>
          <Checkbox
            checked={verifiedDocs[key] || false}
            onChange={(e) => handleVerifyChange(key, e.target.checked)}
            disabled={!filePath}
          >
            Verified
          </Checkbox>
        </Col> */}
      </Row>
    );
  };
  console.log("Student Data:", studentData);

  if (!studentData) return <p>Loading student data...</p>;
  const allFilesVerified = Object.values(verifiedDocs).every((val) => val);

  const fetchData = async () => {
    setLoading(true);
    try {
        const response = await axios.get('/api/student-records');
        setData(response.data);
    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
        setLoading(false);
    }
};




  return (
    <div style={{ padding: "20px", maxWidth: "900px", margin: "auto" }}>
     <Button type="primary" icon={<ReloadOutlined />} size="small" onClick={fetchStudentData} disabled={loading}>
                Refresh
            </Button>


      {/*  Personal Information */}
      <Card
        title="1. Personal Information"
        bordered
        style={{ marginBottom: "20px" }}
      >
        <Row gutter={16}>
          <Col span={16}>
            {[
              { label: "Student ID", value: studentData.studentID },
              { label: "First Name", value: studentData.firstName },
              { label: "Surname", value: studentData.surname },
              { label: "Gender", value: studentData.gender },
              { label: "Citizenship", value: studentData.citizenship },
                     
              { label: "Marital Status", value: studentData.maritalStatus },
              { label: "Email", value: studentData.email },
              { label: "Phone", value: studentData.phone },
              { label: "Address", value: studentData.address },
              { label: "National ID Number", value: studentData.idNumber },
              {
                label: "Passport Number",
                value: studentData.passportNo || "N/A",
              },
              {
                label: "Student Visa Info",
                value: studentData.studentVisaInfo || "N/A",
              },
              { label: "Nationality", value: studentData.nationality || "N/A" },
            ].map(({ label, value }) => (
              <Row key={label} align="middle" style={{ marginBottom: "8px" }}>
                <Col
                  span={8}
                  style={{
                    textAlign: "left",
                    paddingRight: "10px",
                    fontWeight: "bold",
                  }}
                >
                  {label}:
                </Col>
                <Col span={16}>{value}</Col>
              </Row>
            ))}

            {/*  Uploaded Documents */}
            {/* {renderFileLink(studentData.passportUpload, "Passport Document")}
            {renderFileLink(studentData.visaUpload, "Visa Document")}
            {renderFileLink(studentData.idUpload, "National ID")} */}
          </Col>

          {/*  Passport Photo */}
          <Col span={8} style={{ textAlign: "center" }}>
         
            {studentData.passportPhoto ? (
               
              <img
                src={`${config.curriculaserver}/${studentData.passportPhoto}`}
                alt="Passport Photo"
                style={{
                  width: "150px",
                  height: "200px",
                  objectFit: "cover",
                  borderRadius: "8px",
                  border: "1px solid #ccc",
                }}
              />
              
            ) : (
              <p>
                <strong>No Passport Photo Available</strong>
              </p>
            )}
            
          </Col>
        </Row>
      </Card>

      {/*  Next of Kin Details */}
      <Card
        title="2. Next of Kin Details"
        bordered
        style={{ marginBottom: "20px" }}
      >
        {[
          { label: "Full Name", value: studentData.nextKeenDetails?.fullNames },
          { label: "Email", value: studentData.nextKeenDetails?.email },
          { label: "Phone", value: studentData.nextKeenDetails?.phone },
          {
            label: "Physical Address",
            value: studentData.nextKeenDetails?.physicalAddress,
          },
        ].map(({ label, value }) => (
          <Row key={label} align="middle" style={{ marginBottom: "8px" }}>
            <Col
              span={5}
              style={{
                textAlign: "left",
                paddingRight: "10px",
                fontWeight: "bold",
              }}
            >
              {label}:
            </Col>
            <Col span={16}>{value || "N/A"}</Col>
          </Row>
        ))}
      </Card>

      {/*  Enrollment Details */}
      <Card
        title="3. Enrollment Details"
        bordered
        style={{ marginBottom: "20px" }}
      >
        {[
          {
            label: "High School Certificate No",
            value: studentData.enrollmentDetails?.highSchoolCertificateNumber,
          },
          {
            label: "Graduation Date",
            value: studentData.enrollmentDetails?.graduationDate,
          },
          {
            label: "Department",
            value: studentData.enrollmentDetails?.department,
          },
          { label: "Platform", value: studentData.enrollmentDetails?.platform },
          {
            label: "Enrollment Date",
            value: studentData.enrollmentDetails?.enrollmentDate,
          },
        ].map(({ label, value }) => (
          <Row key={label} align="middle" style={{ marginBottom: "8px" }}>
            <Col
              span={5}
              style={{
                textAlign: "left",
                paddingRight: "10px",
                fontWeight: "bold",
              }}
            >
              {label}:
            </Col>
            <Col span={16}>{value || "N/A"}</Col>
          </Row>
        ))}
        {/* {renderFileLink(
          studentData.enrollmentDetails?.highSchoolCertificates,
          "High School Certificate"
        )} */}
      </Card>

      {/*  Accommodation Details */}
      <Card
        title="4. Accommodation Details"
        bordered
        style={{ marginBottom: "20px" }}
      >
        <Row gutter={16}>
          <Col span={24}>
            {[
              {
                label: "Day Scholar",
                value: studentData.accommodationDetails?.dayScholar,
              },
              {
                label: "Accommodation Type",
                value: studentData.accommodationDetails?.accommodationType,
              },
              {
                label: "Meals",
                value: studentData.accommodationDetails?.meals,
              },
              {
                label: "Special Diet",
                value: studentData.accommodationDetails?.specialDiet,
              },
              {
                label: "Allergies",
                value: studentData.accommodationDetails?.allergies,
              },
            ].map(({ label, value }) => (
              <Row key={label} align="middle" style={{ marginBottom: "8px" }}>
                <Col
                  span={5}
                  style={{
                    textAlign: "left",
                    paddingRight: "10px",
                    fontWeight: "bold",
                  }}
                >
                  {label}:
                </Col>
                <Col span={16}>{value || "N/A"}</Col>
              </Row>
            ))}
          </Col>
        </Row>
      </Card>

      {/* ✅ Tuition Details */}
      <Card
        title="5. Tuition Details"
        bordered
        style={{ marginBottom: "20px" }}
      >
        <Row gutter={16}>
          <Col span={5}>
            <p>
              <strong>Kindle Issued:</strong>
            </p>
          </Col>
          <Col span={12}>
            <p>{studentData.tuitionDetails?.kindleIssued || "N/A"}</p>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={5}>
            <p>
              <strong>Kindle Details:</strong>
            </p>
          </Col>
          <Col span={12}>
            <p>{studentData.tuitionDetails?.kindleDetails || "N/A"}</p>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={5}>
            <p>
              <strong>Chromebook Issued:</strong>
            </p>
          </Col>
          <Col span={12}>
            <p>{studentData.tuitionDetails?.chromebookIssued || "N/A"}</p>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={5}>
            <p>
              <strong>Chromebook Details:</strong>
            </p>
          </Col>
          <Col span={12}>
            <p>{studentData.tuitionDetails?.chromebookDetails || "N/A"}</p>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={5}>
            <p>
              <strong>Stationery Issued:</strong>
            </p>
          </Col>
          <Col span={12}>
            <p>{studentData.tuitionDetails?.stationeryIssued || "N/A"}</p>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={5}>
            <p>
              <strong>Stationery Details:</strong>
            </p>
          </Col>
          <Col span={12}>
            <p>{studentData.tuitionDetails?.stationeryDetails || "N/A"}</p>
          </Col>
        </Row>

        {/* {renderFileLink(
          studentData.tuitionDetails?.kindleProof,
          "Kindle Proof"
        )}
        {renderFileLink(
          studentData.tuitionDetails?.chromebookProof,
          "Chromebook Proof"
        )}
        {renderFileLink(
          studentData.tuitionDetails?.stationeryProof,
          "Stationery Proof"
        )} */}
      </Card>

      {/* Fees Details */}
      <Card title="6. Fees Details" bordered style={{ marginBottom: "20px" }}>
        {[
          {
            label: "Payment Method",
            value: studentData.feesDetails?.paymentMethod,
          },
          {
            label: "Tuition Fees Paid",
            value: studentData.feesDetails?.tuitionFeesPaid,
          },
          {
            label: "Accommodation Fees Paid",
            value: studentData.feesDetails?.accommodationFeesPaid,
          },
          {
            label: "Meals Fees Paid",
            value: studentData.feesDetails?.mealsFeesPaid,
          },
          {
            label: "Graduation Fee",
            value: studentData.feesDetails?.graduationFeesPaid,
          },
          // {
          //   label: "Practicum Fee",
          //   value: studentData.feesDetails?.practicumFee,
          // },
          {
            label: "Registration Fee",
            value: studentData.feesDetails?.registrationFeesPaid,
          },
          {
            label: "Sponsor Name",
            value: studentData.feesDetails?.sponsorName,
          },
          {
            label: "Sponsor Contact",
            value: studentData.feesDetails?.sponsorContact,
          },
        ].map(({ label, value }) => (
          <Row key={label} align="middle" style={{ marginBottom: "8px" }}>
            <Col
              span={5}
              style={{
                textAlign: "left",
                paddingRight: "10px",
                fontWeight: "bold",
              }}
            >
              {label}:
            </Col>
            <Col span={16}>{value || "N/A"}</Col>
          </Row>
        ))}

        {/*  Uploaded Payment Proofs */}
        {/* {renderFileLink(studentData.feesDetails?.tuitionProof, "Tuition Proof")}
        {renderFileLink(
          studentData.feesDetails?.accommodationProof,
          "Accommodation Proof"
        )}
        {renderFileLink(studentData.feesDetails?.mealsProof, "Meals Proof")}
        {renderFileLink(
          studentData.feesDetails?.graduationProof,
          "Graduation Proof"
        )}
        {renderFileLink(
          studentData.feesDetails?.registrationProof,
          "Registration Proof"
        )} */}
      </Card>

      {/*  Denomination Details */}
      <Card
        title="7. Denomination Details"
        bordered
        style={{ marginBottom: "20px" }}
      >
        {[
          {
            label: "Church Name",
            value: studentData.denominationDetails?.churchName,
          },
          {
            label: "Denomination",
            value: studentData.denominationDetails?.denomination,
          },
          {
            label: "Church Location",
            value: studentData.denominationDetails?.churchLocation,
          },
          // {
          //   label: "Church Involvement",
          //   value: studentData.denominationDetails?.churchInvolvement,
          // },
          // { label: "File Status", value: studentData.denominationDetails?.status === 1 ? "✅ Submitted" : "❌ Not Submitted" },
        ].map(({ label, value }) => (
          <Row key={label} align="middle" style={{ marginBottom: "8px" }}>
            <Col
              span={5}
              style={{
                textAlign: "left",
                paddingRight: "10px",
                fontWeight: "bold",
              }}
            >
              {label}:
            </Col>
            <Col span={16}>{value || "N/A"}</Col>
          </Row>
        ))}

        {/*  Uploaded Personal Testimony */}
        {/* {renderFileLink(
          studentData.denominationDetails?.personalTestimony,
          "Personal Testimony"
        )} */}
      </Card>

      <Card title="8. Uploaded Documents" bordered style={{ marginBottom: "20px" }}>
          {renderFileLinkWithCheckbox(studentData.passportUpload, "Passport Document", "passportUpload")}
          {renderFileLinkWithCheckbox(studentData.visaUpload, "Visa Document", "visaUpload")}
          {renderFileLinkWithCheckbox(studentData.idUpload, "National ID", "idUpload")}
          {renderFileLinkWithCheckbox(studentData.enrollmentDetails?.highSchoolCertificates, "High School Certificate", "highSchoolCertificates")}
          {renderFileLinkWithCheckbox(studentData.feesDetails?.tuitionProof, "Tuition Proof", "tuitionProof")}
          {renderFileLinkWithCheckbox(studentData.feesDetails?.accommodationProof, "Accommodation Proof", "accommodationProof")}
          {renderFileLinkWithCheckbox(studentData.feesDetails?.mealsProof, "Meals Proof", "mealsProof")}
          {renderFileLinkWithCheckbox(studentData.feesDetails?.graduationProof, "Graduation Proof", "graduationProof")}
          {renderFileLinkWithCheckbox(studentData.feesDetails?.registrationProof, "Registration Proof", "registrationProof")}
          {renderFileLinkWithCheckbox(studentData.tuitionDetails?.kindleProof, "Kindle Proof", "kindleProof")}
          {renderFileLinkWithCheckbox(studentData.tuitionDetails?.chromebookProof, "Chrome Book Proof", "chromebookProof")}
          {renderFileLinkWithCheckbox(studentData.tuitionDetails?.stationeryProof, "Stationery Proof", "stationeryProof")}
          {renderFileLinkWithCheckbox(studentData.denominationDetails?.personalTestimony, "Personal Testimony", "personalTestimony")}
        </Card>

        {/* Checkbox Confirmation */}
        <Checkbox
          checked={isChecked}
          onChange={(e) => setIsChecked(e.target.checked)}
          disabled={isSubmitted}
        >
          I confirm that all information provided is accurate.
        </Checkbox>

        <br />
        <br />

      {/*  Submit Button */}
      <Tooltip title={isDisabled ? "Submission completed. You cannot submit again." : ""}>
      <Button 
        type="primary" 
        onClick={handleFinalSubmit} 
        loading={loading} 
        // disabled={isDisabled}
      >
        Submit Application
      </Button>

      <Button type="default"  onClick={goBack} style={{ marginBottom: "10px" }}>
                Back
            </Button>


    </Tooltip>
    </div>
  );
};

export default ReviewAndSubmit;
