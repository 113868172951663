import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button, Statistic } from "antd";
import { ReloadOutlined, UserOutlined, FileDoneOutlined, HourglassOutlined } from "@ant-design/icons";
import { Column } from "@ant-design/charts";
import axios from "axios";
import config from "../../../config";

const StatisticsDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [statistics, setStatistics] = useState({
    total: 0, completed: 0, pending: 0,
    personalInfo: { completed: 0, pending: 0 },
    nextKeenDetails: { completed: 0, pending: 0 },
    enrollmentDetails: { completed: 0, pending: 0 },
    accommodationDetails: { completed: 0, pending: 0 },
    tuitionDetails: { completed: 0, pending: 0 },
    feesDetails: { completed: 0, pending: 0 },
    denominationDetails: { completed: 0, pending: 0 }
  });

  useEffect(() => {
    fetchStatistics();
  }, []);

  const fetchStatistics = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(`${config.curriculaserver}/curricula/admin/denomination/students`);
      if (data.success) {
        const total = data.data.length;
        const completed = data.data.filter(student => 
          student.personalInfo?.status === 2 &&
          student.nextKeenDetails?.status === 2 &&
          student.enrollmentDetails?.status === 2 &&
          student.accommodationDetails?.status === 2 &&
          student.tuitionDetails?.status === 2 &&
          student.feesDetails?.status === 2 &&
          student.denominationDetails?.status === 2
        ).length;
        const pending = total - completed;

        const sections = ["personalInfo", "nextKeenDetails", "enrollmentDetails", "accommodationDetails", "tuitionDetails", "feesDetails", "denominationDetails"];
        const sectionStats = {};

        sections.forEach(section => {
          const sectionCompleted = data.data.filter(student => student[section]?.status === 2).length;
          sectionStats[section] = {
            completed: sectionCompleted,
            pending: total - sectionCompleted
          };
        });

        setStatistics({ total, completed, pending, ...sectionStats });
      }
    } catch (error) {
      console.error("Error fetching statistics:", error);
    }
    setLoading(false);
  };

  const chartConfig = (data) => ({
    data,
    xField: "name",
    yField: "value",
    label: { position: "top" },
    columnStyle: { fill: "#1890ff" },
  });

  return (
    <>
    <br/>
    <Card  title={`Students Information Statistics`}> 
    <Row justify="end" style={{ marginTop: "16px" }}>
        <Col>
          <Button type="primary" icon={<ReloadOutlined />} size="small" onClick={fetchStatistics} loading={loading}>
            Refresh
          </Button>
        </Col>
      </Row>
      <br/>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Card hoverable>
            <Statistic title="Total Students" value={statistics.total} prefix={<UserOutlined />} />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Card hoverable>
            <Statistic title="Completed" value={statistics.completed} valueStyle={{ color: '#3f8600' }} prefix={<FileDoneOutlined />} />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Card hoverable>
            <Statistic title="Pending" value={statistics.pending} valueStyle={{ color: '#cf1322' }} prefix={<HourglassOutlined />} />
          </Card>
        </Col>
      </Row>
      
      <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
        {Object.entries(statistics).map(([key, value], index) => 
          key !== "total" && key !== "completed" && key !== "pending" ? (
            <Col xs={24} sm={12} md={8} lg={6} key={key}>
              <Card hoverable>
                <Statistic title={`${key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()} Completed`} value={value.completed} valueStyle={{ color: '#3f8600' }} />
                <Statistic title={`${key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()} Pending`} value={value.pending} valueStyle={{ color: '#cf1322' }} />
              </Card>
            </Col>
          ) : null
        )}
      </Row>
      </Card>
      <br/>
      <br/>

      {Object.entries(statistics)
        .filter(([key]) => key !== "total" && key !== "completed" && key !== "pending")
        .reduce((rows, [key, value], index) => {
          if (index % 4 === 0) rows.push([]);
          rows[rows.length - 1].push(
            <Col xs={24} sm={12} md={6} lg={6} key={key}>
              <Card title={`Total Students vs ${key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()} Completions`}>
                <Column {...chartConfig([
                  { name: "Total Students", value: statistics.total },
                  { name: `${key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim()} Completed`, value: value.completed }
                ])} />
              </Card>
            </Col>
          );
          return rows;
        }, [])
        .map((row, index) => (
          <Row gutter={[16, 16]} style={{ marginTop: "16px" }} key={index}>
            {row}
          </Row>
        ))}

     
    </>
  );
};

export default StatisticsDashboard;
