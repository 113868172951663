import React, { useState } from "react";
import {  Card,  Row,  Col,  Input,  Select,  Button,  Upload,  Checkbox,  message,  Form,  Collapse, DatePicker, Radio, Modal} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import config from "../../../config";
import moment from "moment";

const { Option } = Select;
const { Panel } = Collapse;

const ReviewAndSubmit = ({ studentID, onStepComplete }) => {
  const [form] = Form.useForm();
  const [nextKeenForm] = Form.useForm();
  const [enrollmentForm] = Form.useForm();
  const [accommodationForm] = Form.useForm();
  const [tuitionForm] = Form.useForm();
  const [feesForm] = Form.useForm();
  const [denominationForm] = Form.useForm();
  

  const [loading, setLoading] = useState(false);
  const [verifiedDocs, setVerifiedDocs] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [citizenship, setCitizenship] = useState("citizen");
  const [photoPreview, setPhotoPreview] = useState(null);
  const [passportPhoto, setPassportPhoto] = useState([]);
  const [passportUpload, setPassportUpload] = useState([]);
  const [visaUpload, setVisaUpload] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [idUpload, setIdUpload] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [denominationFiles, setDenominationFiles] = useState([]);
  

  const [isDayScholar, setIsDayScholar] = useState(null);
  const [isKindleIssued, setIsKindleIssued] = useState(null);
const [isChromebookIssued, setIsChromebookIssued] = useState(null);
const [isStationeryIssued, setIsStationeryIssued] = useState(null);
const [paymentMethod, setPaymentMethod] = useState(null);
const [tuitionFiles, setTuitionFiles] = useState({  kindleProof: [],  chromebookProof: [],  stationeryProof: [],});
const [feesFiles, setFeesFiles] = useState({  tuitionProof: [], accommodationProof: [], mealsProof: [],  graduationProof: [], registrationProof: []});
const [feeStatus, setFeeStatus] = useState({  tuition: "notPaid",  accommodation: "notPaid",  meals: "notPaid",  graduation: "notPaid",  registration: "notPaid",});
const [completedPanels, setCompletedPanels] = useState({  personalDetails: false, nextOfKin: false, enrollment: false, accommodation: false, tuition: false, fees: false, denomination: false,});


  // Handle Verification Checkbox
  const handleVerifyChange = (key, checked) => {
    setVerifiedDocs((prev) => ({ ...prev, [key]: checked }));
  };

  // Handle Final Submission
  const handleFinalSubmit = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      const studentID = values.studentID;
  
      if (!studentID) {
        message.error("Student ID is required!");
        return;
      }
  
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (values[key] && typeof values[key] !== "object") {
          formData.append(key, values[key]);
        }
      });
  
      // Append all file uploads
      if (passportPhoto.length > 0) formData.append("passportPhoto", passportPhoto[0].originFileObj);
      if (idUpload.length > 0) formData.append("idUpload", idUpload[0].originFileObj);
      if (passportUpload.length > 0) formData.append("passportUpload", passportUpload[0].originFileObj);
      if (visaUpload.length > 0) formData.append("visaUpload", visaUpload[0].originFileObj);
  
      setLoading(true);
  
      const response = await axios.post(
        `${config.curriculaserver}/curricula/admin/StudentDataEntry/personal-information/add`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
  
      if (response.data.success) {
        message.success("Personal Details Saved Successfully!");
  
        // ✅ Mark panel as completed and disable the submit button
        setCompletedPanels((prev) => ({ ...prev, personalDetails: true }));
        setIsSubmitted(true);  // Mark the form as submitted
  
      } else {
        message.error(response.data.message || "Failed to save data.");
      }
    } catch (error) {
      console.error("API Error:", error.response?.data || error.message);
      message.error("Please fill all required fields!");
    } finally {
      setLoading(false);
    }
  };
  

  const handleSaveAndContinue = () => {
    // Validate form fields before submitting
    nextKeenForm.validateFields()
      .then(values => {
        if (!studentID) {
          message.error("Student ID is missing. Please enter personal info first!");
          return;
        }
  
        // Proceed with submission if validation passes
        setLoading(true);
        
        // Send the form data to the backend API
        axios.post(`${config.curriculaserver}/curricula/admin/StudentDataEntry/next-keen/add`, { ...values, studentID })
          .then(response => {
            // On success:
            message.success(response.data.message);
  
            // Mark the panel as completed and set the status to 1 after successful submission
            setCompletedPanels(prev => ({ ...prev, nextKeenDetails: true }));
            setIsSubmitted(true);  // Mark the form as submitted to disable further actions
  
            // Optionally, update the status in the backend
            axios.post(`${config.curriculaserver}/curricula/admin/StudentDataEntry/next-keen/status`, { studentID, status: 1 })
              .then(() => {
                console.log("Status updated to 1 in the backend.");
              })
              .catch(() => {
                message.error("Failed to update status.");
              });
  
            if (onStepComplete) onStepComplete();  // Proceed to the next step
          })
          .catch(() => {
            // On failure:
            message.error("Failed to save Next of Kin details.");
          })
          .finally(() => {
            setLoading(false);  // Turn off loading state after submission
          });
      })
      .catch(() => {
        message.error("⚠️ Please fill all required fields!");
      });
  };
  
  
  const handleEnrollmentSave = async () => {
    try {
      await enrollmentForm.validateFields();
      const values = enrollmentForm.getFieldsValue();

      values.graduationDate = values.graduationDate ? moment(values.graduationDate).format("DD-MM-YYYY") : "";
      values.enrollmentDate = values.enrollmentDate ? moment(values.enrollmentDate).format("DD-MM-YYYY") : "";

      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      if (fileList.length > 0 && fileList[0].originFileObj) {
        formData.append("highSchoolCertificates", fileList[0].originFileObj);
      }

      formData.append("studentID", studentID);

      setLoading(true);
      const response = await axios.post(
        `${config.curriculaserver}/curricula/admin/nextkeen/enrollment/add`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      if (response.data.success) {
        message.success("Enrollment details saved successfully!");
        if (onStepComplete) onStepComplete();
      } else {
        message.error(response.data.message || "Failed to save data.");
      }
    } catch (error) {
      message.error(" Please fill all required fields!");
    } finally {
      setLoading(false);
    }
  };
  const handleAccommodationSave = async () => {
    try {
      await accommodationForm.validateFields();
      const values = accommodationForm.getFieldsValue();
  
      if (!studentID) {
        message.error(" Student ID is missing. Please enter personal info first!");
        return;
      }
  
      const data = { ...values, studentID };
  
      setLoading(true);
      const response = await axios.post(
        `${config.curriculaserver}/curricula/admin/nextkeen/accommodation/add`,
        data
      );
  
      if (response.data.success) {
        message.success("Accommodation details saved successfully!");
        if (onStepComplete) onStepComplete();
      } else {
        message.error(response.data.message || "Failed to save data.");
      }
    } catch (error) {
      message.error(" Please fill all required fields!");
    } finally {
      setLoading(false);
    }
  };

  const handleTuitionSave = async () => {
    try {
      await tuitionForm.validateFields();
      const values = tuitionForm.getFieldsValue();
  
      if (!values.studentID) {
        message.error(" Student ID is missing. Please enter personal info first!");
        return;
      }
  
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });
  
      if (tuitionFiles.kindleProof.length > 0 && tuitionFiles.kindleProof[0].originFileObj) {
        formData.append("kindleProof", tuitionFiles.kindleProof[0].originFileObj);
      }
      if (tuitionFiles.chromebookProof.length > 0 && tuitionFiles.chromebookProof[0].originFileObj) {
        formData.append("chromebookProof", tuitionFiles.chromebookProof[0].originFileObj);
      }
      if (tuitionFiles.stationeryProof.length > 0 && tuitionFiles.stationeryProof[0].originFileObj) {
        formData.append("stationeryProof", tuitionFiles.stationeryProof[0].originFileObj);
      }
  
      formData.append("studentID", values.studentID);
  
      setLoading(true);
      const response = await axios.post(
        `${config.curriculaserver}/curricula/admin/tuition/add`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
  
      if (response.data.success) {
        message.success("Tuition details saved successfully!");
        onStepComplete();
      } else {
        message.error(response.data.message || "Failed to save data.");
      }
    } catch (error) {
      message.error(" Please fill all required fields!");
    } finally {
      setLoading(false);
    }
  };
  const handleFeesSave = async () => {
    try {
      await feesForm.validateFields();
      const values = feesForm.getFieldsValue();
  
      if (!values.studentID) {
        message.error("Student ID is missing. Please enter personal info first!");
        return;
      }
  
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });
  
      ["tuitionProof", "accommodationProof", "mealsProof", "graduationProof", "registrationProof"].forEach(field => {
        if (feesFiles[field]?.length > 0 && feesFiles[field][0].originFileObj) {
          formData.append(field, feesFiles[field][0].originFileObj);
        }
      });
  
      formData.append("studentID", values.studentID);
  
      setLoading(true);
      const response = await axios.post(
        `${config.curriculaserver}/curricula/admin/fees/add`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
  
      if (response.data.success) {
        message.success("Fees details saved successfully!");
        onStepComplete();
      } else {
        message.error(response.data.message || "Failed to save data.");
      }
    } catch (error) {
      message.error(" Please fill all required fields!");
    } finally {
      setLoading(false);
    }
  };
  const handleDenominationAction = async (isFinalSubmit = false) => {
    try {
      await denominationForm.validateFields();
      const values = denominationForm.getFieldsValue();
  
      if (!studentID) {
        message.error("Student ID is missing. Please enter personal info first!");
        return;
      }
  
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });
  
      if (denominationFiles.length > 0 && denominationFiles[0].originFileObj) {
        formData.append("personalTestimony", denominationFiles[0].originFileObj);
      }
  
      formData.append("studentID", studentID);
      formData.append("status", isFinalSubmit ? 1 : 0); // ✅ Status 0 = Save, Status 1 = Submit
  
      setLoading(true);
      const response = await axios.post(
        `${config.curriculaserver}/curricula/admin/denomination/${isFinalSubmit ? "submit" : "add"}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
  
      if (response.data.success) {
        message.success(isFinalSubmit ? "🎉 Form submitted successfully!" : "✅ Denomination details saved successfully!");
        onStepComplete();
      } else {
        message.error(response.data.message || "❌ Operation failed.");
      }
    } catch (error) {
      message.error("⚠ Please fill all required fields!");
    } finally {
      setLoading(false);
    }
  };
  

  
  


  const handleDenominationFileChange = ({ fileList }) => {
    setDenominationFiles(fileList.slice(-1)); // Keep only the latest file
  };
  

const handleFeeStatusChange = (feeType, value) => {
  setFeeStatus((prev) => ({ ...prev, [feeType]: value }));
  if (value === "notPaid") {
    feesForm.setFieldsValue({ [`${feeType}FeesPaid`]: "", [`${feeType}Proof`]: "" });
    setFeesFiles((prev) => ({ ...prev, [`${feeType}Proof`]: [] }));
  }
};

  const handleFeesFileChange = (fileType, { fileList }) => {
    setFeesFiles((prev) => ({ ...prev, [fileType]: fileList.slice(-1) })); // Keep only the latest file
  };

  const handleTuitionFileChange = (fileType, { fileList }) => {
    setTuitionFiles((prev) => ({ ...prev, [fileType]: fileList.slice(-1) })); // Keep only latest file
  };
  
  const beforeUploadPhoto = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isLt500Kb = file.size / 1024 < 500;

    if (!isJpgOrPng) {
      message.error("Only JPG/PNG files are allowed!");
      return false;
    }
    if (!isLt500Kb) {
      message.error("Photo must be smaller than 500KB!");
      return false;
    }

    setPhotoPreview(URL.createObjectURL(file));
    setPassportPhoto([file]); // Fix here

    form.setFieldsValue({ passportPhoto: file });

    return false; // Prevent auto-upload
  };

  const handleFileChange =
    (setter) =>
    ({ fileList }) => {
      setter(fileList.slice(-1)); // Keep only the latest file
    };

  const handlePassportUploadChange = ({ fileList }) => {
    setPassportUpload(fileList.slice(-1));
  };

  const handleVisaUploadChange = ({ fileList }) => {
    setVisaUpload(fileList.slice(-1));
  };

  // Handle clearing the uploaded photo
  const handleClearPhoto = () => {
    setPhotoPreview(null);
    setFileList([]);
    form.setFieldsValue({ passportPhoto: null }); // Clear validation state
  };

  return (
    <div style={{ padding: "20px", maxWidth: "900px", margin: "auto" }}>
           <Form form={form} layout="vertical">
           <Collapse defaultActiveKey={["1"]}>
           <Panel
  header={
    <span>
      1. Personal Details {isSubmitted && <span style={{ color: "green" }}>✔️</span>}
    </span>
  }
  key="1"
>
  <Form form={form} layout="vertical">
    {/* ✅ Student Basic Info */}
    <Row gutter={16}>
      <Col xs={24} sm={12} md={8}>
        <Form.Item label="Student ID" name="studentID" rules={[{ required: true }]}>
          <Input value={studentID} />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8}>
        <Form.Item label="First Name" name="firstName" rules={[{ required: true }]}>
          <Input disabled={isSubmitted} />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8}>
        <Form.Item label="Last Name" name="lastName" rules={[{ required: true }]}>
          <Input disabled={isSubmitted} />
        </Form.Item>
      </Col>
    </Row>

    {/* ✅ Passport Upload */}
    <Row gutter={16}>
      <Col xs={24} sm={12} md={8}>
        <Form.Item label="Passport Size Photo" name="passportPhoto" rules={[{ required: true }]}>
          <Upload
            beforeUpload={() => false}
            maxCount={1}
            fileList={passportPhoto}
            onChange={(info) => {
              setPassportPhoto(info.fileList);
              form.setFieldsValue({ passportPhoto: info.fileList });
            }}
            disabled={isSubmitted}
          >
            <Button icon={<UploadOutlined />} disabled={isSubmitted}>Upload Passport Photo</Button>
          </Upload>
        </Form.Item>
        {passportPhoto.length > 0 && (
          <>
            <img 
              src={URL.createObjectURL(passportPhoto[0].originFileObj)} 
              alt="Passport Preview" 
              style={{ width: 100, height: 100, borderRadius: 5, objectFit: "cover", marginTop: 5 }} 
            />
            <Button 
              onClick={() => {
                setPassportPhoto([]);
                form.setFieldsValue({ passportPhoto: [] });
              }} 
              type="link" danger 
              disabled={isSubmitted}
            >
              Remove
            </Button>
          </>
        )}
      </Col>
    </Row>

    {/* ✅ Gender, Citizenship, Marital Status */}
    <Row gutter={16}>
      <Col xs={24} sm={12} md={8}>
        <Form.Item label="Gender" name="gender" rules={[{ required: true }]}>
          <Select disabled={isSubmitted}>
            <Option value="male">Male</Option>
            <Option value="female">Female</Option>
            <Option value="other">Other</Option>
          </Select>
        </Form.Item>
      </Col>

      <Col xs={24} sm={12} md={8}>
        <Form.Item label="Citizenship" name="citizenship" rules={[{ required: true }]}>
          <Select onChange={setCitizenship} disabled={isSubmitted}>
            <Option value="citizen">Citizen</Option>
            <Option value="non-citizen">Non-Citizen</Option>
          </Select>
        </Form.Item>
      </Col>

      <Col xs={24} sm={12} md={8}>
        <Form.Item label="Marital Status" name="maritalStatus" rules={[{ required: true }]}>
          <Select disabled={isSubmitted}>
            <Option value="single">Single</Option>
            <Option value="married">Married</Option>
          </Select>
        </Form.Item>
      </Col>
    </Row>

    {/* ✅ National ID */}
    <Row gutter={16}>
      <Col xs={24} sm={12} md={8}>
        <Form.Item label="National ID Number" name="idNumber" rules={[{ required: true }]}>
          <Input disabled={isSubmitted} />
        </Form.Item>
      </Col>

      <Col xs={24} sm={12} md={8}>
        <Form.Item
          label="Upload National ID Proof"
          name="idUpload"
          valuePropName="fileList"
          getValueFromEvent={(e) => e && e.fileList}
          rules={[{ required: idUpload.length === 0, message: "National ID proof is required" }]}
        >
          <Upload
            beforeUpload={() => false}
            fileList={idUpload}
            onChange={(info) => {
              setIdUpload(info.fileList);
              form.setFieldsValue({ idUpload: info.fileList });
            }}
            maxCount={1}
            disabled={isSubmitted}
          >
            <Button icon={<UploadOutlined />} disabled={isSubmitted}>Upload</Button>
          </Upload>
          {idUpload.length > 0 && (
            <a href={URL.createObjectURL(idUpload[0].originFileObj)} target="_blank" rel="noopener noreferrer">
              View ID Copy
            </a>
          )}
        </Form.Item>
      </Col>
    </Row>

    {/* ✅ Conditional Upload for Non-Citizens */}
    {citizenship === "non-citizen" && (
      <>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={8}>
            <Form.Item label="Nationality" name="nationality" rules={[{ required: true }]}>
              <Input disabled={isSubmitted} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item label="Passport Number" name="passportNo" rules={[{ required: true }]}>
              <Input disabled={isSubmitted} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item label="Visa Number" name="visaNo" rules={[{ required: true }]}>
              <Input disabled={isSubmitted} />
            </Form.Item>
          </Col>
        </Row>

        {/* ✅ Passport & Visa Proof Uploads for Non-Citizens */}
        <Row gutter={16}>
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              label="Upload Passport Proof"
              name="passportUpload"
              valuePropName="fileList"
              getValueFromEvent={(e) => e && e.fileList}
              rules={[{ required: passportUpload.length === 0, message: "Passport proof is required" }]}
            >
              <Upload
                beforeUpload={() => false}
                fileList={passportUpload}
                onChange={(info) => {
                  setPassportUpload(info.fileList);
                  form.setFieldsValue({ passportUpload: info.fileList });
                }}
                maxCount={1}
                disabled={isSubmitted}
              >
                <Button icon={<UploadOutlined />} disabled={isSubmitted}>Upload</Button>
              </Upload>
              {passportUpload.length > 0 && (
                <a href={URL.createObjectURL(passportUpload[0].originFileObj)} target="_blank" rel="noopener noreferrer">
                  View Passport Proof
                </a>
              )}
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              label="Upload Visa Proof"
              name="visaUpload"
              valuePropName="fileList"
              getValueFromEvent={(e) => e && e.fileList}
              rules={[{ required: visaUpload.length === 0, message: "Visa proof is required" }]}
            >
              <Upload
                beforeUpload={() => false}
                fileList={visaUpload}
                onChange={(info) => {
                  setVisaUpload(info.fileList);
                  form.setFieldsValue({ visaUpload: info.fileList });
                }}
                maxCount={1}
                disabled={isSubmitted}
              >
                <Button icon={<UploadOutlined />} disabled={isSubmitted}>Upload</Button>
              </Upload>
              {visaUpload.length > 0 && (
                <a href={URL.createObjectURL(visaUpload[0].originFileObj)} target="_blank" rel="noopener noreferrer">
                  View Visa Proof
                </a>
              )}
            </Form.Item>
          </Col>
        </Row>
      </>
    )}

    {/* ✅ Email, Phone, Address */}
    <Row gutter={16}>
      <Col xs={24} sm={12} md={8}>
        <Form.Item label="Email" name="email" rules={[{ required: true, type: "email" }]}>
          <Input disabled={isSubmitted} />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8}>
        <Form.Item label="Phone" name="phone" rules={[{ required: true }]}>
          <Input disabled={isSubmitted} />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8}>
        <Form.Item label="Address" name="address" rules={[{ required: true }]}>
          <Input disabled={isSubmitted} />
        </Form.Item>
      </Col>
    </Row>

    <Checkbox checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} disabled={isSubmitted}>
      I confirm that all information provided is accurate.
    </Checkbox>

    <br />
    <br />

    <Button type="primary" onClick={handleFinalSubmit} loading={loading} disabled={isSubmitted}>
      Submit
    </Button>
  </Form>
</Panel>


<Panel
  header={
    <span>
      2. Next Keen Details 
      {isSubmitted && <span style={{ color: "green" }}>✔️</span>} {/* Show green tick after submission */}
    </span>
  }
  key="2"
>
  <Form form={nextKeenForm} layout="vertical">
    {/* Student ID */}
    <Row gutter={16}>
      <Col xs={24} sm={12} md={8}>
        <Form.Item label="Student ID" name="studentID">
          <Input value={studentID} disabled />
        </Form.Item>
      </Col>
    </Row>

    {/* Next of Kin Information */}
    <Row gutter={16}>
      <Col xs={24} sm={12} md={8}>
        <Form.Item label="Full Names" name="fullNames" rules={[{ required: true, message: "Please input full names!" }]}>
          <Input disabled={isSubmitted} />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8}>
        <Form.Item label="Email" name="email" rules={[{ required: true, type: "email", message: "Please input a valid email!" }]}>
          <Input disabled={isSubmitted} />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8}>
        <Form.Item label="Phone Number" name="phone" rules={[{ required: true, message: "Please input the phone number!" }]}>
          <Input disabled={isSubmitted} />
        </Form.Item>
      </Col>
    </Row>

    <Row gutter={16}>
      <Col xs={24} sm={12} md={8}>
        <Form.Item label="Physical Address" name="physicalAddress" rules={[{ required: true, message: "Please input the physical address!" }]}>
          <Input disabled={isSubmitted} />
        </Form.Item>
      </Col>
    </Row>

    {/* Submit Button */}
    <Form.Item>
      <Button type="primary" onClick={handleSaveAndContinue} loading={loading} disabled={isSubmitted}>
        Submit
      </Button>
    </Form.Item>
  </Form>
</Panel>



        <Panel header="3. Enrollment Details" key="3">
         <Form form={enrollmentForm} layout="vertical">
    <Row gutter={16}>
      <Col xs={24} sm={12} md={8}>
        <Form.Item label="Student ID" name="studentID">
          <Input value={studentID} disabled />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8}>
        <Form.Item label="Semester" name="semester" rules={[{ required: true, message: "Please select your semester!" }]}>
          <Select>
            <Option value="1">1</Option>
            <Option value="2">2</Option>
            <Option value="3">3</Option>
            <Option value="4">4</Option>
            <Option value="5">5</Option>
            <Option value="6">6</Option>
            <Option value="5">7</Option>
            <Option value="6">8</Option>
          </Select>
        </Form.Item>
      </Col>
    </Row>

    <h3>Admission Requirements</h3>
    <Row gutter={16}>
      <Col xs={24} sm={12} md={8}>
        <Form.Item label="High School/College Certificates" name="highSchoolCertificates" rules={[{ required: true, message: "Please upload your certificates!" }]}>
          <Upload beforeUpload={() => false} maxCount={1} fileList={fileList} onChange={handleFileChange(setFileList)}>
            <Button icon={<UploadOutlined />}>Upload Certificate</Button>
          </Upload>
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8}>
        <Form.Item label="High School Certificate Number" name="highSchoolCertificateNumber" rules={[{ required: true, message: "Please input your certificate number!" }]}>
          <Input />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8}>
        <Form.Item label="Graduation Date" name="graduationDate" rules={[{ required: true, message: "Please select your graduation date!" }]}>
          <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
        </Form.Item>
      </Col>
    </Row>

    <Row gutter={16}>
      <Col xs={24} sm={12} md={8}>
        <Form.Item label="Department Enrolled to" name="department" rules={[{ required: true, message: "Please input your department!" }]}>
          <Input />
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8}>
        <Form.Item label="Platform" name="platform" rules={[{ required: true, message: "Please select your platform!" }]}>
          <Select>
            <Option value="online">Online</Option>
            <Option value="onsite">Onsite</Option>
            <Option value="non-accredited">Non-accredited</Option>
            <Option value="short-courses">Short Courses</Option>
          </Select>
        </Form.Item>
      </Col>
      <Col xs={24} sm={12} md={8}>
        <Form.Item label="Enrollment Date" name="enrollmentDate" rules={[{ required: true, message: "Please select your enrollment date!" }]}>
          <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
        </Form.Item>
      </Col>
    </Row>

    {/* ✅ New Semester Field */}
    <Row gutter={16}>
     
    </Row>

    <Form.Item>
      <Button type="primary" onClick={handleEnrollmentSave} loading={loading}>
        Save and Continue
      </Button>
    </Form.Item>
         </Form>
        </Panel>
        <Panel header="4. Accommodation Details" key="4">
        
            <Form form={accommodationForm} layout="vertical">
              <Row gutter={16}>
                <Col xs={24} sm={8} md={8}>
                  <Form.Item label="Student ID">
                    <Input value={studentID} disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
        <Form.Item label="Semester" name="semester" rules={[{ required: true, message: "Please select your semester!" }]}>
          <Select>
            <Option value="1">1</Option>
            <Option value="2">2</Option>
            <Option value="3">3</Option>
            <Option value="4">4</Option>
            <Option value="5">5</Option>
            <Option value="6">6</Option>
            <Option value="5">7</Option>
            <Option value="6">8</Option>
          </Select>
        </Form.Item>
      </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={24} sm={8} md={8}>
                  <Form.Item
                    label="Are you a day scholar?"
                    name="dayScholar"
                    rules={[{ required: true, message: "Please specify if you're a day scholar!" }]}
                  >
                    <Radio.Group onChange={(e) => setIsDayScholar(e.target.value)}>
                      <Radio value="yes">Yes</Radio>
                      <Radio value="no">No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

              {isDayScholar === "no" && (
                <>
                  <Row gutter={16}>
                    <Col xs={24} sm={8} md={8}>
                      <Form.Item label="Accommodation Type" name="accommodationType" rules={[{ required: true, message: "Please specify your accommodation type!" }]}>
                        <Select>
                          <Option value="dormitory">Dormitory</Option>
                          <Option value="rentalFlats">Rental Flats</Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={8} md={8}>
                      <Form.Item label="Meals" name="meals" rules={[{ required: true, message: "Please specify your meal plan!" }]}>
                        <Select>
                          <Option value="fullBoard">Full Board</Option>
                          <Option value="halfBoard">Half Board</Option>
                          <Option value="noMeals">No meals at all</Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={8} md={8}>
                      <Form.Item label="Special Diet?" name="specialDiet" rules={[{ required: true, message: "Please specify if you have a special diet!" }]}>
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col xs={24} sm={8} md={8}>
                      <Form.Item label="Any known allergies?" name="allergies" rules={[{ required: true, message: "Please specify any known allergies!" }]}>
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}

              <Form.Item>
                <Button type="primary" onClick={handleAccommodationSave} loading={loading}>
                  Save and Continue
                </Button>
              </Form.Item>
            </Form>
          
        </Panel>   
        <Panel header="5. Tuition Details" key="5">
            
              <Form form={tuitionForm} layout="vertical">
                <Row gutter={16}>
                  <Col xs={24} sm={8} md={8}>
                    <Form.Item label="Student ID">
                      <Input value={studentID} disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
        <Form.Item label="Semester" name="semester" rules={[{ required: true, message: "Please select your semester!" }]}>
          <Select>
            <Option value="1">1</Option>
            <Option value="2">2</Option>
            <Option value="3">3</Option>
            <Option value="4">4</Option>
            <Option value="5">5</Option>
            <Option value="6">6</Option>
            <Option value="5">7</Option>
            <Option value="6">8</Option>
          </Select>
        </Form.Item>
      </Col>
                </Row>

                <Row gutter={16}>
                  <Col xs={24} sm={12} md={12}>
                    <Form.Item label="Issued with Kindle?" name="kindleIssued" rules={[{ required: true, message: "Please specify if issued with Kindle!" }]}>
                      <Radio.Group onChange={(e) => setIsKindleIssued(e.target.value)}>
                        <Radio value="yes">Yes</Radio>
                        <Radio value="no">No</Radio>
                      </Radio.Group>
                    </Form.Item>
                    {isKindleIssued === "yes" && (
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item label="Kindle details" name="kindleDetails" rules={[{ required: true, message: "Please provide Kindle details!" }]}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item label="Upload Kindle Proof" name="kindleProof" rules={[{ required: true, message: "Please upload proof!" }]}>
                            <Upload beforeUpload={() => false} maxCount={1} fileList={tuitionFiles.kindleProof} onChange={(info) => handleTuitionFileChange("kindleProof", info)}>
                              <Button icon={<UploadOutlined />}>Upload Proof</Button>
                            </Upload>
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col xs={24} sm={12} md={12}>
                    <Form.Item label="Issued with Chromebook?" name="chromebookIssued" rules={[{ required: true, message: "Please specify if issued with Chromebook!" }]}>
                      <Radio.Group onChange={(e) => setIsChromebookIssued(e.target.value)}>
                        <Radio value="yes">Yes</Radio>
                        <Radio value="no">No</Radio>
                      </Radio.Group>
                    </Form.Item>
                    {isChromebookIssued === "yes" && (
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item label="Chromebook details" name="chromebookDetails" rules={[{ required: true, message: "Please provide Chromebook details!" }]}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item label="Upload Chromebook Proof" name="chromebookProof" rules={[{ required: true, message: "Please upload proof!" }]}>
                            <Upload beforeUpload={() => false} maxCount={1} fileList={tuitionFiles.chromebookProof} onChange={(info) => handleTuitionFileChange("chromebookProof", info)}>
                              <Button icon={<UploadOutlined />}>Upload Proof</Button>
                            </Upload>
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col xs={24} sm={12} md={12}>
                    <Form.Item label="Issued with Other Stationery?" name="stationeryIssued" rules={[{ required: true, message: "Please specify if issued with stationery!" }]}>
                      <Radio.Group onChange={(e) => setIsStationeryIssued(e.target.value)}>
                        <Radio value="yes">Yes</Radio>
                        <Radio value="no">No</Radio>
                      </Radio.Group>
                    </Form.Item>
                    {isStationeryIssued === "yes" && (
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item label="Other Stationery details" name="stationeryDetails" rules={[{ required: true, message: "Please provide stationery details!" }]}>
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item label="Upload Stationery Proof" name="stationeryProof" rules={[{ required: true, message: "Please upload proof!" }]}>
                            <Upload beforeUpload={() => false} maxCount={1} fileList={tuitionFiles.stationeryProof} onChange={(info) => handleTuitionFileChange("stationeryProof", info)}>
                              <Button icon={<UploadOutlined />}>Upload Proof</Button>
                            </Upload>
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>

                <Form.Item>
                  <Button type="primary" onClick={handleTuitionSave} loading={loading}>
                    Save and Continue
                  </Button>
                </Form.Item>
              </Form>
            
        </Panel>
        <Panel header="6. Fees Details" key="6">
          
            <Form form={feesForm} layout="vertical">
              {/* Student ID */}
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Form.Item label="Student ID">
                    <Input value={studentID} disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
        <Form.Item label="Semester" name="semester" rules={[{ required: true, message: "Please select your semester!" }]}>
          <Select>
            <Option value="1">1</Option>
            <Option value="2">2</Option>
            <Option value="3">3</Option>
            <Option value="4">4</Option>
            <Option value="5">5</Option>
            <Option value="6">6</Option>
            <Option value="5">7</Option>
            <Option value="6">8</Option>
          </Select>
        </Form.Item>
      </Col>
              </Row>

              {/* Payment Method */}
              <Row gutter={16}>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item label="How do you intend to pay your fees?" name="paymentMethod" rules={[{ required: true }]}>
                    <Select onChange={(value) => setPaymentMethod(value)}>
                      <Option value="personal">Personal</Option>
                      <Option value="sponsorship">Sponsorship</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              {/* Sponsorship Details */}
              {paymentMethod === "sponsorship" && (
                <Row gutter={16}>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Sponsor Name" name="sponsorName" rules={[{ required: true }]}>
                      <Input placeholder="Enter sponsor name" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item label="Sponsor Contact" name="sponsorContact" rules={[{ required: true }]}>
                      <Input placeholder="Enter sponsor contact details" />
                    </Form.Item>
                  </Col>
                </Row>
              )}

              {/* Fees Sections */}
              {["tuition", "accommodation", "meals", "graduation", "registration"].map((feeType) => (
                <Row gutter={16} key={feeType}>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item label={`${feeType.charAt(0).toUpperCase() + feeType.slice(1)} Fees`} name={`${feeType}FeesStatus`} rules={[{ required: true }]}>
                      <Select onChange={(value) => handleFeeStatusChange(feeType, value)}>
                        <Option value="paid">Paid</Option>
                        <Option value="notPaid">Not Paid</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {feeStatus[feeType] === "paid" && (
                    <>
                      <Col xs={24} sm={12} md={8}>
                        <Form.Item label={`${feeType.charAt(0).toUpperCase() + feeType.slice(1)} Fee Paid ($)`} name={`${feeType}FeesPaid`} rules={[{ required: true }]}>
                          <Input type="number" />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={8}>
                        <Form.Item label={`Upload ${feeType.charAt(0).toUpperCase() + feeType.slice(1)} Fee Proof`} name={`${feeType}Proof`} rules={[{ required: true }]}>
                          <Upload beforeUpload={() => false} maxCount={1} fileList={feesFiles[`${feeType}Proof`]} onChange={(info) => handleFeesFileChange(`${feeType}Proof`, info)}>
                            <Button icon={<UploadOutlined />}>Upload Proof</Button>
                          </Upload>
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Row>
              ))}

              {/* Submit Button */}
              <Form.Item>
                <Button type="primary" onClick={handleFeesSave} loading={loading}>Save and Continue</Button>
              </Form.Item>
            </Form>
         
        </Panel>   
        <Panel header="7. Denomination Details" key="7">
          
            <Form form={denominationForm} layout="vertical">
              {/* Student ID */}
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Form.Item label="Student ID">
                    <Input value={studentID} disabled />
                  </Form.Item>
                </Col>
              </Row>

              {/* Church Details */}
              <Row gutter={16}>
                <Col xs={24} sm={8}>
                  <Form.Item label="Name of Church" name="churchName" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item label="Name of Denomination" name="denomination" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item label="Location of Church" name="churchLocation" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              {/* Upload Personal Testimony */}
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Form.Item label="Upload Personal Testimony" name="personalTestimony" rules={[{ required: true }]}>
                    <Upload beforeUpload={() => false} maxCount={1} fileList={denominationFiles} onChange={handleDenominationFileChange}>
                      <Button icon={<UploadOutlined />}>Upload Testimony</Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>

              {/* Save & Continue and Final Submit Buttons */}
              <Form.Item>
                
                <Button type="primary" onClick={() => setIsModalVisible(true)} loading={loading} style={{ marginLeft: "10px" }}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
         

          {/* Confirmation Modal */}
          <Modal
            title="Confirm Submission"
            open={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            onOk={() => handleDenominationAction(true)}
            okText="Yes, Submit"
            cancelText="Cancel"
          >
            <p>Are you sure you want to submit the form? Once submitted, changes cannot be made.</p>
          </Modal>
        </Panel>

        











      </Collapse>

       
      </Form>





    </div>
  );
};

export default ReviewAndSubmit;
