import React, { useEffect, useState, useRef } from "react";
import { Table, message, Card, Row, Col, Tooltip, Input, Button, Space, Switch } from "antd";
import { DownloadOutlined, SearchOutlined, ReloadOutlined } from "@ant-design/icons";
import axios from "axios";
import config from "../../../config";
import Highlighter from "react-highlight-words";
import * as XLSX from 'xlsx';

const StudentReportTable = () => {
  const [loading, setLoading] = useState(false);
  const [students, setStudents] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  
  const searchInput = useRef(null);

  useEffect(() => {
    fetchStudentData();
  }, []);

  const fetchStudentData = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(`${config.curriculaserver}/curricula/admin/denomination/students`);
      if (data.success) {
        setStudents(data.data.map((student, index) => ({ ...student, serialNo: index + 1 }))); // Add Serial No.
      } else {
        message.error("Failed to load student data.");
      }
    } catch (error) {
      console.error("Error fetching student data:", error);
      message.error("Error fetching student data.");
    }
    setLoading(false);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] || ""}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });


  const renderDownloadIcon = (filePath, label) => {
    if (!filePath) return "N/A";
    return (
      <Tooltip title={`Download ${label}`}>
        <a href={`${config.curriculaserver}/${filePath}`} download target="_blank" rel="noopener noreferrer">
          <DownloadOutlined style={{ fontSize: "18px", color: "#1890ff" }} />
        </a>
      </Tooltip>
    );
  };

  const columns = [
    { title: "S.No", dataIndex: "serialNo", key: "serialNo", render: (text) => <strong>{text}</strong> },
    { title: "Student ID", dataIndex: "studentID", key: "studentID", ...getColumnSearchProps("studentID") },
    
    // Personal Information
    { title: "First Name", dataIndex: ["personalInfo", "firstName"], key: "firstName", ...getColumnSearchProps("personalInfo.firstName") },
    { title: "Last Name", dataIndex: ["personalInfo", "surname"], key: "surname" , ...getColumnSearchProps("personalInfo.surname")},
    { title: "Gender", dataIndex: ["personalInfo", "gender"], key: "gender", ...getColumnSearchProps("personalInfo.gender") },
    { title: "Citizenship", dataIndex: ["personalInfo", "citizenship"], key: "citizenship", ...getColumnSearchProps("personalInfo.citizenship") },
    { title: "Nationality", dataIndex: ["personalInfo", "nationality"], key: "nationality", ...getColumnSearchProps("personalInfo.nationality") },
    { title: "Marital Status", dataIndex: ["personalInfo", "maritalStatus"], key: "maritalStatus", ...getColumnSearchProps("personalInfo.maritalStatus") },
    { title: "Email", dataIndex: ["personalInfo", "email"], key: "email", ...getColumnSearchProps("personalInfo.email") },
    { title: "Phone", dataIndex: ["personalInfo", "phone"], key: "phone" , ...getColumnSearchProps("personalInfo.phone")},
    { title: "Address", dataIndex: ["personalInfo", "address"], key: "address", ...getColumnSearchProps("personalInfo.address") },
    { title: "National ID Number", dataIndex: ["personalInfo", "idNumber"], key: "idNumber", ...getColumnSearchProps("personalInfo.idNumber") },

    // Next of Kin Details
    { title: "Keen Name", dataIndex: ["nextKeenDetails", "fullNames"], key: "nextKinName", ...getColumnSearchProps("personalInfo.nextKinName") },
    { title: "Keen Email", dataIndex: ["nextKeenDetails", "email"], key: "nextKinEmail", ...getColumnSearchProps("personalInfo.nextKinEmail") },
    { title: "Keen Phone", dataIndex: ["nextKeenDetails", "phone"], key: "nextKinPhone", ...getColumnSearchProps("personalInfo.nextKinPhone") },

    // Enrollment Details
    { title: "School Cert. No", dataIndex: ["enrollmentDetails", "highSchoolCertificateNumber"], key: "highSchoolCert", ...getColumnSearchProps("personalInfo.highSchoolCert") },
    { title: "School completion Date", dataIndex: ["enrollmentDetails", "graduationDate"], key: "graduationDate", ...getColumnSearchProps("personalInfo.graduationDate") },
    { title: "Department", dataIndex: ["enrollmentDetails", "department"], key: "department", ...getColumnSearchProps("personalInfo.department") },
    { title: "Platform", dataIndex: ["enrollmentDetails", "platform"], key: "platform", ...getColumnSearchProps("personalInfo.platform") },
    { title: "Enrollment Date", dataIndex: ["enrollmentDetails", "enrollmentDate"], key: "enrollmentDate", ...getColumnSearchProps("personalInfo.enrollmentDate") },
    
    // Accommodation Details
    { title: "Day Scholar", dataIndex: ["accommodationDetails", "dayScholar"], key: "dayScholar", ...getColumnSearchProps("personalInfo.dayScholar") },
    { title: "Accommodation Type", dataIndex: ["accommodationDetails", "accommodationType"], key: "accommodationType", ...getColumnSearchProps("personalInfo.accommodationType") },
    { title: "Meals", dataIndex: ["accommodationDetails", "meals"], key: "meals", ...getColumnSearchProps("personalInfo.meals") },
    { title: "Special Diet", dataIndex: ["accommodationDetails", "specialDiet"], key: "specialDiet", ...getColumnSearchProps("personalInfo.specialDiet") },
    { title: "Allergies", dataIndex: ["accommodationDetails", "allergies"], key: "allergies", ...getColumnSearchProps("personalInfo.allergies") },
    
    // Tuition Details
    { title: "Kindle ", dataIndex: ["tuitionDetails", "kindleIssued"], key: "kindleIssued", ...getColumnSearchProps("personalInfo.kindleIssued") },
    { title: "Chromebook ", dataIndex: ["tuitionDetails", "chromebookIssued"], key: "chromebookIssued", ...getColumnSearchProps("personalInfo.chromebookIssued") },
    { title: "Stationery ", dataIndex: ["tuitionDetails", "stationeryIssued"], key: "stationeryIssued", ...getColumnSearchProps("personalInfo.stationeryIssued") },
    
    // Fees Details
    { title: "Payment Method", dataIndex: ["feesDetails", "paymentMethod"], key: "paymentMethod", ...getColumnSearchProps("personalInfo.paymentMethod") },
    { title: "Tuition Fee ", dataIndex: ["feesDetails", "tuitionFeesPaid"], key: "tuitionFeesPaid", ...getColumnSearchProps("personalInfo.tuitionFeesPaid") },
    { title: "Accommodation Fee ", dataIndex: ["feesDetails", "accommodationFeesPaid"], key: "accommodationFeesPaid", ...getColumnSearchProps("personalInfo.accommodationFeesPaid") },
    { title: "Meals Fee ", dataIndex: ["feesDetails", "mealsFeesPaid"], key: "mealsFeesPaid", ...getColumnSearchProps("personalInfo.mealsFeesPaid") },
    { title: "graduation Fee ", dataIndex: ["feesDetails", "graduationFeesPaid"], key: "graduationFeesPaid", ...getColumnSearchProps("personalInfo.graduationFeesPaid") },
    { title: "registration Fee ", dataIndex: ["feesDetails", "registrationFeesPaid"], key: "registrationFeesPaid", ...getColumnSearchProps("personalInfo.registrationFeesPaid") },
    
    // Denomination Details
    { title: "Church Name", dataIndex: ["denominationDetails", "churchName"], key: "churchName", ...getColumnSearchProps("personalInfo.churchName") },
    { title: "Denomination", dataIndex: ["denominationDetails", "denomination"], key: "denomination", ...getColumnSearchProps("personalInfo.denomination") },
    { title: "Church Location", dataIndex: ["denominationDetails", "churchLocation"], key: "churchLocation", ...getColumnSearchProps("personalInfo.churchLocation") },
    { title: "Church Involvement", dataIndex: ["denominationDetails", "churchInvolvement"], key: "churchInvolvement", ...getColumnSearchProps("personalInfo.churchInvolvement") },

    // Proof Documents
    { title: "Passport Photo", dataIndex: ["personalInfo", "passportPhoto"], key: "passportPhoto", render: (file) => renderDownloadIcon(file, "Passport Photo") },
    { title: "National ID", dataIndex: ["personalInfo", "idUpload"], key: "idUpload", render: (file) => renderDownloadIcon(file, "National ID") },
    // { title: "Status", dataIndex: ["personalInfo", "status"], key: "status", render: (status) => (status === 2 ? "Submitted" : "Pending") }
    // { title: "Status", dataIndex: "overallStatus", key: "overallStatus" },
  ];

  const exportToExcel = () => {
    const formattedData = students.map(({ serialNo, studentID, personalInfo, enrollmentDetails, feesDetails }) => ({
      "S.No": serialNo,
      "Student ID": studentID,
      "First Name": personalInfo?.firstName,
      "Last Name": personalInfo?.surname,
      "Gender": personalInfo?.gender,
      "Email": personalInfo?.email,
      "Phone": personalInfo?.phone,
      "Department": enrollmentDetails?.department,
      "Tuition Fees Paid": feesDetails?.tuitionFeesPaid,
      "Graduation Fees Paid": feesDetails?.graduationFeesPaid,
    }));
    
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Student Report');
    XLSX.writeFile(wb, 'Student_Report.xlsx');
  };

  return (
    <>
      <Row gutter={[16, 16]} justify="end">
        <Col xs={24}>
          <Button type="dashed" icon={<DownloadOutlined />} size="small" onClick={exportToExcel}>
            Download Excel
          </Button>
          <Button type="primary" icon={<ReloadOutlined />} size="small" onClick={fetchStudentData}>
            Refresh
          </Button>
        </Col>
      </Row>
      <Card title="1. Student Report">
        <Table columns={columns} dataSource={students} loading={loading} rowKey="studentID" scroll={{ x: 1500 }} />
      </Card>
    </>
  );
};

export default StudentReportTable;
