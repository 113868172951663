import React, { useState, useEffect , useRef} from "react";
import {  Card, Table, Space, Button,  Row,  Col,  Input,  Form,  Select,  DatePicker,  message,  Modal,} from "antd";
import axios from "axios";
import config from "../../../config";
import moment from "moment";
import AllMembers from "./AllMembers";
import * as XLSX from 'xlsx';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, EditOutlined, DeleteOutlined, DownloadOutlined, ReloadOutlined } from '@ant-design/icons';


const AddMember = () => {
  const [memberData, setMemberData] = useState({
    memberId: "",
    firstName: "",
    lastName: "",
    department: "Department of Christian Ministries", // Default department
    email: "",
    phoneNumber: "",
    gender: "",
    dateOfBirth: "",
    address: "",
    membershipType: "",
    dateJoined: "",
    membershipExpiry: "",
    status: "Active",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [existingMemberIds, setExistingMemberIds] = useState([]);
  const [existingEmails, setExistingEmails] = useState([]);
  const [departmentList, setDepartmentList] = useState(
    JSON.parse(localStorage.getItem("departments")) || [
      "Department of Christian Ministries", // Predefined department
    ]
  );
  const [showAddDepartmentModal, setShowAddDepartmentModal] = useState(false);
  const [newDepartment, setNewDepartment] = useState("");
  const [data, setData] = useState([]);
  const [editingMember, setEditingMember] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [departments, setDepartments] = useState([]); // Empty initially
 
 
    // const fetchMembers = async () => {
    //   try {
    //     const response = await axios.get(`${config.curriculaserver}/curricula/admin/library/members`);
    //     const members = Array.isArray(response.data) ? response.data : response.data.members || [];
    //     setTableData(members);

    //     const existingIds = members.map((member) => member.memberId);
    //     const existingEmails = members.map((member) => member.email);
    //     setExistingMemberIds(existingIds);
    //     setExistingEmails(existingEmails);
    //   } catch (error) {
    //     console.error("Error fetching members:", error);
    //     message.error("Failed to fetch members.");
    //   }
    // };
   

  const handleDateOfBirthChange = (date, dateString) => {
    if (date) {
      setMemberData((prevState) => ({
        ...prevState,
        dateOfBirth: dateString,
      }));
    }
  };

  const handleSelectChange = (value) => {
    setMemberData((prevState) => ({
      ...prevState,
      membershipType: value,
    }));
    calculateExpiryAndStatus(value, memberData.dateJoined);
  };

  const calculateExpiryAndStatus = (membershipType, dateJoined) => {
    const joinedDate = moment(dateJoined);
    let expiryDate = "";
    let status = "Active";

    if (!dateJoined || !joinedDate.isValid()) return;

    if (membershipType === "Student") {
      expiryDate = joinedDate.add(1, "years").format("YYYY-MM-DD");
    } else if (membershipType === "Nonteaching Faculty") {
      expiryDate = joinedDate.add(2, "years").format("YYYY-MM-DD");
    } else if (membershipType === "Teaching Faculty") {
      expiryDate = joinedDate.add(3, "years").format("YYYY-MM-DD");
    }

    const currentDate = moment();
    if (currentDate.isAfter(expiryDate)) {
      status = "Inactive";
    }

    setMemberData((prevState) => ({
      ...prevState,
      membershipExpiry: expiryDate,
      status: status,
    }));
  };

  const validateMemberData = () => {
    const { memberId, firstName, lastName, department, email, phoneNumber, gender, dateOfBirth, address, membershipType, dateJoined } = memberData;
    if (!memberId || !firstName || !lastName || !department || !email || !phoneNumber || !gender || !dateOfBirth || !address || !membershipType || !dateJoined) {
      message.error("All fields are required.");
      return false;
    }
    if (existingMemberIds.includes(memberId)) {
      message.error("Member ID already exists.");
      return false;
    }
    if (existingEmails.includes(email)) {
      message.error("Email already exists.");
      return false;
    }
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      message.error("Invalid email format.");
      return false;
    }
    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(phoneNumber)) {
      message.error("Invalid phone number format.");
      return false;
    }
    return true;
  };

   const handleSingleMemberSubmit = async () => {
    if (!validateMemberData()) return;
    setIsSubmitting(true);
    try {
      let response;
      response = await axios.post(
        `${config.curriculaserver}/curricula/admin/library/members/add`,
        memberData
      );
      message.success("Member added successfully!");
      fetchMembers();
      // Update table data
      setTableData((prevData) => [...prevData, response.data]);
      // Reset form
      setMemberData({
        memberId: "",
        firstName: "",
        lastName: "",
        department: "Department of Christian Ministries", // Reset to default department
        email: "",
        phoneNumber: "",
        gender: "",
        dateOfBirth: "",
        address: "",
        membershipType: "",
        dateJoined: "",
        membershipExpiry: "",
        status: "Active",
      });
    } catch (error) {
      console.error("Error adding member:", error);
      message.error(error.response ? error.response.data.message : "Failed to add member.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMemberData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddDepartment = () => {
    if (newDepartment && !departmentList.includes(newDepartment)) {
      const updatedDepartments = [...departmentList, newDepartment];
      setDepartmentList(updatedDepartments);
      setMemberData((prevState) => ({
        ...prevState,
        department: newDepartment,
      }));
      // Save updated department list to local storage
      localStorage.setItem("departments", JSON.stringify(updatedDepartments));
      setNewDepartment("");
      setShowAddDepartmentModal(false);
      message.success("Department added successfully.");
    } else {
      message.error("Please enter a valid department name.");
    }
  };

  const handleDeleteDepartment = (department) => {
    if (department !== "Department of Christian Ministries") {
      const updatedDepartments = departmentList.filter((dept) => dept !== department);
      setDepartmentList(updatedDepartments);
      // Save updated department list to local storage
      localStorage.setItem("departments", JSON.stringify(updatedDepartments));
      message.success(`${department} removed successfully.`);
    } else {
      message.error("You cannot delete the default department.");
    }
  };



 

  // Fetch departments from the backend
  useEffect(() => {
    fetchDepartments();
    fetchMembers();
  }, []);

  const fetchDepartments = async () => {
    try {
      // Assuming you have an endpoint to fetch departments
      const response = await axios.get(`${config.curriculaserver}/curricula/admin/library/departments`);
      setDepartments(response.data); // Store departments in the state
    } catch (error) {
      // console.error('Error fetching departments:', error);
      // message.error('');
    }
  };

  // Fetch members
  const fetchMembers = async () => {
    try {
      const response = await axios.get(`${config.curriculaserver}/curricula/admin/library/members`);
      setData(response.data);
    } catch (error) {
      console.error('Error fetching members:', error);
      message.error('Error fetching members');
    }
  };

  // Search functionality
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] || ''}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'S.No',
      key: 'serialNo',
      render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: 'Member ID',
      dataIndex: 'memberId',
      key: 'memberId',
      ...getColumnSearchProps('memberId'),
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      ...getColumnSearchProps('firstName'),
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      ...getColumnSearchProps('lastName'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      ...getColumnSearchProps('phoneNumber'),
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      ...getColumnSearchProps('gender'),
    },
    {
      title: 'Date of Birth',
      dataIndex: 'dateOfBirth',
      key: 'dateOfBirth',
      ...getColumnSearchProps('dateOfBirth'),
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      ...getColumnSearchProps('address'),
    },
    {
      title: 'Membership Type',
      dataIndex: 'membershipType',
      key: 'membershipType',
      ...getColumnSearchProps('membershipType'),
    },
    {
      title: 'Date Joined',
      dataIndex: 'dateJoined',
      key: 'dateJoined',
      ...getColumnSearchProps('dateJoined'),
    },
    {
      title: 'Membership Expiry',
      dataIndex: 'membershipExpiry',
      key: 'membershipExpiry',
      ...getColumnSearchProps('membershipExpiry'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ...getColumnSearchProps('status'),
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
      ...getColumnSearchProps('department'),
      render: (department) => department || 'No department',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          {/* <Button icon={<EditOutlined />} onClick={() => handleEdit(record)} /> */}
          <Button icon={<DeleteOutlined />} onClick={() => handleDelete(record.memberId)} />
        </Space>
      ),
    },
  ];

  const handleEdit = (record) => {
    setEditingMember(record);
    setIsModalVisible(true);
  };

  const handleEditSubmit = async (values) => {
    if (editingMember && editingMember._id) {
      try {
        await axios.put(`${config.curriculaserver}/curricula/admin/library/members/${editingMember._id}`, values);
        fetchMembers(); // Refetch members after editing
        setIsModalVisible(false); // Close the modal
        message.success('Member updated successfully');
      } catch (error) {
        console.error('Error updating member:', error);
        message.error('Error updating member');
      }
    }
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this member?');
    if (confirmDelete) {
      try {
        await axios.delete(`${config.curriculaserver}/curricula/admin/library/members/${id}`);
        fetchMembers(); // Refetch members after deletion
        message.success('Member deleted successfully');
      } catch (error) {
        console.error('Error deleting member:', error);
        message.error('Error deleting member');
      }
    }
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Members');
    XLSX.writeFile(wb, 'Members.xlsx');
  };

return (
    <div>
      {/* Form for Single Member Upload */}
      <Card
        title="1. Add New Member"
        size="small"
        style={{
          marginBottom: "20px",
          boxShadow: "none",
          border: "1px solid #d9d9d9",
          width: "100%",
        }}
      >
        <Form layout="vertical" onFinish={handleSingleMemberSubmit}>
          {/* First Row: Four fields in a row */}
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item label="Member ID" required>
                <Input name="memberId" value={memberData.memberId} onChange={handleInputChange} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="First Name" required>
                <Input name="firstName" value={memberData.firstName} onChange={handleInputChange} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Last Name" required>
                <Input name="lastName" value={memberData.lastName} onChange={handleInputChange} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Department" required>
                <Select
                  name="department"
                  value={memberData.department}
                  onChange={(value) => setMemberData((prevState) => ({ ...prevState, department: value }))}
                >
                  {departmentList.map((dept, index) => (
                    <Select.Option key={index} value={dept}>
                      {dept}
                      {dept !== "Department of Christian Ministries" && (
                        <Button
                          type="link"
                          danger
                          onClick={() => handleDeleteDepartment(dept)}
                          style={{ marginLeft: "5px" }}
                        >
                          Delete
                        </Button>
                      )}
                    </Select.Option>
                  ))}
                </Select>
                <Button type="link" onClick={() => setShowAddDepartmentModal(true)}>
                  + Add Department
                </Button>
              </Form.Item>
            </Col>
          </Row>

          {/* Second Row: Other fields */}
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item label="Email" required>
                <Input name="email" value={memberData.email} onChange={handleInputChange} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Phone Number" required>
                <Input name="phoneNumber" value={memberData.phoneNumber} onChange={handleInputChange} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Gender" required>
                <Select
                  name="gender"
                  value={memberData.gender}
                  onChange={(value) => setMemberData((prevState) => ({ ...prevState, gender: value }))}
                >
                  <Select.Option value="Male">Male</Select.Option>
                  <Select.Option value="Female">Female</Select.Option>
                  <Select.Option value="Other">Other</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Date of Birth" required>
                <DatePicker
                  format="YYYY-MM-DD"
                  onChange={handleDateOfBirthChange}
                  value={memberData.dateOfBirth ? moment(memberData.dateOfBirth) : null}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Third Row: Other fields */}
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item label="Address" required>
                <Input name="address" value={memberData.address} onChange={handleInputChange} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Date Joined" required>
                <DatePicker
                  format="YYYY-MM-DD"
                  onChange={(date, dateString) => setMemberData((prevState) => ({ ...prevState, dateJoined: dateString }))}
                  value={memberData.dateJoined ? moment(memberData.dateJoined) : null}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Membership Type" required>
                <Select name="membershipType" value={memberData.membershipType} onChange={handleSelectChange}>
                  <Select.Option value="Student">Student</Select.Option>
                  <Select.Option value="Nonteaching Faculty">Nonteaching Faculty</Select.Option>
                  <Select.Option value="Teaching Faculty">Teaching Faculty</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Membership Expiry" required>
                <Input value={memberData.membershipExpiry} disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={6}>
              <Button
                type="primary"
                htmlType="submit"
                loading={isSubmitting}
                block
                disabled={isSubmitting}
              >
                Add Member
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>

      {/* Modal for Adding New Department */}
      <Modal
        visible={showAddDepartmentModal}
        title="Add New Department"
        onCancel={() => setShowAddDepartmentModal(false)}
        onOk={handleAddDepartment}
      >
        <Input
          value={newDepartment}
          onChange={(e) => setNewDepartment(e.target.value)}
          placeholder="Enter new department name"
        />
      </Modal>

      {/* Display all members */}
      {/* <AllMembers members={tableData} /> */}
                  <br/>
                  <br/>
                  <br/>

      <Row gutter={[16, 16]} justify="end">
        <Col xs={24}>
          <Button type="dashed" icon={<DownloadOutlined />} size="small" onClick={exportToExcel}>
            Download All Members
          </Button>
          {/* <Button type="primary" icon={<ReloadOutlined />} size="small" onClick={fetchMembers}>
            Refresh
          </Button> */}
        </Col>
        <Col xs={24}>
          <Card
            title="2. All Members"
            size="small"
            style={{ marginTop: '20px', boxShadow: 'none', border: '1px solid #d9d9d9' }}
            extra={[
              <Button
                key="refresh"
                type="primary"
                icon={<ReloadOutlined />}
                onClick={fetchMembers}
              >
                Refresh
              </Button>,
            ]}
          >
            <Table
              className="table-bordered"
              columns={columns}
              dataSource={data}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                  setPageSize(pageSize);
                },
              }}
              scroll={{ x: 1300 }} // Adjust horizontal scroll for large tables
              
            />
          </Card>
        </Col>
      </Row>

      <Modal title="Edit Member" visible={isModalVisible} onCancel={() => setIsModalVisible(false)} footer={null}>
        <Form initialValues={editingMember} onFinish={handleEditSubmit}>
          <Form.Item name="firstName" label="First Name">
            <Input />
          </Form.Item>
          <Form.Item name="lastName" label="Last Name">
            <Input />
          </Form.Item>
          <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Please input the email!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="phoneNumber" label="Phone Number" rules={[{ required: true, message: 'Please input the phone number!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="gender" label="Gender">
            <Input />
          </Form.Item>
          <Form.Item name="dateOfBirth" label="Date of Birth">
            <Input style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="address" label="Address">
            <Input />
          </Form.Item>
          <Form.Item name="membershipType" label="Membership Type">
            <Input />
          </Form.Item>
          <Form.Item name="dateJoined" label="Date Joined">
            <Input style={{ width: "100%" }}  />
          </Form.Item>
          <Form.Item name="membershipExpiry" label="Membership Expiry">
            <Input />
          </Form.Item>
          <Form.Item name="status" label="Status">
            <Input />
          </Form.Item>
          <Form.Item name="department" label="Department">
            <Select defaultValue={editingMember?.department || departments[0]}>
              {departments.map((department, index) => (
                <Select.Option key={index} value={department}>
                  {department}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">Submit</Button>
          </Form.Item>
        </Form>
      </Modal>



    </div>
  );
};

export default AddMember;
