import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, Upload, Row, Col, message, Card } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import config from "../../../config";

const { Option } = Select;

const Fees = ({ onStepComplete, studentID }) => {
  const [form] = Form.useForm();
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [feeStatus, setFeeStatus] = useState({
    tuition: "notPaid",
    accommodation: "notPaid",
    meals: "notPaid",
    graduation: "notPaid",
    registration: "notPaid",
  });
  const [fileList, setFileList] = useState({
    tuitionProof: [], accommodationProof: [], mealsProof: [],
    graduationProof: [], practicumProof: [], registrationProof: []
  });
  const [loading, setLoading] = useState(false);


  //  Load saved data from localStorage on component mount
  useEffect(() => {
    if (studentID) {
      form.setFieldsValue({ studentID });
      fetchFeesData();
    }
  }, [studentID, form]);


  const fetchFeesData = async () => {
    try {
      const { data } = await axios.get(`${config.curriculaserver}/curricula/admin/fees/get/${studentID}`);

      if (data && data.feesDetails) {
        form.setFieldsValue({
          paymentMethod: data.feesDetails.paymentMethod || "Not Specified",
          tuitionFeesPaid: data.feesDetails.tuitionFeesPaid || "Not Paid",
          accommodationFeesPaid: data.feesDetails.accommodationFeesPaid || "Not Paid",
          mealsFeesPaid: data.feesDetails.mealsFeesPaid || "Not Paid",
          graduationFeesPaid: data.feesDetails.graduationFeesPaid || "Not Paid",
          registrationFeesPaid: data.feesDetails.registrationFeesPaid || "Not Paid",
          sponsorName: data.feesDetails.sponsorName || "N/A",
          sponsorContact: data.feesDetails.sponsorContact || "N/A",
        });

        // Handle existing file uploads
        setFileList({
          tuitionProof: data.feesDetails.tuitionProof
            ? [{ url: `${config.fileServer}/${data.feesDetails.tuitionProof}`, name: "Tuition Proof" }]
            : [],
          accommodationProof: data.feesDetails.accommodationProof
            ? [{ url: `${config.fileServer}/${data.feesDetails.accommodationProof}`, name: "Accommodation Proof" }]
            : [],
          mealsProof: data.feesDetails.mealsProof
            ? [{ url: `${config.fileServer}/${data.feesDetails.mealsProof}`, name: "Meals Proof" }]
            : [],
          graduationProof: data.feesDetails.graduationProof
            ? [{ url: `${config.fileServer}/${data.feesDetails.graduationProof}`, name: "Graduation Proof" }]
            : [],
          registrationProof: data.feesDetails.registrationProof
            ? [{ url: `${config.fileServer}/${data.feesDetails.registrationProof}`, name: "Registration Proof" }]
            : [],
        });
      }
    } catch (error) {
      console.error("Error fetching fees data:", error);
      message.error("Failed to load fees details.");
    }
  };

  //  Handle file selection for proof uploads
  const handleFileChange = (fileType, { fileList }) => {
    setFileList(prev => ({ ...prev, [fileType]: fileList.slice(-1) })); // Keep latest file
  };

  //  Save and Continue - Send Data to Backend
  const handleSaveAndContinue = async () => {
    try {
      await form.validateFields();
      let values = form.getFieldsValue(true); // Ensure all fields are included
  
      if (!studentID) {
        message.error("Student ID is missing. Please enter personal info first!");
        return;
      }
  
      // console.log("Submitted Values:", values); // Debugging
  
      // ✅ Ensure all fees are explicitly stored
      ["tuition", "accommodation", "meals", "graduation", "registration"].forEach((feeType) => {
        if (feeStatus[feeType] === "notPaid") {
          values[`${feeType}FeesPaid`] = "Not Paid"; // Store as "Not Paid"
        } else if (!values[`${feeType}FeesPaid`]) {
          values[`${feeType}FeesPaid`] = values[`${feeType}FeesPaid`] || "0"; // Store actual values or 0
        }
      });
  
      // console.log("Final Data to be Sent:", values); // Debugging
  
      // Fetch existing status inside feesDetails
      const { data } = await axios.get(`${config.curriculaserver}/curricula/admin/fees/get/${studentID}`);
  
      const existingStatus = data?.feesDetails?.status;
  
      if (existingStatus === 2) {
        message.error("Your form has already been submitted.");
        return;
      }
  
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });
  
      // Attach Uploaded Files
      ["tuitionProof", "accommodationProof", "mealsProof", "graduationProof", "registrationProof"].forEach(field => {
        if (fileList[field]?.length > 0 && fileList[field][0].originFileObj) {
          formData.append(field, fileList[field][0].originFileObj);
        }
      });
  
      formData.append("studentID", studentID);
      formData.append("status", existingStatus === 1 ? 1 : 1); // Ensure status is retained
  
      setLoading(true);
      const response = await axios.post(
        `${config.curriculaserver}/curricula/admin/fees/add`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
  
      if (response.data.success) {
        message.success("Fees details saved successfully!");
        onStepComplete();
      } else {
        message.error(response.data.message || "Failed to save data.");
      }
    } catch (error) {
      console.error("Validation Error:", error);
      message.error("⚠️ Please fill all required fields!");
    } finally {
      setLoading(false);
    }
  };
  
  
  
  
  // Handle Fee Status Change
  const handleFeeStatusChange = (feeType, value) => {
    setFeeStatus(prev => ({ ...prev, [feeType]: value }));
    if (value === "notPaid") {
      form.setFieldsValue({ [`${feeType}FeesPaid`]: "", [`${feeType}Proof`]: "" });
      setFileList(prev => ({ ...prev, [`${feeType}Proof`]: [] }));
    }
  };

  return (
    <div className="site-layout-content" style={{ padding: "20px" }}>
      <Card>
        <Form form={form} layout="vertical" initialValues={{ studentID }}>
          {/*  Student ID */}
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item label="Student ID" name="studentID">
                <Input disabled />
              </Form.Item>
              <Form.Item name="studentID" hidden>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          {/*  Payment Method */}
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8}>
              <Form.Item label="How do you intend to pay your fees?" name="paymentMethod" rules={[{ required: true }]}>
                <Select onChange={(value) => setPaymentMethod(value)}>
                  <Option value="personal">Personal</Option>
                  <Option value="sponsorship">Sponsorship</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>


 {/* Sponsorship Details */}
 {paymentMethod === "sponsorship" && (
            <Row gutter={16}>
              <Col xs={24} sm={12} md={8}>
                <Form.Item label="Sponsor Name" name="sponsorName" rules={[{ required: true }]}>
                  <Input placeholder="Enter sponsor name" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item label="Sponsor Contact" name="sponsorContact" rules={[{ required: true }]}>
                  <Input placeholder="Enter sponsor contact details" />
                </Form.Item>
              </Col>
            </Row>
          )}

          {/* Fees Sections */}
          {["tuition", "accommodation", "meals", "graduation", "registration"].map((feeType) => (
            <Row gutter={16} key={feeType}>
              <Col xs={24} sm={12} md={8}>
                <Form.Item label={`${feeType.charAt(0).toUpperCase() + feeType.slice(1)} Fees`} name={`${feeType}FeesStatus`} rules={[{ required: true }]}>
                  <Select onChange={(value) => handleFeeStatusChange(feeType, value)}>
                    <Option value="paid">Paid</Option>
                    <Option value="notPaid">Not Paid</Option>
                  </Select>
                </Form.Item>
              </Col>
              {feeStatus[feeType] === "paid" && (
                <>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item label={`${feeType.charAt(0).toUpperCase() + feeType.slice(1)} Fee Paid ($)`} name={`${feeType}FeesPaid`} rules={[{ required: true }]}>
                      <Input type="number" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <Form.Item label={`Upload ${feeType.charAt(0).toUpperCase() + feeType.slice(1)} Fee Proof`} name={`${feeType}Proof`} rules={[{ required: true }]}>
                      <Upload beforeUpload={() => false} maxCount={1} fileList={fileList[`${feeType}Proof`]} onChange={(info) => handleFileChange(`${feeType}Proof`, info)}>
                        <Button icon={<UploadOutlined />}>Upload Proof</Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
          ))}




          {/* Submit Button */}
          <Form.Item>
            <Button type="primary" onClick={handleSaveAndContinue} loading={loading}>Continue</Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Fees;
